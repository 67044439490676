import React, { Component, useRef, memo } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import MessageSeconNotification2 from "../../../src/sound/MessageSeconNotification2.mp3";
import {
  deleteChatFromRoom,
  UpdateMessage,
  sendAttachmentMessageAction,
  getAllChatMessageByThreadID,
} from "../../services/messageService";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import WebSocketInstance from "../services/WebSocket";
import profilePicture from "../../images/profile/profilePic.png";
import { Dropdown } from "react-bootstrap";
import Dropzone from "react-dropzone";
import ChatAssigned from "./ChatAssigned";
import Sidebar from "../components/Menu/Sidebar";
import LoadingSpinner from "../components/Component/LoadingSpinner";
class Chat extends Component {
  constructor(props) {
    super(props);
    this.state = {
      counter: this.props?.props?.user?.unread_messages,
      toggle: false,
      NoMessages: true,
      isEdit: false,
      showSpinner: true,
      PrevMessage: [],
      start_index: "",
      end_index: "",
      isCreate: false,
      allThread: [],
      notification: "",
      found: false,
      noLoading: true,
      searchMessageId: this.props?.message?.id,
    };
    this.audio = React.createRef(null);
    this.SocketOpen = WebSocketInstance?.socketRef?.readyState;
    WebSocketInstance.addCallbacks(
      this.setMessages.bind(this),
      this.addMessage.bind(this)
    );
    WebSocketInstance.fetchMessages(this.userData);
    this.sendmessages = "";
    this.newEnteredMessage = null;
    this.roomId = this.props?.props?.user?.thread_id;
    this.listRef = (useRef < HTMLDivElement) | null;
    this.refs = React.createRef();
    this.messagesEndRef = React.createRef(null);
    this.userData = JSON.parse(localStorage?.getItem("userDetails")).data;
    this.token = JSON.parse(
      localStorage?.getItem("userDetails")
    ).data.accessToken;
    this.fileRef = React.createRef();
    this.room_id = JSON.parse(localStorage.getItem("ActiveChat"))?.id;
    this.waitForSocketConnection(() => {
      this.SocketOpen = WebSocketInstance?.socketRef?.readyState;
      this.setState({ PrevMessage: [] });
      this.getAllMessagesByThread(
        this.props?.props?.user?.thread_id,
        !this.state.searchMessageId ? true : false
      );
    });
  }

  waitForSocketConnection(callback) {
    const component = this;
    setTimeout(function () {
      this.SocketOpen = WebSocketInstance?.socketRef?.readyState;
      if (this.SocketOpen === 1) {
        callback();
        return;
      } else {
        component.waitForSocketConnection(callback);
      }
    }, 5); // wait 100 milisecond for the connection...
  }

  async componentDidMount() {
    this.SocketOpen = WebSocketInstance?.socketRef?.readyState;
    this.setState({ PrevMessage: [] });
    this.setState({
      counter: this.props?.props?.user?.unread_messages,
      searchMessageId: this.props?.message?.id,
    });
  }
  componentWillUnmount() { }

  async componentDidUpdate(prevProps) {
    this.SocketOpen = WebSocketInstance?.socketRef?.readyState;
    if (WebSocketInstance?.socketRef?.url.split("/")[5] !== this.props?.props?.user?.thread_id) {
      this.getAllMessagesByThread(
        this.props?.props?.user?.thread_id,
        !this.state.searchMessageId ? true : false
      );
    }
  }

  async componentWillReceiveProps(props) {

    if (this.state?.PrevMessage?.length >= 8) {
      this.scrollToBottom();
    }
    this.setState({ counter: this.props.props.user.unread_messages });
    this.SocketOpen = WebSocketInstance?.socketRef?.readyState;
    if (this.SocketOpen === 1)
      this.setState({ showSpinner: true })

    this.setState({
      counter: this.props?.props?.user?.unread_messages,
      searchMessageId: this.props?.message?.id,
    });
  }

  scrollToBottom = (e) => {
    const element = document.getElementById("chartBox");
    element.scrollTop = element.scrollHeight;
  };

  async addMessage(message) {
    if (message === "refresh") {
      await this.getAllMessagesByThread(this.roomId, false, "delete");
    } else {
      if (message?.sender?.id !== this.userData?.id) {
        this.audio.current.play();
      }
      let data = [...this.state.PrevMessage, message];
      this.setState({
        PrevMessage: [
          ...data
            .reduce((map, obj) => map.set(obj.id, obj), new Map())
            .values(),
        ],
      });
    }
    if (this.state?.PrevMessage?.length >= 5) {
      this.scrollToBottom();
    }
  }

  setMessages(messages) {
    this.setState({ message: messages.reverse() });
  }

  async getAllMessagesByThread(threadID, LoadMore, text = null) {
    let load = LoadMore;
    let found = false
    this.setState({ showSpinner: false });
    const start = !load && !text ? this.state?.PrevMessage?.length : 0;
    const end = !load && !text
      ? this.state?.PrevMessage?.length + 10
      : this.state?.PrevMessage?.length || 10;
    const data = getAllChatMessageByThreadID(threadID, start, end, this.token);
    data?.then(
      ((res) => {
        this.setState({
          noLoading: res.data?.data?.conversation?.length < 10 ? true : false,
        });
        let data = !text ? [...this.state.PrevMessage] : [];
        if (res.data?.data?.notification?.length !== 0) {
          this.setState({ notification: res.data?.data?.notification[0] });
        }
        res.data?.data?.conversation?.forEach((element) => {
          if (this.state?.searchMessageId) {
            if (element?.id === this.state?.searchMessageId) {
              this.setState({ found: true });
              found = true;
              load = true;
            }
          }
          data.push(element);
        });
        if (data.length === 0) {
          this.setState({ NoMessages: false });
        }
        console.log(res?.data, "5555555555555555555555555")
        this.setState({
          PrevMessage: [
            ...data
              .reduce((map, obj) => map.set(obj.id, obj), new Map())
              .values(),
          ]?.sort((a, b) => a?.id - b?.id),
        });
        if (!found && !load && this.state.searchMessageId) {
          this.getAllMessagesByThread(this.roomId, load);
        } else {
          if (this.props?.props?.user?.unread_messages) {
            this.props.ChatUpdate("Update");
          }
        }
        if (this.props?.props?.user?.unread_messages) {
          this.props.ChatUpdate("Update");
        }

        if (this.SocketOpen) {
          this.setState({ showSpinner: true });
        }
      })
    );
    data?.catch((err) => {
      if (err.response.data.code === 500) {
        toast(
          <>
            <div className="mx-1 d-flex">
              <i class="bi bi-check-circle fs-26 me-3" style={{ color: "#131F4C" }}></i>
              <div>
                <h2 style={{
                  // color: "#ffffff",
                  fontSize: "22px"
                }}>
                  Failed
                </h2>
                <h3 style={{
                  // color: "#ffffff",
                  fontSize: "18px"
                }}>
                  Facing issue to get thread previous messages!
                </h3>
              </div>
            </div>
          </>,
          {
            toastId: "thread-data",
            className: 'toast-error-container toast-error-container-after ',
          }
        )
      }
    });
  }

  messageChangeHandler = (event) => {
    this.setState({
      message: event.target.value,
    });
  };

  sendMessageHandler = (e, message, threadId) => {
    e.preventDefault();
    const messageObject = {
      text: message,
      threadId: threadId,
    };
    WebSocketInstance.newChatMessage(messageObject);
    this.setState({
      message: "",
    });
  };

  sendAttachmentHandler = async (e) => {
    for (const file of e) {
      let size = parseFloat(file.size / (1024 * 1024)).toFixed(2);
      if (size > 30) {
        this.setState({
          ErrorMessage:
            "Uploaded file too Big, please select a file less than 30mb",
        });
        setTimeout(() => {
          this.setState({ ErrorMessage: "" });
        }, 4000);
      } else {
        const data = sendAttachmentMessageAction(
          this.userData?.id,
          this.state?.textMessage,
          JSON.parse(localStorage.getItem("ActiveChat"))?.id,
          file,
          this.token
        );
        data?.then(
          await ((res) => {
            WebSocketInstance.newChatMessage(res.data.data);
            this.setState({ message: "" });
          })
        );
        data?.catch(await ((err) => { }));
        this.setState({ message: file?.name });
      }
    }
  };

  convertUrlsToLinks(text) {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    if (!text) {
      return ''; // return an empty string if text is empty or undefined
    }
    const urls = text.match(urlRegex);
    if (!urls) {
      return text; // return the original text if no URLs are found
    }
    return text.replace(urlRegex, '<a class="link-styled" href="$&" target="_blank">$&</a>');
  }


  changedDateFormat = (date) => {
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    let days = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    let today1 = new Date(date);
    return (
      days[today1.getDay()] +
      ",  " +
      monthNames[today1.getMonth()] +
      " " +
      String(today1.getDate()).padStart(2, "0") +
      "th, " +
      " " +
      today1.getFullYear()
    );
  };

  getTwentyFourHourTime = (date) => {
    var d = new Date(date);
    return d.getHours() + ":" + d.getMinutes();
  };

  getTimeZone = (date) => {
    let today1 = new Date(date);
    let dates = today1.toLocaleString("en-US", {
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    }); //.split(',')[1]
    return this.getTwentyFourHourTime(dates);
  };

  handleDeleteChatClick = async (delete_both, id) => {
    if (delete_both) {
      const data = deleteChatFromRoom(id, delete_both, this.token);
      data?.then(
        await ((res) => {
          setTimeout(() => {
            WebSocketInstance.newChatMessage({
              command: "refresh",
            });
          }, 100);
        })
      );
      data?.catch(await (() => { }));
    } else {
      const data = deleteChatFromRoom(id, delete_both, this.token);
      data?.then(
        await (() => {
          this.getAllMessagesByThread(
            JSON.parse(localStorage.getItem("ActiveChat"))?.thread_id,
            false,
            "delete"
          );
        })
      );
      data?.catch(await (() => { }));
    }
  };

  handleEditMessageClick = async (e, id, txt_msg) => {
    e.preventDefault();
    const data = UpdateMessage(
      id,
      JSON.parse(localStorage.getItem("ActiveChat"))?.thread_id,
      txt_msg,
      this.token
    );
    data?.then(
      await ((res) => {
        this.setState({ message: "", isEdit: false });
        setTimeout(() => {
          WebSocketInstance.newChatMessage({
            command: "refresh",
          });
        }, 100);
      })
    );
    data?.catch(
      await ((err) => {
        console.log("error UpdateMessage", err);
      })
    );
  };

  getFileNameWithEx = (file) => {
    let files = file?.substring(file?.lastIndexOf("/") + 1);
    let ext = files?.split(".")[files?.split(".").length - 1];
    return { files: files, ext: ext };
  };

  setUpdate = (toggle) => {
    this.setState({ toggle: toggle });
  };

  render() {
    return (
      <>
        <audio ref={this.audio} src={MessageSeconNotification2} />
        <Row>
          <Col className={this.state.toggle ? "col-sm-7 chat-row" : "col-sm-12 chat-row"}>
            <div className="p-0 m-0 border-bottom mb-2">
              <PerfectScrollbar
                className="dlab-scroll"
                id="chartBox"
                style={{ height: "55vh" }}
              >
                {WebSocketInstance?.socketRef?.readyState === 1 ? (
                  <>
                    <div className="chat-window mx-1">
                      <div className="chat-box-area chat-box-area message-body">
                        <div
                          className=" dz-scroll  m-0"
                          id="ChatWindowInner"
                          ref={this.listRef}
                        >
                          {!this.state.noLoading && (
                            <Row className="d-flex justify-content-end m-2">
                              <div className="" style={{ textAlign: "center" }}>
                                <Link
                                  to=""
                                  className="figma-link col-sm-3 mx-5"
                                  data-bs-toggle="tooltip"
                                  title="Load more messages"
                                  onClick={(event) => {
                                    this.getAllMessagesByThread(
                                      JSON.parse(
                                        localStorage.getItem("ActiveChat")
                                      )?.thread_id,
                                      false
                                    );
                                  }}
                                >
                                  {" "}
                                  Load more...
                                </Link>
                              </div>
                            </Row>
                          )}
                          {this.state?.notification?.length !== 0 && (
                            <span className="d-flex justify-content-end mb-2">
                              <ChatAssigned
                                props={this?.state?.notification}
                                data={this?.setUpdate}
                                toggle={this?.toggle}
                              />
                            </span>
                          )}
                          {this.state?.notification?.length === 0 &&
                            this.state?.PrevMessage?.length === 0 &&
                            this.state?.showSpinner && (
                              <h4 className="d-flex justify-content-center mt-4">
                                No messages in this chat
                              </h4>
                            )}
                          {this.state.PrevMessage?.length >= 1 &&
                            Array?.isArray(this.state?.PrevMessage) &&
                            this.state?.PrevMessage?.map(
                              (oneMessage, index) => (
                                <>
                                  <div
                                    style={
                                      oneMessage?.id ===
                                        this.state.searchMessageId
                                        ? {
                                          border: "1px solid #e84c4c",
                                          padding: "5px",
                                          margin: "5px",
                                          borderRadius: "10px",
                                        }
                                        : {}
                                    }
                                  >
                                    {oneMessage?.sender?.id ===
                                      this.userData?.id
                                      ? (oneMessage?.text !== "" ||
                                        oneMessage?.attachments?.length !==
                                        0) && (
                                        <div
                                          className="media mb-2 justify-content-end align-items-start"
                                          key={index}
                                          ref={(el) => {
                                            this.el = el;
                                          }}
                                        >
                                          {oneMessage?.text && (
                                            <>
                                              <div className="message-sent dropdown1">
                                                <span className="filterText fs-12">
                                                  {this.props?.props?.user
                                                    ?.room_type === "personal"
                                                    ? this.getTimeZone(
                                                      oneMessage?.created_at
                                                    )
                                                    : oneMessage?.sender
                                                      ?.first_name +
                                                    " " +
                                                    oneMessage?.sender
                                                      ?.last_name +
                                                    " " +
                                                    this.getTimeZone(
                                                      oneMessage?.created_at
                                                    )}
                                                </span>
                                                <div className="d-flex justify-content-end">
                                                  <p
                                                    className="p me-3"
                                                    key={index}
                                                    style={{
                                                      marginLeft: "10vw",
                                                    }}
                                                  >
                                                    <div clsssName="mt-4 mb-3 p-4" style={{overflowWrap:"anywhere", padding:"4px"}} dangerouslySetInnerHTML={{ __html: this.convertUrlsToLinks(oneMessage?.text) }} />
                                                    {/* {oneMessage?.text} */}
                                                  </p>
                                                  <div className="dropdown-content1">
                                                    <Dropdown className="dropdown float-center m-2 me-2 mt-0 ">
                                                      <Dropdown.Toggle
                                                        as="div"
                                                        className="btn-link i-false p-1"
                                                      >
                                                        <svg
                                                          width="20"
                                                          height="20"
                                                          viewBox="0 0 24 24"
                                                          fill="none"
                                                          xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                          <circle
                                                            cx="12.4999"
                                                            cy="3.5"
                                                            r="2.5"
                                                            fill="#ffffff"
                                                          />
                                                          <circle
                                                            cx="12.4999"
                                                            cy="11.5"
                                                            r="2.5"
                                                            fill="#ffffff"
                                                          />
                                                          <circle
                                                            cx="12.4999"
                                                            cy="19.5"
                                                            r="2.5"
                                                            fill="#ffffff"
                                                          />
                                                        </svg>
                                                      </Dropdown.Toggle>
                                                      <Dropdown.Menu className="dropdown-menu dropdown-menu-right">
                                                        <Dropdown.Item
                                                          onClick={() => {
                                                            this.setState({
                                                              message:
                                                                oneMessage?.text,
                                                              msgID:
                                                                oneMessage.id,
                                                              isEdit: true,
                                                            });
                                                          }}
                                                        >
                                                          Edit
                                                        </Dropdown.Item>
                                                        <Dropdown.Item
                                                          onClick={() => {
                                                            this.handleDeleteChatClick(
                                                              true,
                                                              oneMessage.id
                                                            );
                                                          }}
                                                        >
                                                          Delete for everyone
                                                        </Dropdown.Item>
                                                        <Dropdown.Item
                                                          onClick={() => {
                                                            this.handleDeleteChatClick(
                                                              false,
                                                              oneMessage.id
                                                            );
                                                          }}
                                                        >
                                                          Delete for me
                                                        </Dropdown.Item>
                                                      </Dropdown.Menu>
                                                    </Dropdown>
                                                  </div>
                                                </div>
                                              </div>
                                              <div className="chat-img  ms-2 ">
                                                <img
                                                  src={
                                                    oneMessage.sender
                                                      .profile_picture ||
                                                    profilePicture
                                                  }
                                                  alt="chat-img"
                                                  title="chat-img"
                                                  className="rounded-circle img-1"
                                                />
                                                {oneMessage?.sender
                                                  ?.is_online === true ? (
                                                  <span
                                                    className="active"
                                                    style={{ bottom: "-1px" }}
                                                  ></span>
                                                ) : (
                                                  <span
                                                    className="inActive"
                                                    style={{ bottom: "-1px" }}
                                                  ></span>
                                                )}
                                              </div>
                                            </>
                                          )}
                                          {oneMessage?.attachments?.length !==
                                            0 &&
                                            oneMessage?.attachments[0]
                                              ?.attachments?.length !== 0 ? (
                                            <>
                                              <>
                                                {oneMessage?.attachments[0]
                                                  ?.attachments?.length !==
                                                  0 && (
                                                    <>
                                                      <div className="message-sent dropdown1">
                                                        <span className="filterText fs-12">
                                                          {this.props?.props
                                                            ?.user
                                                            ?.room_type ===
                                                            "personal"
                                                            ? this.getTimeZone(
                                                              oneMessage?.created_at
                                                            )
                                                            : oneMessage?.sender
                                                              ?.first_name +
                                                            " " +
                                                            oneMessage?.sender
                                                              ?.last_name +
                                                            " " +
                                                            this.getTimeZone(
                                                              oneMessage?.created_at
                                                            )}
                                                        </span>
                                                        <div className="d-flex justify-content-end">
                                                          {oneMessage?.attachments?.map(
                                                            (element, index) =>
                                                              this.getFileNameWithEx(
                                                                element?.attachments
                                                              ).ext ===
                                                                "pdf" ? (
                                                                <>
                                                                  <div className="d-flex justify-content-between mb-0 ">
                                                                    <p
                                                                      className="mb-1 message"
                                                                      key={
                                                                        index
                                                                      }
                                                                      data-toggle="tooltip"
                                                                      data-placement="right"
                                                                      title="Download attachment"
                                                                      style={{
                                                                        maxWidth:
                                                                          "20rem",
                                                                      }}
                                                                    >
                                                                      <a
                                                                        className="text-center"
                                                                        href={
                                                                          element?.attachments
                                                                        }
                                                                        target="_blank"
                                                                        rel="noopener noreferrer"
                                                                        download
                                                                        key={
                                                                          index
                                                                        }
                                                                        data-toggle="tooltip"
                                                                        data-placement="right"
                                                                        title="Download attachment"
                                                                      >
                                                                        <svg
                                                                          color="red"
                                                                          xmlns="http://www.w3.org/2000/svg"
                                                                          width="38"
                                                                          height="38"
                                                                          fill="currentColor"
                                                                          className="bi bi-filetype-pdf"
                                                                          viewBox="0 0 16 16"
                                                                        >
                                                                          <path
                                                                            fillRule="evenodd"
                                                                            d="M14 4.5V14a2 2 0 0 1-2 2h-1v-1h1a1 1 0 0 0 1-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5L14 4.5ZM1.6 11.85H0v3.999h.791v-1.342h.803c.287 0 .531-.057.732-.173.203-.117.358-.275.463-.474a1.42 1.42 0 0 0 .161-.677c0-.25-.053-.476-.158-.677a1.176 1.176 0 0 0-.46-.477c-.2-.12-.443-.179-.732-.179Zm.545 1.333a.795.795 0 0 1-.085.38.574.574 0 0 1-.238.241.794.794 0 0 1-.375.082H.788V12.48h.66c.218 0 .389.06.512.181.123.122.185.296.185.522Zm1.217-1.333v3.999h1.46c.401 0 .734-.08.998-.237a1.45 1.45 0 0 0 .595-.689c.13-.3.196-.662.196-1.084 0-.42-.065-.778-.196-1.075a1.426 1.426 0 0 0-.589-.68c-.264-.156-.599-.234-1.005-.234H3.362Zm.791.645h.563c.248 0 .45.05.609.152a.89.89 0 0 1 .354.454c.079.201.118.452.118.753a2.3 2.3 0 0 1-.068.592 1.14 1.14 0 0 1-.196.422.8.8 0 0 1-.334.252 1.298 1.298 0 0 1-.483.082h-.563v-2.707Zm3.743 1.763v1.591h-.79V11.85h2.548v.653H7.896v1.117h1.606v.638H7.896Z"
                                                                          />
                                                                        </svg>
                                                                        <span className="ms-2 mt-2 text-white text-wrap">
                                                                          {this.getFileNameWithEx(
                                                                            element?.attachments
                                                                          )
                                                                            .files ||
                                                                            "-"}
                                                                        </span>
                                                                      </a>
                                                                    </p>
                                                                    <div className="dropdown-content1 text-center mt-2">
                                                                      <Dropdown className="dropdown float-center ms-4">
                                                                        <Dropdown.Toggle
                                                                          as="div"
                                                                          className="btn-link i-false p-1"
                                                                        >
                                                                          <svg
                                                                            width="20"
                                                                            height="20"
                                                                            viewBox="0 0 24 24"
                                                                            fill="none"
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                          >
                                                                            <circle
                                                                              cx="12.4999"
                                                                              cy="3.5"
                                                                              r="2.5"
                                                                              fill="#ffffff"
                                                                            />
                                                                            <circle
                                                                              cx="12.4999"
                                                                              cy="11.5"
                                                                              r="2.5"
                                                                              fill="#ffffff"
                                                                            />
                                                                            <circle
                                                                              cx="12.4999"
                                                                              cy="19.5"
                                                                              r="2.5"
                                                                              fill="#ffffff"
                                                                            />
                                                                          </svg>
                                                                        </Dropdown.Toggle>
                                                                        <Dropdown.Menu className="dropdown-menu dropdown-menu-right">
                                                                          <Dropdown.Item
                                                                            onClick={() => {
                                                                              this.handleDeleteChatClick(
                                                                                true,
                                                                                oneMessage.id
                                                                              );
                                                                            }}
                                                                          >
                                                                            Delete
                                                                            for
                                                                            everyone
                                                                          </Dropdown.Item>
                                                                          <Dropdown.Item
                                                                            onClick={() => {
                                                                              this.handleDeleteChatClick(
                                                                                false,
                                                                                oneMessage.id
                                                                              );
                                                                            }}
                                                                          >
                                                                            Delete
                                                                            for
                                                                            me
                                                                          </Dropdown.Item>
                                                                        </Dropdown.Menu>
                                                                      </Dropdown>
                                                                    </div>
                                                                  </div>
                                                                </>
                                                              ) : this.getFileNameWithEx(
                                                                element?.attachments
                                                              ).ext ===
                                                                "txt" ? (
                                                                <>
                                                                  <div className="d-flex justify-content-between mb-0 ">
                                                                    <p
                                                                      className="mb-1 message"
                                                                      key={
                                                                        index
                                                                      }
                                                                      data-toggle="tooltip"
                                                                      data-placement="right"
                                                                      title="Download attachment"
                                                                      style={{
                                                                        maxWidth:
                                                                          "20rem",
                                                                      }}
                                                                    >
                                                                      <a
                                                                        className="text-center"
                                                                        href={
                                                                          element?.attachments
                                                                        }
                                                                        target="_blank"
                                                                        rel="noopener noreferrer"
                                                                        download
                                                                        key={
                                                                          index
                                                                        }
                                                                        data-toggle="tooltip"
                                                                        data-placement="right"
                                                                        title="Download attachment"
                                                                      >
                                                                        <svg
                                                                          color="#df5276"
                                                                          xmlns="http://www.w3.org/2000/svg"
                                                                          width="38"
                                                                          height="38"
                                                                          fill="currentColor"
                                                                          className="bi bi-filetype-txt"
                                                                          viewBox="0 0 16 16"
                                                                        >
                                                                          <path
                                                                            fillRule="evenodd"
                                                                            d="M14 4.5V14a2 2 0 0 1-2 2h-2v-1h2a1 1 0 0 0 1-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5L14 4.5ZM1.928 15.849v-3.337h1.136v-.662H0v.662h1.134v3.337h.794Zm4.689-3.999h-.894L4.9 13.289h-.035l-.832-1.439h-.932l1.228 1.983-1.24 2.016h.862l.853-1.415h.035l.85 1.415h.907l-1.253-1.992 1.274-2.007Zm1.93.662v3.337h-.794v-3.337H6.619v-.662h3.064v.662H8.546Z"
                                                                          />
                                                                        </svg>
                                                                        <span className="ms-2 mt-2 text-white font-w500">
                                                                          {this.getFileNameWithEx(
                                                                            element?.attachments
                                                                          )
                                                                            .files ||
                                                                            "-"}
                                                                        </span>
                                                                      </a>
                                                                    </p>
                                                                    <div className="dropdown-content1 text-center mt-2">
                                                                      <Dropdown className="dropdown float-center ms-4">
                                                                        <Dropdown.Toggle
                                                                          as="div"
                                                                          className="btn-link i-false p-1"
                                                                        >
                                                                          <svg
                                                                            width="20"
                                                                            height="20"
                                                                            viewBox="0 0 24 24"
                                                                            fill="none"
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                          >
                                                                            <circle
                                                                              cx="12.4999"
                                                                              cy="3.5"
                                                                              r="2.5"
                                                                              fill="#ffffff"
                                                                            />
                                                                            <circle
                                                                              cx="12.4999"
                                                                              cy="11.5"
                                                                              r="2.5"
                                                                              fill="#ffffff"
                                                                            />
                                                                            <circle
                                                                              cx="12.4999"
                                                                              cy="19.5"
                                                                              r="2.5"
                                                                              fill="#ffffff"
                                                                            />
                                                                          </svg>
                                                                        </Dropdown.Toggle>
                                                                        <Dropdown.Menu className="dropdown-menu dropdown-menu-right">
                                                                          <Dropdown.Item
                                                                            onClick={() => {
                                                                              this.handleDeleteChatClick(
                                                                                true,
                                                                                oneMessage.id
                                                                              );
                                                                            }}
                                                                          >
                                                                            Delete
                                                                            for
                                                                            everyone
                                                                          </Dropdown.Item>
                                                                          <Dropdown.Item
                                                                            onClick={() => {
                                                                              this.handleDeleteChatClick(
                                                                                false,
                                                                                oneMessage.id
                                                                              );
                                                                            }}
                                                                          >
                                                                            Delete
                                                                            for
                                                                            me
                                                                          </Dropdown.Item>
                                                                        </Dropdown.Menu>
                                                                      </Dropdown>
                                                                    </div>
                                                                  </div>
                                                                </>
                                                              ) : this.getFileNameWithEx(
                                                                element?.attachments
                                                              ).ext ===
                                                                "xlsx" ? (
                                                                <>
                                                                  <div className="d-flex justify-content-between mb-0 ">
                                                                    <p
                                                                      className="mb-1 message"
                                                                      key={
                                                                        index
                                                                      }
                                                                      data-toggle="tooltip"
                                                                      data-placement="right"
                                                                      title="Download attachment"
                                                                      style={{
                                                                        maxWidth:
                                                                          "20rem",
                                                                      }}
                                                                    >
                                                                      <a
                                                                        className="text-center"
                                                                        href={
                                                                          element?.attachments
                                                                        }
                                                                        target="_blank"
                                                                        rel="noopener noreferrer"
                                                                        download
                                                                        key={
                                                                          index
                                                                        }
                                                                        data-toggle="tooltip"
                                                                        data-placement="right"
                                                                        title="Download attachment"
                                                                      >
                                                                        <svg
                                                                          color="#528f07"
                                                                          xmlns="http://www.w3.org/2000/svg"
                                                                          width="38"
                                                                          height="38"
                                                                          fill="currentColor"
                                                                          className="bi bi-filetype-xlsx"
                                                                          viewBox="0 0 16 16"
                                                                        >
                                                                          <path
                                                                            fillRule="evenodd"
                                                                            d="M14 4.5V11h-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5L14 4.5ZM7.86 14.841a1.13 1.13 0 0 0 .401.823c.13.108.29.192.479.252.19.061.411.091.665.091.338 0 .624-.053.858-.158.237-.105.416-.252.54-.44a1.17 1.17 0 0 0 .187-.656c0-.224-.045-.41-.135-.56a1.002 1.002 0 0 0-.375-.357 2.028 2.028 0 0 0-.565-.21l-.621-.144a.97.97 0 0 1-.405-.176.37.37 0 0 1-.143-.299c0-.156.061-.284.184-.384.125-.101.296-.152.513-.152.143 0 .266.023.37.068a.624.624 0 0 1 .245.181.56.56 0 0 1 .12.258h.75a1.093 1.093 0 0 0-.199-.566 1.21 1.21 0 0 0-.5-.41 1.813 1.813 0 0 0-.78-.152c-.293 0-.552.05-.777.15-.224.099-.4.24-.527.421-.127.182-.19.395-.19.639 0 .201.04.376.123.524.082.149.199.27.351.367.153.095.332.167.54.213l.618.144c.207.049.36.113.462.193a.387.387 0 0 1 .153.326.512.512 0 0 1-.085.29.558.558 0 0 1-.255.193c-.111.047-.25.07-.413.07-.117 0-.224-.013-.32-.04a.837.837 0 0 1-.249-.115.578.578 0 0 1-.255-.384h-.764Zm-3.726-2.909h.893l-1.274 2.007 1.254 1.992h-.908l-.85-1.415h-.035l-.853 1.415H1.5l1.24-2.016-1.228-1.983h.931l.832 1.438h.036l.823-1.438Zm1.923 3.325h1.697v.674H5.266v-3.999h.791v3.325Zm7.636-3.325h.893l-1.274 2.007 1.254 1.992h-.908l-.85-1.415h-.035l-.853 1.415h-.861l1.24-2.016-1.228-1.983h.931l.832 1.438h.036l.823-1.438Z"
                                                                          />
                                                                        </svg>
                                                                        <span className="ms-2 mt-2 text-white font-w500">
                                                                          {this.getFileNameWithEx(
                                                                            element?.attachments
                                                                          )
                                                                            .files ||
                                                                            "-"}
                                                                        </span>
                                                                      </a>
                                                                    </p>
                                                                    <div className="dropdown-content1 text-center mt-2">
                                                                      <Dropdown className="dropdown float-center ms-4">
                                                                        <Dropdown.Toggle
                                                                          as="div"
                                                                          className="btn-link i-false p-1"
                                                                        >
                                                                          <svg
                                                                            width="20"
                                                                            height="20"
                                                                            viewBox="0 0 24 24"
                                                                            fill="none"
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                          >
                                                                            <circle
                                                                              cx="12.4999"
                                                                              cy="3.5"
                                                                              r="2.5"
                                                                              fill="#ffffff"
                                                                            />
                                                                            <circle
                                                                              cx="12.4999"
                                                                              cy="11.5"
                                                                              r="2.5"
                                                                              fill="#ffffff"
                                                                            />
                                                                            <circle
                                                                              cx="12.4999"
                                                                              cy="19.5"
                                                                              r="2.5"
                                                                              fill="#ffffff"
                                                                            />
                                                                          </svg>
                                                                        </Dropdown.Toggle>
                                                                        <Dropdown.Menu className="dropdown-menu dropdown-menu-right">
                                                                          <Dropdown.Item
                                                                            onClick={() => {
                                                                              this.handleDeleteChatClick(
                                                                                true,
                                                                                oneMessage.id
                                                                              );
                                                                            }}
                                                                          >
                                                                            Delete
                                                                            for
                                                                            everyone
                                                                          </Dropdown.Item>
                                                                          <Dropdown.Item
                                                                            onClick={() => {
                                                                              this.handleDeleteChatClick(
                                                                                false,
                                                                                oneMessage.id
                                                                              );
                                                                            }}
                                                                          >
                                                                            Delete
                                                                            for
                                                                            me
                                                                          </Dropdown.Item>
                                                                        </Dropdown.Menu>
                                                                      </Dropdown>
                                                                    </div>
                                                                  </div>
                                                                </>
                                                              ) : this.getFileNameWithEx(
                                                                element?.attachments
                                                              ).ext ===
                                                                "xls" ? (
                                                                <>
                                                                  <div className="d-flex justify-content-between mb-0 ">
                                                                    <p
                                                                      className="mb-1 message"
                                                                      key={
                                                                        index
                                                                      }
                                                                      data-toggle="tooltip"
                                                                      data-placement="right"
                                                                      title="Download attachment"
                                                                      style={{
                                                                        maxWidth:
                                                                          "20rem",
                                                                      }}
                                                                    >
                                                                      <a
                                                                        className="text-center"
                                                                        href={
                                                                          element?.attachments
                                                                        }
                                                                        target="_blank"
                                                                        rel="noopener noreferrer"
                                                                        download
                                                                        key={
                                                                          index
                                                                        }
                                                                        data-toggle="tooltip"
                                                                        data-placement="right"
                                                                        title="Download attachment"
                                                                      >
                                                                        <svg
                                                                          color="#0ef216"
                                                                          xmlns="http://www.w3.org/2000/svg"
                                                                          width="38"
                                                                          height="38"
                                                                          fill="currentColor"
                                                                          className="bi bi-filetype-xls"
                                                                          viewBox="0 0 16 16"
                                                                        >
                                                                          <path
                                                                            fillRule="evenodd"
                                                                            d="M14 4.5V14a2 2 0 0 1-2 2h-1v-1h1a1 1 0 0 0 1-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5L14 4.5ZM6.472 15.29a1.176 1.176 0 0 1-.111-.449h.765a.578.578 0 0 0 .254.384c.07.049.154.087.25.114.095.028.202.041.319.041.164 0 .302-.023.413-.07a.559.559 0 0 0 .255-.193.507.507 0 0 0 .085-.29.387.387 0 0 0-.153-.326c-.101-.08-.255-.144-.462-.193l-.619-.143a1.72 1.72 0 0 1-.539-.214 1.001 1.001 0 0 1-.351-.367 1.068 1.068 0 0 1-.123-.524c0-.244.063-.457.19-.639.127-.181.303-.322.527-.422.225-.1.484-.149.777-.149.305 0 .564.05.78.152.216.102.383.239.5.41.12.17.186.359.2.566h-.75a.56.56 0 0 0-.12-.258.625.625 0 0 0-.247-.181.923.923 0 0 0-.369-.068c-.217 0-.388.05-.513.152a.472.472 0 0 0-.184.384c0 .121.048.22.143.3a.97.97 0 0 0 .405.175l.62.143c.217.05.406.12.566.211a1 1 0 0 1 .375.358c.09.148.135.335.135.56 0 .247-.063.466-.188.656a1.216 1.216 0 0 1-.539.439c-.234.105-.52.158-.858.158-.254 0-.476-.03-.665-.09a1.404 1.404 0 0 1-.478-.252 1.13 1.13 0 0 1-.29-.375Zm-2.945-3.358h-.893L1.81 13.37h-.036l-.832-1.438h-.93l1.227 1.983L0 15.931h.861l.853-1.415h.035l.85 1.415h.908L2.253 13.94l1.274-2.007Zm2.727 3.325H4.557v-3.325h-.79v4h2.487v-.675Z"
                                                                          />
                                                                        </svg>
                                                                        <span className="ms-2 mt-2 text-white font-w500">
                                                                          {this.getFileNameWithEx(
                                                                            element?.attachments
                                                                          )
                                                                            .files ||
                                                                            "-"}
                                                                        </span>
                                                                      </a>
                                                                    </p>
                                                                    <div className="dropdown-content1 text-center mt-2">
                                                                      <Dropdown className="dropdown float-center ms-4">
                                                                        <Dropdown.Toggle
                                                                          as="div"
                                                                          className="btn-link i-false p-1"
                                                                        >
                                                                          <svg
                                                                            width="20"
                                                                            height="20"
                                                                            viewBox="0 0 24 24"
                                                                            fill="none"
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                          >
                                                                            <circle
                                                                              cx="12.4999"
                                                                              cy="3.5"
                                                                              r="2.5"
                                                                              fill="#ffffff"
                                                                            />
                                                                            <circle
                                                                              cx="12.4999"
                                                                              cy="11.5"
                                                                              r="2.5"
                                                                              fill="#ffffff"
                                                                            />
                                                                            <circle
                                                                              cx="12.4999"
                                                                              cy="19.5"
                                                                              r="2.5"
                                                                              fill="#ffffff"
                                                                            />
                                                                          </svg>
                                                                        </Dropdown.Toggle>
                                                                        <Dropdown.Menu className="dropdown-menu dropdown-menu-right">
                                                                          <Dropdown.Item
                                                                            onClick={() => {
                                                                              this.handleDeleteChatClick(
                                                                                true,
                                                                                oneMessage.id
                                                                              );
                                                                            }}
                                                                          >
                                                                            Delete
                                                                            for
                                                                            everyone
                                                                          </Dropdown.Item>
                                                                          <Dropdown.Item
                                                                            onClick={() => {
                                                                              this.handleDeleteChatClick(
                                                                                false,
                                                                                oneMessage.id
                                                                              );
                                                                            }}
                                                                          >
                                                                            Delete
                                                                            for
                                                                            me
                                                                          </Dropdown.Item>
                                                                        </Dropdown.Menu>
                                                                      </Dropdown>
                                                                    </div>
                                                                  </div>
                                                                </>
                                                              ) : this.getFileNameWithEx(
                                                                element?.attachments
                                                              ).ext ===
                                                                "doc" ? (
                                                                <>
                                                                  <div className="d-flex justify-content-between mb-0 ">
                                                                    <p
                                                                      className="mb-1 message"
                                                                      key={
                                                                        index
                                                                      }
                                                                      data-toggle="tooltip"
                                                                      data-placement="right"
                                                                      title="Download attachment"
                                                                      style={{
                                                                        maxWidth:
                                                                          "20rem",
                                                                      }}
                                                                    >
                                                                      <a
                                                                        className="text-center"
                                                                        href={
                                                                          element?.attachments
                                                                        }
                                                                        target="_blank"
                                                                        rel="noopener noreferrer"
                                                                        download
                                                                        key={
                                                                          index
                                                                        }
                                                                        data-toggle="tooltip"
                                                                        data-placement="right"
                                                                        title="Download attachment"
                                                                      >
                                                                        <svg
                                                                          color="#f78717"
                                                                          xmlns="http://www.w3.org/2000/svg"
                                                                          width="38"
                                                                          height="38"
                                                                          fill="currentColor"
                                                                          className="bi bi-filetype-doc"
                                                                          viewBox="0 0 16 16"
                                                                        >
                                                                          <path
                                                                            fillRule="evenodd"
                                                                            d="M14 4.5V14a2 2 0 0 1-2 2v-1a1 1 0 0 0 1-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5L14 4.5Zm-7.839 9.166v.522c0 .256-.039.47-.117.641a.861.861 0 0 1-.322.387.877.877 0 0 1-.469.126.883.883 0 0 1-.471-.126.868.868 0 0 1-.32-.386 1.55 1.55 0 0 1-.117-.642v-.522c0-.257.04-.471.117-.641a.868.868 0 0 1 .32-.387.868.868 0 0 1 .471-.129c.176 0 .332.043.469.13a.861.861 0 0 1 .322.386c.078.17.117.384.117.641Zm.803.519v-.513c0-.377-.068-.7-.205-.972a1.46 1.46 0 0 0-.589-.63c-.254-.147-.56-.22-.917-.22-.355 0-.662.073-.92.22a1.441 1.441 0 0 0-.589.627c-.136.271-.205.596-.205.975v.513c0 .375.069.7.205.973.137.271.333.48.59.627.257.144.564.216.92.216.357 0 .662-.072.916-.216.256-.147.452-.356.59-.627.136-.274.204-.598.204-.973ZM0 11.926v4h1.459c.402 0 .735-.08.999-.238a1.45 1.45 0 0 0 .595-.689c.13-.3.196-.662.196-1.084 0-.42-.065-.778-.196-1.075a1.426 1.426 0 0 0-.59-.68c-.263-.156-.598-.234-1.004-.234H0Zm.791.645h.563c.248 0 .45.05.609.152a.89.89 0 0 1 .354.454c.079.201.118.452.118.753a2.3 2.3 0 0 1-.068.592 1.141 1.141 0 0 1-.196.422.8.8 0 0 1-.334.252 1.298 1.298 0 0 1-.483.082H.79V12.57Zm7.422.483a1.732 1.732 0 0 0-.103.633v.495c0 .246.034.455.103.627a.834.834 0 0 0 .298.393.845.845 0 0 0 .478.131.868.868 0 0 0 .401-.088.699.699 0 0 0 .273-.248.8.8 0 0 0 .117-.364h.765v.076a1.268 1.268 0 0 1-.226.674c-.137.194-.32.345-.55.454a1.81 1.81 0 0 1-.786.164c-.36 0-.664-.072-.914-.216a1.424 1.424 0 0 1-.571-.627c-.13-.272-.194-.597-.194-.976v-.498c0-.379.066-.705.197-.978.13-.274.321-.485.571-.633.252-.149.556-.223.911-.223.219 0 .421.032.607.097.187.062.35.153.489.272a1.326 1.326 0 0 1 .466.964v.073H9.78a.85.85 0 0 0-.12-.38.7.7 0 0 0-.273-.261.802.802 0 0 0-.398-.097.814.814 0 0 0-.475.138.868.868 0 0 0-.301.398Z"
                                                                          />
                                                                        </svg>
                                                                        <span className="ms-2 mt-2 text-white font-w500">
                                                                          {this.getFileNameWithEx(
                                                                            element?.attachments
                                                                          )
                                                                            .files ||
                                                                            "-"}
                                                                        </span>
                                                                      </a>
                                                                    </p>
                                                                    <div className="dropdown-content1 text-center mt-2">
                                                                      <Dropdown className="dropdown float-center ms-4">
                                                                        <Dropdown.Toggle
                                                                          as="div"
                                                                          className="btn-link i-false p-1"
                                                                        >
                                                                          <svg
                                                                            width="20"
                                                                            height="20"
                                                                            viewBox="0 0 24 24"
                                                                            fill="none"
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                          >
                                                                            <circle
                                                                              cx="12.4999"
                                                                              cy="3.5"
                                                                              r="2.5"
                                                                              fill="#ffffff"
                                                                            />
                                                                            <circle
                                                                              cx="12.4999"
                                                                              cy="11.5"
                                                                              r="2.5"
                                                                              fill="#ffffff"
                                                                            />
                                                                            <circle
                                                                              cx="12.4999"
                                                                              cy="19.5"
                                                                              r="2.5"
                                                                              fill="#ffffff"
                                                                            />
                                                                          </svg>
                                                                        </Dropdown.Toggle>
                                                                        <Dropdown.Menu className="dropdown-menu dropdown-menu-right">
                                                                          <Dropdown.Item
                                                                            onClick={() => {
                                                                              this.handleDeleteChatClick(
                                                                                true,
                                                                                oneMessage.id
                                                                              );
                                                                            }}
                                                                          >
                                                                            Delete
                                                                            for
                                                                            everyone
                                                                          </Dropdown.Item>
                                                                          <Dropdown.Item
                                                                            onClick={() => {
                                                                              this.handleDeleteChatClick(
                                                                                false,
                                                                                oneMessage.id
                                                                              );
                                                                            }}
                                                                          >
                                                                            Delete
                                                                            for
                                                                            me
                                                                          </Dropdown.Item>
                                                                        </Dropdown.Menu>
                                                                      </Dropdown>
                                                                    </div>
                                                                  </div>
                                                                </>
                                                              ) : this.getFileNameWithEx(
                                                                element?.attachments
                                                              ).ext ===
                                                                "docx" ? (
                                                                <>
                                                                  <div className="d-flex justify-content-between mb-0 ">
                                                                    <p
                                                                      className="mb-1 message"
                                                                      key={
                                                                        index
                                                                      }
                                                                      data-toggle="tooltip"
                                                                      data-placement="right"
                                                                      title="Download attachment"
                                                                      style={{
                                                                        maxWidth:
                                                                          "20rem",
                                                                      }}
                                                                    >
                                                                      <a
                                                                        className="text-center"
                                                                        href={
                                                                          element?.attachments
                                                                        }
                                                                        target="_blank"
                                                                        rel="noopener noreferrer"
                                                                        download
                                                                        key={
                                                                          index
                                                                        }
                                                                        data-toggle="tooltip"
                                                                        data-placement="right"
                                                                        title="Download attachment"
                                                                      >
                                                                        <svg
                                                                          color="#f8ca0e"
                                                                          xmlns="http://www.w3.org/2000/svg"
                                                                          width="38"
                                                                          height="38"
                                                                          fill="currentColor"
                                                                          className="bi bi-filetype-docx"
                                                                          viewBox="0 0 16 16"
                                                                        >
                                                                          <path
                                                                            fillRule="evenodd"
                                                                            d="M14 4.5V11h-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5L14 4.5Zm-6.839 9.688v-.522a1.54 1.54 0 0 0-.117-.641.861.861 0 0 0-.322-.387.862.862 0 0 0-.469-.129.868.868 0 0 0-.471.13.868.868 0 0 0-.32.386 1.54 1.54 0 0 0-.117.641v.522c0 .256.04.47.117.641a.868.868 0 0 0 .32.387.883.883 0 0 0 .471.126.877.877 0 0 0 .469-.126.861.861 0 0 0 .322-.386 1.55 1.55 0 0 0 .117-.642Zm.803-.516v.513c0 .375-.068.7-.205.973a1.47 1.47 0 0 1-.589.627c-.254.144-.56.216-.917.216a1.86 1.86 0 0 1-.92-.216 1.463 1.463 0 0 1-.589-.627 2.151 2.151 0 0 1-.205-.973v-.513c0-.379.069-.704.205-.975.137-.274.333-.483.59-.627.257-.147.564-.22.92-.22.357 0 .662.073.916.22.256.146.452.356.59.63.136.271.204.595.204.972ZM1 15.925v-3.999h1.459c.406 0 .741.078 1.005.235.264.156.46.382.589.68.13.296.196.655.196 1.074 0 .422-.065.784-.196 1.084-.131.301-.33.53-.595.689-.264.158-.597.237-.999.237H1Zm1.354-3.354H1.79v2.707h.563c.185 0 .346-.028.483-.082a.8.8 0 0 0 .334-.252c.088-.114.153-.254.196-.422a2.3 2.3 0 0 0 .068-.592c0-.3-.04-.552-.118-.753a.89.89 0 0 0-.354-.454c-.158-.102-.361-.152-.61-.152Zm6.756 1.116c0-.248.034-.46.103-.633a.868.868 0 0 1 .301-.398.814.814 0 0 1 .475-.138c.15 0 .283.032.398.097a.7.7 0 0 1 .273.26.85.85 0 0 1 .12.381h.765v-.073a1.33 1.33 0 0 0-.466-.964 1.44 1.44 0 0 0-.49-.272 1.836 1.836 0 0 0-.606-.097c-.355 0-.66.074-.911.223-.25.148-.44.359-.571.633-.131.273-.197.6-.197.978v.498c0 .379.065.704.194.976.13.271.321.48.571.627.25.144.555.216.914.216.293 0 .555-.054.785-.164.23-.11.414-.26.551-.454a1.27 1.27 0 0 0 .226-.674v-.076h-.765a.8.8 0 0 1-.117.364.699.699 0 0 1-.273.248.874.874 0 0 1-.401.088.845.845 0 0 1-.478-.131.834.834 0 0 1-.298-.393 1.7 1.7 0 0 1-.103-.627v-.495Zm5.092-1.76h.894l-1.275 2.006 1.254 1.992h-.908l-.85-1.415h-.035l-.852 1.415h-.862l1.24-2.015-1.228-1.984h.932l.832 1.439h.035l.823-1.439Z"
                                                                          />
                                                                        </svg>
                                                                        <span className="ms-2 mt-2 text-white font-w500">
                                                                          {this.getFileNameWithEx(
                                                                            element?.attachments
                                                                          )
                                                                            .files ||
                                                                            "-"}
                                                                        </span>
                                                                      </a>
                                                                    </p>
                                                                    <div className="dropdown-content1 text-center mt-2">
                                                                      <Dropdown className="dropdown float-center ms-4">
                                                                        <Dropdown.Toggle
                                                                          as="div"
                                                                          className="btn-link i-false p-1"
                                                                        >
                                                                          <svg
                                                                            width="20"
                                                                            height="20"
                                                                            viewBox="0 0 24 24"
                                                                            fill="none"
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                          >
                                                                            <circle
                                                                              cx="12.4999"
                                                                              cy="3.5"
                                                                              r="2.5"
                                                                              fill="#ffffff"
                                                                            />
                                                                            <circle
                                                                              cx="12.4999"
                                                                              cy="11.5"
                                                                              r="2.5"
                                                                              fill="#ffffff"
                                                                            />
                                                                            <circle
                                                                              cx="12.4999"
                                                                              cy="19.5"
                                                                              r="2.5"
                                                                              fill="#ffffff"
                                                                            />
                                                                          </svg>
                                                                        </Dropdown.Toggle>
                                                                        <Dropdown.Menu className="dropdown-menu dropdown-menu-right">
                                                                          <Dropdown.Item
                                                                            onClick={() => {
                                                                              this.handleDeleteChatClick(
                                                                                true,
                                                                                oneMessage.id
                                                                              );
                                                                            }}
                                                                          >
                                                                            Delete
                                                                            for
                                                                            everyone
                                                                          </Dropdown.Item>
                                                                          <Dropdown.Item
                                                                            onClick={() => {
                                                                              this.handleDeleteChatClick(
                                                                                false,
                                                                                oneMessage.id
                                                                              );
                                                                            }}
                                                                          >
                                                                            Delete
                                                                            for
                                                                            me
                                                                          </Dropdown.Item>
                                                                        </Dropdown.Menu>
                                                                      </Dropdown>
                                                                    </div>
                                                                  </div>
                                                                </>
                                                              ) : this.getFileNameWithEx(
                                                                element?.attachments
                                                              ).ext ===
                                                                "mp4" ||
                                                                this.getFileNameWithEx(
                                                                  element?.attachments
                                                                ).ext ===
                                                                "webm" ||
                                                                this.getFileNameWithEx(
                                                                  element?.attachments
                                                                ).ext ===
                                                                "mov" ||
                                                                this.getFileNameWithEx(
                                                                  element?.attachments
                                                                ).ext ===
                                                                "avi" ||
                                                                this.getFileNameWithEx(
                                                                  element?.attachments
                                                                ).ext ===
                                                                "m4v" ||
                                                                this.getFileNameWithEx(
                                                                  element?.attachments
                                                                ).ext ===
                                                                "mp3" ||
                                                                this.getFileNameWithEx(
                                                                  element?.attachments
                                                                ).ext ===
                                                                "mkv" ||
                                                                this.getFileNameWithEx(
                                                                  element?.attachments
                                                                ).ext ===
                                                                "m4a" ||
                                                                this.getFileNameWithEx(
                                                                  element?.attachments
                                                                ).ext ===
                                                                "mpg" ? (
                                                                <>
                                                                  <div className="d-flex justify-content-between mb-0 ">
                                                                    <p
                                                                      className="mb-1 message"
                                                                      key={
                                                                        index
                                                                      }
                                                                      data-toggle="tooltip"
                                                                      data-placement="right"
                                                                      title="Download attachment"
                                                                      style={{
                                                                        maxWidth:
                                                                          "20rem",
                                                                      }}
                                                                    >
                                                                      <a
                                                                        className="text-center"
                                                                        href={
                                                                          element?.attachments
                                                                        }
                                                                        target="_blank"
                                                                        rel="noopener noreferrer"
                                                                        download
                                                                        key={
                                                                          index
                                                                        }
                                                                        data-toggle="tooltip"
                                                                        data-placement="right"
                                                                        title="Download attachment"
                                                                      >
                                                                        <svg
                                                                          color="#96e60b"
                                                                          xmlns="http://www.w3.org/2000/svg"
                                                                          width="40"
                                                                          height="40"
                                                                          fill="currentColor"
                                                                          className="bi bi-file-play"
                                                                          viewBox="0 0 16 16"
                                                                        >
                                                                          <path d="M9.293 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.707A1 1 0 0 0 13.707 4L10 .293A1 1 0 0 0 9.293 0zM9.5 3.5v-2l3 3h-2a1 1 0 0 1-1-1zM6 6.883a.5.5 0 0 1 .757-.429l3.528 2.117a.5.5 0 0 1 0 .858l-3.528 2.117a.5.5 0 0 1-.757-.43V6.884z" />
                                                                        </svg>
                                                                        <span className="ms-2 mt-2 text-white font-w500">
                                                                          {this.getFileNameWithEx(
                                                                            element?.attachments
                                                                          )
                                                                            .files ||
                                                                            "-"}
                                                                        </span>
                                                                      </a>
                                                                    </p>
                                                                    <div className="dropdown-content1 text-center mt-2">
                                                                      <Dropdown className="dropdown float-center ms-4">
                                                                        <Dropdown.Toggle
                                                                          as="div"
                                                                          className="btn-link i-false p-1"
                                                                        >
                                                                          <svg
                                                                            width="20"
                                                                            height="20"
                                                                            viewBox="0 0 24 24"
                                                                            fill="none"
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                          >
                                                                            <circle
                                                                              cx="12.4999"
                                                                              cy="3.5"
                                                                              r="2.5"
                                                                              fill="#ffffff"
                                                                            />
                                                                            <circle
                                                                              cx="12.4999"
                                                                              cy="11.5"
                                                                              r="2.5"
                                                                              fill="#ffffff"
                                                                            />
                                                                            <circle
                                                                              cx="12.4999"
                                                                              cy="19.5"
                                                                              r="2.5"
                                                                              fill="#ffffff"
                                                                            />
                                                                          </svg>
                                                                        </Dropdown.Toggle>
                                                                        <Dropdown.Menu className="dropdown-menu dropdown-menu-right">
                                                                          <Dropdown.Item
                                                                            onClick={() => {
                                                                              this.handleDeleteChatClick(
                                                                                true,
                                                                                oneMessage.id
                                                                              );
                                                                            }}
                                                                          >
                                                                            Delete
                                                                            for
                                                                            everyone
                                                                          </Dropdown.Item>
                                                                          <Dropdown.Item
                                                                            onClick={() => {
                                                                              this.handleDeleteChatClick(
                                                                                false,
                                                                                oneMessage.id
                                                                              );
                                                                            }}
                                                                          >
                                                                            Delete
                                                                            for
                                                                            me
                                                                          </Dropdown.Item>
                                                                        </Dropdown.Menu>
                                                                      </Dropdown>
                                                                    </div>
                                                                  </div>
                                                                </>
                                                              ) : this.getFileNameWithEx(
                                                                element?.attachments
                                                              ).ext ===
                                                                "csv" ? (
                                                                <>
                                                                  <div className="d-flex justify-content-between mb-0 ">
                                                                    <p
                                                                      className="mb-1 message"
                                                                      key={
                                                                        index
                                                                      }
                                                                      data-toggle="tooltip"
                                                                      data-placement="right"
                                                                      title="Download attachment"
                                                                      style={{
                                                                        maxWidth:
                                                                          "20rem",
                                                                      }}
                                                                    >
                                                                      <a
                                                                        className="text-center"
                                                                        href={
                                                                          element?.attachments
                                                                        }
                                                                        target="_blank"
                                                                        rel="noopener noreferrer"
                                                                        download
                                                                        key={
                                                                          index
                                                                        }
                                                                        data-toggle="tooltip"
                                                                        data-placement="right"
                                                                        title="Download attachment"
                                                                      >
                                                                        <svg
                                                                          color="#55eda4"
                                                                          xmlns="http://www.w3.org/2000/svg"
                                                                          width="38"
                                                                          height="38"
                                                                          fill="currentColor"
                                                                          className="bi bi-filetype-csv"
                                                                          viewBox="0 0 16 16"
                                                                        >
                                                                          <path
                                                                            fillRule="evenodd"
                                                                            d="M14 4.5V14a2 2 0 0 1-2 2h-1v-1h1a1 1 0 0 0 1-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5L14 4.5ZM3.517 14.841a1.13 1.13 0 0 0 .401.823c.13.108.289.192.478.252.19.061.411.091.665.091.338 0 .624-.053.859-.158.236-.105.416-.252.539-.44.125-.189.187-.408.187-.656 0-.224-.045-.41-.134-.56a1.001 1.001 0 0 0-.375-.357 2.027 2.027 0 0 0-.566-.21l-.621-.144a.97.97 0 0 1-.404-.176.37.37 0 0 1-.144-.299c0-.156.062-.284.185-.384.125-.101.296-.152.512-.152.143 0 .266.023.37.068a.624.624 0 0 1 .246.181.56.56 0 0 1 .12.258h.75a1.092 1.092 0 0 0-.2-.566 1.21 1.21 0 0 0-.5-.41 1.813 1.813 0 0 0-.78-.152c-.293 0-.551.05-.776.15-.225.099-.4.24-.527.421-.127.182-.19.395-.19.639 0 .201.04.376.122.524.082.149.2.27.352.367.152.095.332.167.539.213l.618.144c.207.049.361.113.463.193a.387.387 0 0 1 .152.326.505.505 0 0 1-.085.29.559.559 0 0 1-.255.193c-.111.047-.249.07-.413.07-.117 0-.223-.013-.32-.04a.838.838 0 0 1-.248-.115.578.578 0 0 1-.255-.384h-.765ZM.806 13.693c0-.248.034-.46.102-.633a.868.868 0 0 1 .302-.399.814.814 0 0 1 .475-.137c.15 0 .283.032.398.097a.7.7 0 0 1 .272.26.85.85 0 0 1 .12.381h.765v-.072a1.33 1.33 0 0 0-.466-.964 1.441 1.441 0 0 0-.489-.272 1.838 1.838 0 0 0-.606-.097c-.356 0-.66.074-.911.223-.25.148-.44.359-.572.632-.13.274-.196.6-.196.979v.498c0 .379.064.704.193.976.131.271.322.48.572.626.25.145.554.217.914.217.293 0 .554-.055.785-.164.23-.11.414-.26.55-.454a1.27 1.27 0 0 0 .226-.674v-.076h-.764a.799.799 0 0 1-.118.363.7.7 0 0 1-.272.25.874.874 0 0 1-.401.087.845.845 0 0 1-.478-.132.833.833 0 0 1-.299-.392 1.699 1.699 0 0 1-.102-.627v-.495Zm8.239 2.238h-.953l-1.338-3.999h.917l.896 3.138h.038l.888-3.138h.879l-1.327 4Z"
                                                                          />
                                                                        </svg>
                                                                        <span className="ms-2 mt-2 text-white font-w500">
                                                                          {this.getFileNameWithEx(
                                                                            element?.attachments
                                                                          )
                                                                            .files ||
                                                                            "-"}
                                                                        </span>
                                                                      </a>
                                                                    </p>
                                                                    <div className="dropdown-content1 text-center mt-2">
                                                                      <Dropdown className="dropdown float-center ms-4">
                                                                        <Dropdown.Toggle
                                                                          as="div"
                                                                          className="btn-link i-false p-1"
                                                                        >
                                                                          <svg
                                                                            width="20"
                                                                            height="20"
                                                                            viewBox="0 0 24 24"
                                                                            fill="none"
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                          >
                                                                            <circle
                                                                              cx="12.4999"
                                                                              cy="3.5"
                                                                              r="2.5"
                                                                              fill="#ffffff"
                                                                            />
                                                                            <circle
                                                                              cx="12.4999"
                                                                              cy="11.5"
                                                                              r="2.5"
                                                                              fill="#ffffff"
                                                                            />
                                                                            <circle
                                                                              cx="12.4999"
                                                                              cy="19.5"
                                                                              r="2.5"
                                                                              fill="#ffffff"
                                                                            />
                                                                          </svg>
                                                                        </Dropdown.Toggle>
                                                                        <Dropdown.Menu className="dropdown-menu dropdown-menu-right">
                                                                          <Dropdown.Item
                                                                            onClick={() => {
                                                                              this.handleDeleteChatClick(
                                                                                true,
                                                                                oneMessage.id
                                                                              );
                                                                            }}
                                                                          >
                                                                            Delete
                                                                            for
                                                                            everyone
                                                                          </Dropdown.Item>
                                                                          <Dropdown.Item
                                                                            onClick={() => {
                                                                              this.handleDeleteChatClick(
                                                                                false,
                                                                                oneMessage.id
                                                                              );
                                                                            }}
                                                                          >
                                                                            Delete
                                                                            for
                                                                            me
                                                                          </Dropdown.Item>
                                                                        </Dropdown.Menu>
                                                                      </Dropdown>
                                                                    </div>
                                                                  </div>
                                                                </>
                                                              ) : this.getFileNameWithEx(
                                                                element?.attachments
                                                              ).ext ===
                                                                "ppt" ? (
                                                                <>
                                                                  <div className="d-flex justify-content-between mb-0 ">
                                                                    <p
                                                                      className="mb-1 message"
                                                                      key={
                                                                        index
                                                                      }
                                                                      data-toggle="tooltip"
                                                                      data-placement="right"
                                                                      title="Download attachment"
                                                                      style={{
                                                                        maxWidth:
                                                                          "20rem",
                                                                      }}
                                                                    >
                                                                      <a
                                                                        className="text-center"
                                                                        href={
                                                                          element?.attachments
                                                                        }
                                                                        target="_blank"
                                                                        rel="noopener noreferrer"
                                                                        download
                                                                        key={
                                                                          index
                                                                        }
                                                                        data-toggle="tooltip"
                                                                        data-placement="right"
                                                                        title="Download attachment"
                                                                      >
                                                                        <svg
                                                                          color="#33ccff"
                                                                          xmlns="http://www.w3.org/2000/svg"
                                                                          width="38"
                                                                          height="38"
                                                                          fill="currentColor"
                                                                          className="bi bi-filetype-ppt"
                                                                          viewBox="0 0 16 16"
                                                                        >
                                                                          <path
                                                                            fillRule="evenodd"
                                                                            d="M14 4.5V14a2 2 0 0 1-2 2h-1v-1h1a1 1 0 0 0 1-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5L14 4.5ZM1.6 11.85H0v3.999h.791v-1.342h.803c.287 0 .531-.057.732-.173.203-.117.358-.275.463-.474a1.42 1.42 0 0 0 .161-.677c0-.25-.053-.476-.158-.677a1.176 1.176 0 0 0-.46-.477c-.2-.12-.443-.179-.732-.179Zm.545 1.333a.795.795 0 0 1-.085.38.574.574 0 0 1-.238.241.794.794 0 0 1-.375.082H.788V12.48h.66c.218 0 .389.06.512.181.123.122.185.296.185.522Zm2.817-1.333h-1.6v3.999h.791v-1.342h.803c.287 0 .531-.057.732-.173.203-.117.358-.275.463-.474.108-.201.161-.427.161-.677 0-.25-.052-.476-.158-.677a1.176 1.176 0 0 0-.46-.477c-.2-.12-.443-.179-.732-.179Zm.545 1.333a.795.795 0 0 1-.085.38.574.574 0 0 1-.238.241.793.793 0 0 1-.375.082H4.15V12.48h.66c.218 0 .389.06.512.181.123.122.185.296.185.522Zm2.767-.67v3.336H7.48v-3.337H6.346v-.662h3.065v.662H8.274Z"
                                                                          />
                                                                        </svg>
                                                                        <span className="ms-2 mt-2 text-white font-w500">
                                                                          {this.getFileNameWithEx(
                                                                            element?.attachments
                                                                          )
                                                                            .files ||
                                                                            "-"}
                                                                        </span>
                                                                      </a>
                                                                    </p>
                                                                    <div className="dropdown-content1 text-center mt-2">
                                                                      <Dropdown className="dropdown float-center ms-4">
                                                                        <Dropdown.Toggle
                                                                          as="div"
                                                                          className="btn-link i-false p-1"
                                                                        >
                                                                          <svg
                                                                            width="20"
                                                                            height="20"
                                                                            viewBox="0 0 24 24"
                                                                            fill="none"
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                          >
                                                                            <circle
                                                                              cx="12.4999"
                                                                              cy="3.5"
                                                                              r="2.5"
                                                                              fill="#ffffff"
                                                                            />
                                                                            <circle
                                                                              cx="12.4999"
                                                                              cy="11.5"
                                                                              r="2.5"
                                                                              fill="#ffffff"
                                                                            />
                                                                            <circle
                                                                              cx="12.4999"
                                                                              cy="19.5"
                                                                              r="2.5"
                                                                              fill="#ffffff"
                                                                            />
                                                                          </svg>
                                                                        </Dropdown.Toggle>
                                                                        <Dropdown.Menu className="dropdown-menu dropdown-menu-right">
                                                                          <Dropdown.Item
                                                                            onClick={() => {
                                                                              this.handleDeleteChatClick(
                                                                                true,
                                                                                oneMessage.id
                                                                              );
                                                                            }}
                                                                          >
                                                                            Delete
                                                                            for
                                                                            everyone
                                                                          </Dropdown.Item>
                                                                          <Dropdown.Item
                                                                            onClick={() => {
                                                                              this.handleDeleteChatClick(
                                                                                false,
                                                                                oneMessage.id
                                                                              );
                                                                            }}
                                                                          >
                                                                            Delete
                                                                            for
                                                                            me
                                                                          </Dropdown.Item>
                                                                        </Dropdown.Menu>
                                                                      </Dropdown>
                                                                    </div>
                                                                  </div>
                                                                </>
                                                              ) : (
                                                                <>
                                                                  <div className="d-flex justify-content-between mb-0 ">
                                                                    <p
                                                                      className="mb-1 message"
                                                                      key={
                                                                        index
                                                                      }
                                                                      data-toggle="tooltip"
                                                                      data-placement="right"
                                                                      title="Download attachment"
                                                                      style={{
                                                                        maxWidth:
                                                                          "20rem",
                                                                      }}
                                                                    >
                                                                      <a
                                                                        className="text-center py-1 "
                                                                        href={
                                                                          element?.attachments
                                                                        }
                                                                        target="_blank"
                                                                        rel="noopener noreferrer"
                                                                        download
                                                                        key={
                                                                          index
                                                                        }
                                                                      >
                                                                        <img
                                                                          src={
                                                                            element?.attachments
                                                                          }
                                                                          className="img-fluid img-thumbnail p-1 m-0"
                                                                          alt="profile"
                                                                          title="profile"
                                                                          style={{
                                                                            height:
                                                                              "50px",
                                                                            width:
                                                                              "50px",
                                                                          }}
                                                                        ></img>
                                                                      </a>
                                                                      <h5 className="mt-2 text-white">
                                                                        {this.getFileNameWithEx(
                                                                          element?.attachments
                                                                        )
                                                                          ?.files ||
                                                                          "-"}
                                                                      </h5>
                                                                    </p>
                                                                    <div className="dropdown-content1 text-center mt-2">
                                                                      <Dropdown className="dropdown float-center ms-4">
                                                                        <Dropdown.Toggle
                                                                          as="div"
                                                                          className="btn-link i-false p-1"
                                                                        >
                                                                          <svg
                                                                            width="20"
                                                                            height="20"
                                                                            viewBox="0 0 24 24"
                                                                            fill="none"
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                          >
                                                                            <circle
                                                                              cx="12.4999"
                                                                              cy="3.5"
                                                                              r="2.5"
                                                                              fill="#ffffff"
                                                                            />
                                                                            <circle
                                                                              cx="12.4999"
                                                                              cy="11.5"
                                                                              r="2.5"
                                                                              fill="#ffffff"
                                                                            />
                                                                            <circle
                                                                              cx="12.4999"
                                                                              cy="19.5"
                                                                              r="2.5"
                                                                              fill="#ffffff"
                                                                            />
                                                                          </svg>
                                                                        </Dropdown.Toggle>
                                                                        <Dropdown.Menu className="dropdown-menu dropdown-menu-right">
                                                                          <Dropdown.Item
                                                                            onClick={() => {
                                                                              this.handleDeleteChatClick(
                                                                                true,
                                                                                oneMessage.id
                                                                              );
                                                                            }}
                                                                          >
                                                                            Delete
                                                                            for
                                                                            everyone
                                                                          </Dropdown.Item>
                                                                          <Dropdown.Item
                                                                            onClick={() => {
                                                                              this.handleDeleteChatClick(
                                                                                false,
                                                                                oneMessage.id
                                                                              );
                                                                            }}
                                                                          >
                                                                            Delete
                                                                            for
                                                                            me
                                                                          </Dropdown.Item>
                                                                        </Dropdown.Menu>
                                                                      </Dropdown>
                                                                    </div>
                                                                  </div>
                                                                </>
                                                              )
                                                          )}
                                                          {/* </p> */}
                                                        </div>
                                                      </div>
                                                      <div className="chat-img ms-2 ">
                                                        <img
                                                          src={
                                                            oneMessage.sender
                                                              .profile_picture ||
                                                            profilePicture
                                                          }
                                                          alt="chat-sender"
                                                          title="chat-sender"
                                                          className="rounded-circle img-1"
                                                        />
                                                        {oneMessage?.sender
                                                          ?.is_online ===
                                                          true ? (
                                                          <span
                                                            className="active"
                                                            style={{
                                                              bottom: "-1px",
                                                            }}
                                                          ></span>
                                                        ) : (
                                                          <span
                                                            className="inActive"
                                                            style={{
                                                              bottom: "-1px",
                                                            }}
                                                          ></span>
                                                        )}
                                                      </div>
                                                    </>
                                                  )}
                                              </>
                                            </>
                                          ) : (
                                            ""
                                          )}
                                        </div>
                                      )
                                      : (oneMessage?.text !== "" ||
                                        oneMessage.attachments.length !==
                                        0) && (
                                        <>
                                          {index ===
                                            this.state?.PrevMessage?.length -
                                            this.state.counter &&
                                            this.state?.counter !== 0 ? (
                                            <span
                                              style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                alignItems: "center",
                                              }}
                                            >
                                              <span
                                                className=""
                                                style={{
                                                  flex: "1",
                                                  borderBottom:
                                                    "2px solid #356FD4",
                                                  marginRight: "10px",
                                                }}
                                              ></span>
                                              <span
                                                className="mb-2 p-2"
                                                style={{
                                                  color: "#356FD4",
                                                  fontSize: "13px",
                                                  fontWeight: "65px",
                                                }}
                                              >
                                                Unread messages
                                              </span>
                                              <span
                                                style={{
                                                  flex: "1",
                                                  borderBottom:
                                                    "2px solid #356FD4",
                                                  marginLeft: "10px",
                                                }}
                                              ></span>
                                            </span>
                                          ) : null}

                                          <div
                                            className="media mb-2 justify-content-left align-items-start ms-5 message-row"
                                          // key={index}
                                          >
                                            {oneMessage?.text && (
                                              <>
                                                <div className="chat-img me-2">
                                                  <img
                                                    src={
                                                      oneMessage.sender
                                                        ?.profile_picture ||
                                                      profilePicture
                                                    }
                                                    alt="sender-img"
                                                  title="sender-img"

                                                    className="rounded-circle img-1"
                                                  />
                                                  {oneMessage?.sender
                                                    ?.is_online ? (
                                                    <span
                                                      className="active"
                                                      style={{
                                                        bottom: "-1px",
                                                      }}
                                                    ></span>
                                                  ) : (
                                                    <span
                                                      className="inActive"
                                                      style={{
                                                        bottom: "-1px",
                                                      }}
                                                    ></span>
                                                  )}
                                                </div>
                                                <div className="message-sentR dropdown1">
                                                  <span className="filterText fs-12">
                                                    {this.props?.props?.user
                                                      ?.room_type ===
                                                      "personal"
                                                      ? this.getTimeZone(
                                                        oneMessage?.created_at
                                                      )
                                                      : oneMessage?.sender
                                                        ?.first_name +
                                                      " " +
                                                      oneMessage?.sender
                                                        ?.last_name +
                                                      " " +
                                                      this.getTimeZone(
                                                        oneMessage?.created_at
                                                      )}
                                                  </span>
                                                  <div className="d-flex justify-content-start ">
                                                    <p
                                                      className="p me-3"
                                                      key={index}
                                                      style={{
                                                        minWidth: "6.5rem",
                                                        marginRight: "10vw",
                                                      }}
                                                    >
                                                       <div clsssName="mt-4 mb-3 p-4" style={{wordWrap:"break-word", padding:"4px"}} dangerouslySetInnerHTML={{ __html: this.convertUrlsToLinks(oneMessage?.text) }} />
                                                      {/* {oneMessage?.text} */}
                                                    </p>
                                                    <div className="dropdown-content1 text-center m-1 me-2">
                                                      <Dropdown className="dropdown float-center ms-2">
                                                        <Dropdown.Toggle
                                                          as="div"
                                                          className="btn-link i-false p-1"
                                                        >
                                                          <svg
                                                            width="20"
                                                            height="20"
                                                            viewBox="0 0 24 24"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                          >
                                                            <circle
                                                              cx="12.4999"
                                                              cy="3.5"
                                                              r="2.5"
                                                              fill="#121212"
                                                            />
                                                            <circle
                                                              cx="12.4999"
                                                              cy="11.5"
                                                              r="2.5"
                                                              fill="#121212"
                                                            />
                                                            <circle
                                                              cx="12.4999"
                                                              cy="19.5"
                                                              r="2.5"
                                                              fill="#121212"
                                                            />
                                                          </svg>
                                                        </Dropdown.Toggle>
                                                        <Dropdown.Menu className="dropdown-menu dropdown-menu-right">
                                                          <Dropdown.Item
                                                            onClick={() => {
                                                              this.handleDeleteChatClick(
                                                                false,
                                                                oneMessage.id
                                                              );
                                                            }}
                                                          >
                                                            Delete for me
                                                          </Dropdown.Item>
                                                        </Dropdown.Menu>
                                                      </Dropdown>
                                                    </div>
                                                  </div>
                                                </div>
                                              </>
                                            )}
                                            {oneMessage?.attachments
                                              ?.length !== 0 &&
                                              oneMessage?.attachments[0]
                                                ?.attachments?.length !== 0 ? (
                                              <>
                                                <>
                                                  {oneMessage?.attachments[0]
                                                    ?.attachments?.length !==
                                                    0 && (
                                                      <>
                                                        <div className="chat-img me-4 ">
                                                          <img
                                                            src={
                                                              oneMessage.sender
                                                                .profile_picture ||
                                                              profilePicture
                                                            }
                                                            alt="sender-profile-img"
                                                            title="sender-profile-img"
                                                            className="rounded-circle img-1"
                                                          />
                                                          {oneMessage?.sender
                                                            ?.is_online ? (
                                                            <span
                                                              className="active"
                                                              style={{
                                                                bottom: "-1px",
                                                              }}
                                                            ></span>
                                                          ) : (
                                                            <span
                                                              className="inActive"
                                                              style={{
                                                                bottom: "-1px",
                                                              }}
                                                            ></span>
                                                          )}
                                                        </div>
                                                        <div
                                                          className="message-sentR dropdown1"
                                                          style={{
                                                            minWidth: "20rem",
                                                            maxWidth: "20rem",
                                                          }}
                                                        >
                                                          <span className="filterText fs-12">
                                                            {this.props?.props
                                                              ?.user?.id ===
                                                              oneMessage?.sender
                                                                ?.id
                                                              ? this.getTimeZone(
                                                                oneMessage?.created_at
                                                              )
                                                              : oneMessage
                                                                ?.sender
                                                                ?.first_name +
                                                              " " +
                                                              oneMessage
                                                                ?.sender
                                                                ?.last_name +
                                                              " " +
                                                              this.getTimeZone(
                                                                oneMessage?.created_at
                                                              )}
                                                          </span>

                                                          {oneMessage?.attachments?.map(
                                                            (element, index) =>
                                                              this.getFileNameWithEx(
                                                                element?.attachments
                                                              ).ext ===
                                                                "pdf" ? (
                                                                <>
                                                                  <div
                                                                    className="d-flex justify-content-between mb-0 "
                                                                    key={index}
                                                                  >
                                                                    <p
                                                                      className="mb-1 message"
                                                                      key={
                                                                        index
                                                                      }
                                                                      data-toggle="tooltip"
                                                                      data-placement="right"
                                                                      title="Download attachment"
                                                                      style={{
                                                                        minWidth:
                                                                          "20rem",
                                                                      }}
                                                                    >
                                                                      <a
                                                                        className="text-center"
                                                                        href={
                                                                          element?.attachments
                                                                        }
                                                                        target="_blank"
                                                                        rel="noopener noreferrer"
                                                                        download
                                                                        key={
                                                                          index
                                                                        }
                                                                        data-toggle="tooltip"
                                                                        data-placement="right"
                                                                        title="Download attachment"
                                                                      >
                                                                        <svg
                                                                          color="red"
                                                                          xmlns="http://www.w3.org/2000/svg"
                                                                          width="38"
                                                                          height="38"
                                                                          fill="currentColor"
                                                                          className="bi bi-filetype-pdf"
                                                                          viewBox="0 0 16 16"
                                                                        >
                                                                          <path
                                                                            fillRule="evenodd"
                                                                            d="M14 4.5V14a2 2 0 0 1-2 2h-1v-1h1a1 1 0 0 0 1-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5L14 4.5ZM1.6 11.85H0v3.999h.791v-1.342h.803c.287 0 .531-.057.732-.173.203-.117.358-.275.463-.474a1.42 1.42 0 0 0 .161-.677c0-.25-.053-.476-.158-.677a1.176 1.176 0 0 0-.46-.477c-.2-.12-.443-.179-.732-.179Zm.545 1.333a.795.795 0 0 1-.085.38.574.574 0 0 1-.238.241.794.794 0 0 1-.375.082H.788V12.48h.66c.218 0 .389.06.512.181.123.122.185.296.185.522Zm1.217-1.333v3.999h1.46c.401 0 .734-.08.998-.237a1.45 1.45 0 0 0 .595-.689c.13-.3.196-.662.196-1.084 0-.42-.065-.778-.196-1.075a1.426 1.426 0 0 0-.589-.68c-.264-.156-.599-.234-1.005-.234H3.362Zm.791.645h.563c.248 0 .45.05.609.152a.89.89 0 0 1 .354.454c.079.201.118.452.118.753a2.3 2.3 0 0 1-.068.592 1.14 1.14 0 0 1-.196.422.8.8 0 0 1-.334.252 1.298 1.298 0 0 1-.483.082h-.563v-2.707Zm3.743 1.763v1.591h-.79V11.85h2.548v.653H7.896v1.117h1.606v.638H7.896Z"
                                                                          />
                                                                        </svg>
                                                                        <span className="p ms-2 mt-2">
                                                                          {this.getFileNameWithEx(
                                                                            element?.attachments
                                                                          )
                                                                            .files ||
                                                                            "-"}
                                                                        </span>
                                                                      </a>
                                                                    </p>
                                                                    <div className="dropdown-content1 text-center mt-2">
                                                                      <Dropdown className="dropdown float-center ms-4">
                                                                        <Dropdown.Toggle
                                                                          as="div"
                                                                          className="btn-link i-false p-1"
                                                                        >
                                                                          <svg
                                                                            width="20"
                                                                            height="20"
                                                                            viewBox="0 0 24 24"
                                                                            fill="none"
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                          >
                                                                            <circle
                                                                              cx="12.4999"
                                                                              cy="3.5"
                                                                              r="2.5"
                                                                              fill="#121212"
                                                                            />
                                                                            <circle
                                                                              cx="12.4999"
                                                                              cy="11.5"
                                                                              r="2.5"
                                                                              fill="#121212"
                                                                            />
                                                                            <circle
                                                                              cx="12.4999"
                                                                              cy="19.5"
                                                                              r="2.5"
                                                                              fill="#121212"
                                                                            />
                                                                          </svg>
                                                                        </Dropdown.Toggle>
                                                                        <Dropdown.Menu className="dropdown-menu dropdown-menu-right">
                                                                          <Dropdown.Item
                                                                            onClick={() => {
                                                                              this.setState(
                                                                                {
                                                                                  message:
                                                                                    oneMessage?.text,
                                                                                  msgID:
                                                                                    oneMessage.id,
                                                                                  isEdit: true,
                                                                                }
                                                                              );
                                                                            }}
                                                                          >
                                                                            Edit
                                                                          </Dropdown.Item>
                                                                          <Dropdown.Item
                                                                            onClick={() => {
                                                                              this.handleDeleteChatClick(
                                                                                true,
                                                                                oneMessage.id
                                                                              );
                                                                            }}
                                                                          >
                                                                            Delete
                                                                            for
                                                                            everyone
                                                                          </Dropdown.Item>
                                                                          <Dropdown.Item
                                                                            onClick={() => {
                                                                              this.handleDeleteChatClick(
                                                                                false,
                                                                                oneMessage.id
                                                                              );
                                                                            }}
                                                                          >
                                                                            Delete
                                                                            for
                                                                            me
                                                                          </Dropdown.Item>
                                                                        </Dropdown.Menu>
                                                                      </Dropdown>
                                                                    </div>
                                                                  </div>
                                                                </>
                                                              ) : this.getFileNameWithEx(
                                                                element?.attachments
                                                              ).ext ===
                                                                "txt" ? (
                                                                <>
                                                                  <div className="d-flex justify-content-between mb-0 ">
                                                                    <p
                                                                      className="mb-1 message"
                                                                      key={
                                                                        index
                                                                      }
                                                                      data-toggle="tooltip"
                                                                      data-placement="right"
                                                                      title="Download attachment"
                                                                      style={{
                                                                        minWidth:
                                                                          "20rem",
                                                                      }}
                                                                    >
                                                                      <a
                                                                        className="text-center"
                                                                        href={
                                                                          element?.attachments
                                                                        }
                                                                        target="_blank"
                                                                        rel="noopener noreferrer"
                                                                        download
                                                                        key={
                                                                          index
                                                                        }
                                                                        data-toggle="tooltip"
                                                                        data-placement="right"
                                                                        title="Download attachment"
                                                                      >
                                                                        <svg
                                                                          color="#df5276"
                                                                          xmlns="http://www.w3.org/2000/svg"
                                                                          width="38"
                                                                          height="38"
                                                                          fill="currentColor"
                                                                          className="bi bi-filetype-txt"
                                                                          viewBox="0 0 16 16"
                                                                        >
                                                                          <path
                                                                            fillRule="evenodd"
                                                                            d="M14 4.5V14a2 2 0 0 1-2 2h-2v-1h2a1 1 0 0 0 1-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5L14 4.5ZM1.928 15.849v-3.337h1.136v-.662H0v.662h1.134v3.337h.794Zm4.689-3.999h-.894L4.9 13.289h-.035l-.832-1.439h-.932l1.228 1.983-1.24 2.016h.862l.853-1.415h.035l.85 1.415h.907l-1.253-1.992 1.274-2.007Zm1.93.662v3.337h-.794v-3.337H6.619v-.662h3.064v.662H8.546Z"
                                                                          />
                                                                        </svg>
                                                                        <span className="p ms-2 mt-2">
                                                                          {this.getFileNameWithEx(
                                                                            element?.attachments
                                                                          )
                                                                            .files ||
                                                                            "-"}
                                                                        </span>
                                                                      </a>
                                                                    </p>
                                                                    <div className="dropdown-content1 text-center mt-2">
                                                                      <Dropdown className="dropdown float-center ms-4">
                                                                        <Dropdown.Toggle
                                                                          as="div"
                                                                          className="btn-link i-false p-1"
                                                                        >
                                                                          <svg
                                                                            width="20"
                                                                            height="20"
                                                                            viewBox="0 0 24 24"
                                                                            fill="none"
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                          >
                                                                            <circle
                                                                              cx="12.4999"
                                                                              cy="3.5"
                                                                              r="2.5"
                                                                              fill="#121212"
                                                                            />
                                                                            <circle
                                                                              cx="12.4999"
                                                                              cy="11.5"
                                                                              r="2.5"
                                                                              fill="#121212"
                                                                            />
                                                                            <circle
                                                                              cx="12.4999"
                                                                              cy="19.5"
                                                                              r="2.5"
                                                                              fill="#121212"
                                                                            />
                                                                          </svg>
                                                                        </Dropdown.Toggle>
                                                                        <Dropdown.Menu className="dropdown-menu dropdown-menu-right">
                                                                          <Dropdown.Item
                                                                            onClick={() => {
                                                                              this.handleDeleteChatClick(
                                                                                false,
                                                                                oneMessage.id
                                                                              );
                                                                            }}
                                                                          >
                                                                            Delete
                                                                            for
                                                                            me
                                                                          </Dropdown.Item>
                                                                        </Dropdown.Menu>
                                                                      </Dropdown>
                                                                    </div>
                                                                  </div>
                                                                </>
                                                              ) : this.getFileNameWithEx(
                                                                element?.attachments
                                                              ).ext ===
                                                                "xlsx" ? (
                                                                <>
                                                                  <div className="d-flex justify-content-between mb-0 ">
                                                                    <p
                                                                      className="mb-1 message"
                                                                      key={
                                                                        index
                                                                      }
                                                                      data-toggle="tooltip"
                                                                      data-placement="right"
                                                                      title="Download attachment"
                                                                      style={{
                                                                        minWidth:
                                                                          "20rem",
                                                                      }}
                                                                    >
                                                                      <a
                                                                        className="text-center"
                                                                        href={
                                                                          element?.attachments
                                                                        }
                                                                        target="_blank"
                                                                        rel="noopener noreferrer"
                                                                        download
                                                                        key={
                                                                          index
                                                                        }
                                                                        data-toggle="tooltip"
                                                                        data-placement="right"
                                                                        title="Download attachment"
                                                                      >
                                                                        <svg
                                                                          color="#e5f10b"
                                                                          xmlns="http://www.w3.org/2000/svg"
                                                                          width="38"
                                                                          height="38"
                                                                          fill="currentColor"
                                                                          className="bi bi-filetype-xlsx"
                                                                          viewBox="0 0 16 16"
                                                                        >
                                                                          <path
                                                                            fillRule="evenodd"
                                                                            d="M14 4.5V11h-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5L14 4.5ZM7.86 14.841a1.13 1.13 0 0 0 .401.823c.13.108.29.192.479.252.19.061.411.091.665.091.338 0 .624-.053.858-.158.237-.105.416-.252.54-.44a1.17 1.17 0 0 0 .187-.656c0-.224-.045-.41-.135-.56a1.002 1.002 0 0 0-.375-.357 2.028 2.028 0 0 0-.565-.21l-.621-.144a.97.97 0 0 1-.405-.176.37.37 0 0 1-.143-.299c0-.156.061-.284.184-.384.125-.101.296-.152.513-.152.143 0 .266.023.37.068a.624.624 0 0 1 .245.181.56.56 0 0 1 .12.258h.75a1.093 1.093 0 0 0-.199-.566 1.21 1.21 0 0 0-.5-.41 1.813 1.813 0 0 0-.78-.152c-.293 0-.552.05-.777.15-.224.099-.4.24-.527.421-.127.182-.19.395-.19.639 0 .201.04.376.123.524.082.149.199.27.351.367.153.095.332.167.54.213l.618.144c.207.049.36.113.462.193a.387.387 0 0 1 .153.326.512.512 0 0 1-.085.29.558.558 0 0 1-.255.193c-.111.047-.25.07-.413.07-.117 0-.224-.013-.32-.04a.837.837 0 0 1-.249-.115.578.578 0 0 1-.255-.384h-.764Zm-3.726-2.909h.893l-1.274 2.007 1.254 1.992h-.908l-.85-1.415h-.035l-.853 1.415H1.5l1.24-2.016-1.228-1.983h.931l.832 1.438h.036l.823-1.438Zm1.923 3.325h1.697v.674H5.266v-3.999h.791v3.325Zm7.636-3.325h.893l-1.274 2.007 1.254 1.992h-.908l-.85-1.415h-.035l-.853 1.415h-.861l1.24-2.016-1.228-1.983h.931l.832 1.438h.036l.823-1.438Z"
                                                                          />
                                                                        </svg>

                                                                        <span className="p ms-2 mt-2 ">
                                                                          {this.getFileNameWithEx(
                                                                            element?.attachments
                                                                          )
                                                                            .files ||
                                                                            "-"}
                                                                        </span>
                                                                      </a>
                                                                    </p>
                                                                    <div className="dropdown-content1 text-center mt-2">
                                                                      <Dropdown className="dropdown float-center ms-4">
                                                                        <Dropdown.Toggle
                                                                          as="div"
                                                                          className="btn-link i-false p-1"
                                                                        >
                                                                          <svg
                                                                            width="20"
                                                                            height="20"
                                                                            viewBox="0 0 24 24"
                                                                            fill="none"
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                          >
                                                                            <circle
                                                                              cx="12.4999"
                                                                              cy="3.5"
                                                                              r="2.5"
                                                                              fill="#121212"
                                                                            />
                                                                            <circle
                                                                              cx="12.4999"
                                                                              cy="11.5"
                                                                              r="2.5"
                                                                              fill="#121212"
                                                                            />
                                                                            <circle
                                                                              cx="12.4999"
                                                                              cy="19.5"
                                                                              r="2.5"
                                                                              fill="#121212"
                                                                            />
                                                                          </svg>
                                                                        </Dropdown.Toggle>
                                                                        <Dropdown.Menu className="dropdown-menu dropdown-menu-right">
                                                                          <Dropdown.Item
                                                                            onClick={() => {
                                                                              this.handleDeleteChatClick(
                                                                                false,
                                                                                oneMessage.id
                                                                              );
                                                                            }}
                                                                          >
                                                                            Delete
                                                                            for
                                                                            me
                                                                          </Dropdown.Item>
                                                                        </Dropdown.Menu>
                                                                      </Dropdown>
                                                                    </div>
                                                                  </div>
                                                                </>
                                                              ) : this.getFileNameWithEx(
                                                                element?.attachments
                                                              ).ext ===
                                                                "xls" ? (
                                                                <>
                                                                  <div className="d-flex justify-content-between mb-0 ">
                                                                    <p
                                                                      className="mb-1 message"
                                                                      key={
                                                                        index
                                                                      }
                                                                      data-toggle="tooltip"
                                                                      data-placement="right"
                                                                      title="Download attachment"
                                                                      style={{
                                                                        minWidth:
                                                                          "20rem",
                                                                      }}
                                                                    >
                                                                      <a
                                                                        className="text-center"
                                                                        href={
                                                                          element?.attachments
                                                                        }
                                                                        target="_blank"
                                                                        rel="noopener noreferrer"
                                                                        download
                                                                        key={
                                                                          index
                                                                        }
                                                                        data-toggle="tooltip"
                                                                        data-placement="right"
                                                                        title="Download attachment"
                                                                      >
                                                                        <svg
                                                                          color="#0ef216"
                                                                          xmlns="http://www.w3.org/2000/svg"
                                                                          width="38"
                                                                          height="38"
                                                                          fill="currentColor"
                                                                          className="bi bi-filetype-xls"
                                                                          viewBox="0 0 16 16"
                                                                        >
                                                                          <path
                                                                            fillRule="evenodd"
                                                                            d="M14 4.5V14a2 2 0 0 1-2 2h-1v-1h1a1 1 0 0 0 1-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5L14 4.5ZM6.472 15.29a1.176 1.176 0 0 1-.111-.449h.765a.578.578 0 0 0 .254.384c.07.049.154.087.25.114.095.028.202.041.319.041.164 0 .302-.023.413-.07a.559.559 0 0 0 .255-.193.507.507 0 0 0 .085-.29.387.387 0 0 0-.153-.326c-.101-.08-.255-.144-.462-.193l-.619-.143a1.72 1.72 0 0 1-.539-.214 1.001 1.001 0 0 1-.351-.367 1.068 1.068 0 0 1-.123-.524c0-.244.063-.457.19-.639.127-.181.303-.322.527-.422.225-.1.484-.149.777-.149.305 0 .564.05.78.152.216.102.383.239.5.41.12.17.186.359.2.566h-.75a.56.56 0 0 0-.12-.258.625.625 0 0 0-.247-.181.923.923 0 0 0-.369-.068c-.217 0-.388.05-.513.152a.472.472 0 0 0-.184.384c0 .121.048.22.143.3a.97.97 0 0 0 .405.175l.62.143c.217.05.406.12.566.211a1 1 0 0 1 .375.358c.09.148.135.335.135.56 0 .247-.063.466-.188.656a1.216 1.216 0 0 1-.539.439c-.234.105-.52.158-.858.158-.254 0-.476-.03-.665-.09a1.404 1.404 0 0 1-.478-.252 1.13 1.13 0 0 1-.29-.375Zm-2.945-3.358h-.893L1.81 13.37h-.036l-.832-1.438h-.93l1.227 1.983L0 15.931h.861l.853-1.415h.035l.85 1.415h.908L2.253 13.94l1.274-2.007Zm2.727 3.325H4.557v-3.325h-.79v4h2.487v-.675Z"
                                                                          />
                                                                        </svg>
                                                                        <span className="p ms-2 mt-2 ">
                                                                          {this.getFileNameWithEx(
                                                                            element?.attachments
                                                                          )
                                                                            .files ||
                                                                            "-"}
                                                                        </span>
                                                                      </a>
                                                                    </p>
                                                                    <div className="dropdown-content1 text-center mt-2">
                                                                      <Dropdown className="dropdown float-center ms-4">
                                                                        <Dropdown.Toggle
                                                                          as="div"
                                                                          className="btn-link i-false p-1"
                                                                        >
                                                                          <svg
                                                                            width="20"
                                                                            height="20"
                                                                            viewBox="0 0 24 24"
                                                                            fill="none"
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                          >
                                                                            <circle
                                                                              cx="12.4999"
                                                                              cy="3.5"
                                                                              r="2.5"
                                                                              fill="#121212"
                                                                            />
                                                                            <circle
                                                                              cx="12.4999"
                                                                              cy="11.5"
                                                                              r="2.5"
                                                                              fill="#121212"
                                                                            />
                                                                            <circle
                                                                              cx="12.4999"
                                                                              cy="19.5"
                                                                              r="2.5"
                                                                              fill="#121212"
                                                                            />
                                                                          </svg>
                                                                        </Dropdown.Toggle>
                                                                        <Dropdown.Menu className="dropdown-menu dropdown-menu-right">
                                                                          <Dropdown.Item
                                                                            onClick={() => {
                                                                              this.handleDeleteChatClick(
                                                                                false,
                                                                                oneMessage.id
                                                                              );
                                                                            }}
                                                                          >
                                                                            Delete
                                                                            for
                                                                            me
                                                                          </Dropdown.Item>
                                                                        </Dropdown.Menu>
                                                                      </Dropdown>
                                                                    </div>
                                                                  </div>
                                                                </>
                                                              ) : this.getFileNameWithEx(
                                                                element?.attachments
                                                              ).ext ===
                                                                "doc" ? (
                                                                <>
                                                                  <div className="d-flex justify-content-between mb-0 ">
                                                                    <p
                                                                      className="mb-1 message"
                                                                      key={
                                                                        index
                                                                      }
                                                                      data-toggle="tooltip"
                                                                      data-placement="right"
                                                                      title="Download attachment"
                                                                      style={{
                                                                        minWidth:
                                                                          "20rem",
                                                                      }}
                                                                    >
                                                                      <a
                                                                        className="text-center"
                                                                        href={
                                                                          element?.attachments
                                                                        }
                                                                        target="_blank"
                                                                        rel="noopener noreferrer"
                                                                        download
                                                                        key={
                                                                          index
                                                                        }
                                                                        data-toggle="tooltip"
                                                                        data-placement="right"
                                                                        title="Download attachment"
                                                                      >
                                                                        <svg
                                                                          color="#f78717"
                                                                          xmlns="http://www.w3.org/2000/svg"
                                                                          width="38"
                                                                          height="38"
                                                                          fill="currentColor"
                                                                          className="bi bi-filetype-doc"
                                                                          viewBox="0 0 16 16"
                                                                        >
                                                                          <path
                                                                            fillRule="evenodd"
                                                                            d="M14 4.5V14a2 2 0 0 1-2 2v-1a1 1 0 0 0 1-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5L14 4.5Zm-7.839 9.166v.522c0 .256-.039.47-.117.641a.861.861 0 0 1-.322.387.877.877 0 0 1-.469.126.883.883 0 0 1-.471-.126.868.868 0 0 1-.32-.386 1.55 1.55 0 0 1-.117-.642v-.522c0-.257.04-.471.117-.641a.868.868 0 0 1 .32-.387.868.868 0 0 1 .471-.129c.176 0 .332.043.469.13a.861.861 0 0 1 .322.386c.078.17.117.384.117.641Zm.803.519v-.513c0-.377-.068-.7-.205-.972a1.46 1.46 0 0 0-.589-.63c-.254-.147-.56-.22-.917-.22-.355 0-.662.073-.92.22a1.441 1.441 0 0 0-.589.627c-.136.271-.205.596-.205.975v.513c0 .375.069.7.205.973.137.271.333.48.59.627.257.144.564.216.92.216.357 0 .662-.072.916-.216.256-.147.452-.356.59-.627.136-.274.204-.598.204-.973ZM0 11.926v4h1.459c.402 0 .735-.08.999-.238a1.45 1.45 0 0 0 .595-.689c.13-.3.196-.662.196-1.084 0-.42-.065-.778-.196-1.075a1.426 1.426 0 0 0-.59-.68c-.263-.156-.598-.234-1.004-.234H0Zm.791.645h.563c.248 0 .45.05.609.152a.89.89 0 0 1 .354.454c.079.201.118.452.118.753a2.3 2.3 0 0 1-.068.592 1.141 1.141 0 0 1-.196.422.8.8 0 0 1-.334.252 1.298 1.298 0 0 1-.483.082H.79V12.57Zm7.422.483a1.732 1.732 0 0 0-.103.633v.495c0 .246.034.455.103.627a.834.834 0 0 0 .298.393.845.845 0 0 0 .478.131.868.868 0 0 0 .401-.088.699.699 0 0 0 .273-.248.8.8 0 0 0 .117-.364h.765v.076a1.268 1.268 0 0 1-.226.674c-.137.194-.32.345-.55.454a1.81 1.81 0 0 1-.786.164c-.36 0-.664-.072-.914-.216a1.424 1.424 0 0 1-.571-.627c-.13-.272-.194-.597-.194-.976v-.498c0-.379.066-.705.197-.978.13-.274.321-.485.571-.633.252-.149.556-.223.911-.223.219 0 .421.032.607.097.187.062.35.153.489.272a1.326 1.326 0 0 1 .466.964v.073H9.78a.85.85 0 0 0-.12-.38.7.7 0 0 0-.273-.261.802.802 0 0 0-.398-.097.814.814 0 0 0-.475.138.868.868 0 0 0-.301.398Z"
                                                                          />
                                                                        </svg>
                                                                        <span className="p ms-2 mt-2 ">
                                                                          {this.getFileNameWithEx(
                                                                            element?.attachments
                                                                          )
                                                                            .files ||
                                                                            "-"}
                                                                        </span>
                                                                      </a>
                                                                    </p>
                                                                    <div className="dropdown-content1 text-center mt-2">
                                                                      <Dropdown className="dropdown float-center ms-4">
                                                                        <Dropdown.Toggle
                                                                          as="div"
                                                                          className="btn-link i-false p-1"
                                                                        >
                                                                          <svg
                                                                            width="20"
                                                                            height="20"
                                                                            viewBox="0 0 24 24"
                                                                            fill="none"
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                          >
                                                                            <circle
                                                                              cx="12.4999"
                                                                              cy="3.5"
                                                                              r="2.5"
                                                                              fill="#121212"
                                                                            />
                                                                            <circle
                                                                              cx="12.4999"
                                                                              cy="11.5"
                                                                              r="2.5"
                                                                              fill="#121212"
                                                                            />
                                                                            <circle
                                                                              cx="12.4999"
                                                                              cy="19.5"
                                                                              r="2.5"
                                                                              fill="#121212"
                                                                            />
                                                                          </svg>
                                                                        </Dropdown.Toggle>
                                                                        <Dropdown.Menu className="dropdown-menu dropdown-menu-right">
                                                                          <Dropdown.Item
                                                                            onClick={() => {
                                                                              this.handleDeleteChatClick(
                                                                                false,
                                                                                oneMessage.id
                                                                              );
                                                                            }}
                                                                          >
                                                                            Delete
                                                                            for
                                                                            me
                                                                          </Dropdown.Item>
                                                                        </Dropdown.Menu>
                                                                      </Dropdown>
                                                                    </div>
                                                                  </div>
                                                                </>
                                                              ) : this.getFileNameWithEx(
                                                                element?.attachments
                                                              ).ext ===
                                                                "docx" ? (
                                                                <>
                                                                  <div className="d-flex justify-content-between mb-0 ">
                                                                    <p
                                                                      className="mb-1 message"
                                                                      key={
                                                                        index
                                                                      }
                                                                      data-toggle="tooltip"
                                                                      data-placement="right"
                                                                      title="Download attachment"
                                                                      style={{
                                                                        minWidth:
                                                                          "20rem",
                                                                      }}
                                                                    >
                                                                      <a
                                                                        className="text-center"
                                                                        href={
                                                                          element?.attachments
                                                                        }
                                                                        target="_blank"
                                                                        rel="noopener noreferrer"
                                                                        download
                                                                        key={
                                                                          index
                                                                        }
                                                                        data-toggle="tooltip"
                                                                        data-placement="right"
                                                                        title="Download attachment"
                                                                      >
                                                                        <svg
                                                                          color="#f8ca0e"
                                                                          xmlns="http://www.w3.org/2000/svg"
                                                                          width="38"
                                                                          height="38"
                                                                          fill="currentColor"
                                                                          className="bi bi-filetype-docx"
                                                                          viewBox="0 0 16 16"
                                                                        >
                                                                          <path
                                                                            fillRule="evenodd"
                                                                            d="M14 4.5V11h-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5L14 4.5Zm-6.839 9.688v-.522a1.54 1.54 0 0 0-.117-.641.861.861 0 0 0-.322-.387.862.862 0 0 0-.469-.129.868.868 0 0 0-.471.13.868.868 0 0 0-.32.386 1.54 1.54 0 0 0-.117.641v.522c0 .256.04.47.117.641a.868.868 0 0 0 .32.387.883.883 0 0 0 .471.126.877.877 0 0 0 .469-.126.861.861 0 0 0 .322-.386 1.55 1.55 0 0 0 .117-.642Zm.803-.516v.513c0 .375-.068.7-.205.973a1.47 1.47 0 0 1-.589.627c-.254.144-.56.216-.917.216a1.86 1.86 0 0 1-.92-.216 1.463 1.463 0 0 1-.589-.627 2.151 2.151 0 0 1-.205-.973v-.513c0-.379.069-.704.205-.975.137-.274.333-.483.59-.627.257-.147.564-.22.92-.22.357 0 .662.073.916.22.256.146.452.356.59.63.136.271.204.595.204.972ZM1 15.925v-3.999h1.459c.406 0 .741.078 1.005.235.264.156.46.382.589.68.13.296.196.655.196 1.074 0 .422-.065.784-.196 1.084-.131.301-.33.53-.595.689-.264.158-.597.237-.999.237H1Zm1.354-3.354H1.79v2.707h.563c.185 0 .346-.028.483-.082a.8.8 0 0 0 .334-.252c.088-.114.153-.254.196-.422a2.3 2.3 0 0 0 .068-.592c0-.3-.04-.552-.118-.753a.89.89 0 0 0-.354-.454c-.158-.102-.361-.152-.61-.152Zm6.756 1.116c0-.248.034-.46.103-.633a.868.868 0 0 1 .301-.398.814.814 0 0 1 .475-.138c.15 0 .283.032.398.097a.7.7 0 0 1 .273.26.85.85 0 0 1 .12.381h.765v-.073a1.33 1.33 0 0 0-.466-.964 1.44 1.44 0 0 0-.49-.272 1.836 1.836 0 0 0-.606-.097c-.355 0-.66.074-.911.223-.25.148-.44.359-.571.633-.131.273-.197.6-.197.978v.498c0 .379.065.704.194.976.13.271.321.48.571.627.25.144.555.216.914.216.293 0 .555-.054.785-.164.23-.11.414-.26.551-.454a1.27 1.27 0 0 0 .226-.674v-.076h-.765a.8.8 0 0 1-.117.364.699.699 0 0 1-.273.248.874.874 0 0 1-.401.088.845.845 0 0 1-.478-.131.834.834 0 0 1-.298-.393 1.7 1.7 0 0 1-.103-.627v-.495Zm5.092-1.76h.894l-1.275 2.006 1.254 1.992h-.908l-.85-1.415h-.035l-.852 1.415h-.862l1.24-2.015-1.228-1.984h.932l.832 1.439h.035l.823-1.439Z"
                                                                          />
                                                                        </svg>
                                                                        <span className="p ms-2 mt-2 ">
                                                                          {this.getFileNameWithEx(
                                                                            element?.attachments
                                                                          )
                                                                            .files ||
                                                                            "-"}
                                                                        </span>
                                                                      </a>
                                                                    </p>
                                                                    <div className="dropdown-content1 text-center mt-2">
                                                                      <Dropdown className="dropdown float-center ms-4">
                                                                        <Dropdown.Toggle
                                                                          as="div"
                                                                          className="btn-link i-false p-1"
                                                                        >
                                                                          <svg
                                                                            width="20"
                                                                            height="20"
                                                                            viewBox="0 0 24 24"
                                                                            fill="none"
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                          >
                                                                            <circle
                                                                              cx="12.4999"
                                                                              cy="3.5"
                                                                              r="2.5"
                                                                              fill="#121212"
                                                                            />
                                                                            <circle
                                                                              cx="12.4999"
                                                                              cy="11.5"
                                                                              r="2.5"
                                                                              fill="#121212"
                                                                            />
                                                                            <circle
                                                                              cx="12.4999"
                                                                              cy="19.5"
                                                                              r="2.5"
                                                                              fill="#121212"
                                                                            />
                                                                          </svg>
                                                                        </Dropdown.Toggle>
                                                                        <Dropdown.Menu className="dropdown-menu dropdown-menu-right">
                                                                          <Dropdown.Item
                                                                            onClick={() => {
                                                                              this.handleDeleteChatClick(
                                                                                false,
                                                                                oneMessage.id
                                                                              );
                                                                            }}
                                                                          >
                                                                            Delete
                                                                            for
                                                                            me
                                                                          </Dropdown.Item>
                                                                        </Dropdown.Menu>
                                                                      </Dropdown>
                                                                    </div>
                                                                  </div>
                                                                </>
                                                              ) : this.getFileNameWithEx(
                                                                element?.attachments
                                                              ).ext ===
                                                                "csv" ? (
                                                                <>
                                                                  <div className="d-flex justify-content-between mb-0 ">
                                                                    <p
                                                                      className="mb-1 message"
                                                                      key={
                                                                        index
                                                                      }
                                                                      data-toggle="tooltip"
                                                                      data-placement="right"
                                                                      title="Download attachment"
                                                                      style={{
                                                                        minWidth:
                                                                          "20rem",
                                                                      }}
                                                                    >
                                                                      <a
                                                                        className="text-center"
                                                                        href={
                                                                          element?.attachments
                                                                        }
                                                                        target="_blank"
                                                                        rel="noopener noreferrer"
                                                                        download
                                                                        key={
                                                                          index
                                                                        }
                                                                        data-toggle="tooltip"
                                                                        data-placement="right"
                                                                        title="Download attachment"
                                                                      >
                                                                        <svg
                                                                          color="#55eda4"
                                                                          xmlns="http://www.w3.org/2000/svg"
                                                                          width="38"
                                                                          height="38"
                                                                          fill="currentColor"
                                                                          className="bi bi-filetype-csv"
                                                                          viewBox="0 0 16 16"
                                                                        >
                                                                          <path
                                                                            fillRule="evenodd"
                                                                            d="M14 4.5V14a2 2 0 0 1-2 2h-1v-1h1a1 1 0 0 0 1-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5L14 4.5ZM3.517 14.841a1.13 1.13 0 0 0 .401.823c.13.108.289.192.478.252.19.061.411.091.665.091.338 0 .624-.053.859-.158.236-.105.416-.252.539-.44.125-.189.187-.408.187-.656 0-.224-.045-.41-.134-.56a1.001 1.001 0 0 0-.375-.357 2.027 2.027 0 0 0-.566-.21l-.621-.144a.97.97 0 0 1-.404-.176.37.37 0 0 1-.144-.299c0-.156.062-.284.185-.384.125-.101.296-.152.512-.152.143 0 .266.023.37.068a.624.624 0 0 1 .246.181.56.56 0 0 1 .12.258h.75a1.092 1.092 0 0 0-.2-.566 1.21 1.21 0 0 0-.5-.41 1.813 1.813 0 0 0-.78-.152c-.293 0-.551.05-.776.15-.225.099-.4.24-.527.421-.127.182-.19.395-.19.639 0 .201.04.376.122.524.082.149.2.27.352.367.152.095.332.167.539.213l.618.144c.207.049.361.113.463.193a.387.387 0 0 1 .152.326.505.505 0 0 1-.085.29.559.559 0 0 1-.255.193c-.111.047-.249.07-.413.07-.117 0-.223-.013-.32-.04a.838.838 0 0 1-.248-.115.578.578 0 0 1-.255-.384h-.765ZM.806 13.693c0-.248.034-.46.102-.633a.868.868 0 0 1 .302-.399.814.814 0 0 1 .475-.137c.15 0 .283.032.398.097a.7.7 0 0 1 .272.26.85.85 0 0 1 .12.381h.765v-.072a1.33 1.33 0 0 0-.466-.964 1.441 1.441 0 0 0-.489-.272 1.838 1.838 0 0 0-.606-.097c-.356 0-.66.074-.911.223-.25.148-.44.359-.572.632-.13.274-.196.6-.196.979v.498c0 .379.064.704.193.976.131.271.322.48.572.626.25.145.554.217.914.217.293 0 .554-.055.785-.164.23-.11.414-.26.55-.454a1.27 1.27 0 0 0 .226-.674v-.076h-.764a.799.799 0 0 1-.118.363.7.7 0 0 1-.272.25.874.874 0 0 1-.401.087.845.845 0 0 1-.478-.132.833.833 0 0 1-.299-.392 1.699 1.699 0 0 1-.102-.627v-.495Zm8.239 2.238h-.953l-1.338-3.999h.917l.896 3.138h.038l.888-3.138h.879l-1.327 4Z"
                                                                          />
                                                                        </svg>
                                                                        <span className="p ms-2 mt-2 ">
                                                                          {this.getFileNameWithEx(
                                                                            element?.attachments
                                                                          )
                                                                            .files ||
                                                                            "-"}
                                                                        </span>
                                                                      </a>
                                                                    </p>
                                                                    <div className="dropdown-content1 text-center mt-2">
                                                                      <Dropdown className="dropdown float-center ms-4">
                                                                        <Dropdown.Toggle
                                                                          as="div"
                                                                          className="btn-link i-false p-1"
                                                                        >
                                                                          <svg
                                                                            width="20"
                                                                            height="20"
                                                                            viewBox="0 0 24 24"
                                                                            fill="none"
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                          >
                                                                            <circle
                                                                              cx="12.4999"
                                                                              cy="3.5"
                                                                              r="2.5"
                                                                              fill="#ffffff"
                                                                            />
                                                                            <circle
                                                                              cx="12.4999"
                                                                              cy="11.5"
                                                                              r="2.5"
                                                                              fill="#ffffff"
                                                                            />
                                                                            <circle
                                                                              cx="12.4999"
                                                                              cy="19.5"
                                                                              r="2.5"
                                                                              fill="#ffffff"
                                                                            />
                                                                          </svg>
                                                                        </Dropdown.Toggle>
                                                                        <Dropdown.Menu className="dropdown-menu dropdown-menu-right">
                                                                          <Dropdown.Item
                                                                            onClick={() => {
                                                                              this.handleDeleteChatClick(
                                                                                false,
                                                                                oneMessage.id
                                                                              );
                                                                            }}
                                                                          >
                                                                            Delete
                                                                            for
                                                                            me
                                                                          </Dropdown.Item>
                                                                        </Dropdown.Menu>
                                                                      </Dropdown>
                                                                    </div>
                                                                  </div>
                                                                </>
                                                              ) : this.getFileNameWithEx(
                                                                element?.attachments
                                                              ).ext ===
                                                                "ppt" ? (
                                                                <>
                                                                  <div className="d-flex justify-content-between mb-0 ">
                                                                    <p
                                                                      className="mb-1 message"
                                                                      key={
                                                                        index
                                                                      }
                                                                      data-toggle="tooltip"
                                                                      data-placement="right"
                                                                      title="Download attachment"
                                                                      style={{
                                                                        minWidth:
                                                                          "20rem",
                                                                      }}
                                                                    >
                                                                      <a
                                                                        className="text-center"
                                                                        href={
                                                                          element?.attachments
                                                                        }
                                                                        target="_blank"
                                                                        rel="noopener noreferrer"
                                                                        download
                                                                        key={
                                                                          index
                                                                        }
                                                                        data-toggle="tooltip"
                                                                        data-placement="right"
                                                                        title="Download attachment"
                                                                      >
                                                                        <svg
                                                                          color="#33ccff"
                                                                          xmlns="http://www.w3.org/2000/svg"
                                                                          width="38"
                                                                          height="38"
                                                                          fill="currentColor"
                                                                          className="bi bi-filetype-ppt"
                                                                          viewBox="0 0 16 16"
                                                                        >
                                                                          <path
                                                                            fillRule="evenodd"
                                                                            d="M14 4.5V14a2 2 0 0 1-2 2h-1v-1h1a1 1 0 0 0 1-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5L14 4.5ZM1.6 11.85H0v3.999h.791v-1.342h.803c.287 0 .531-.057.732-.173.203-.117.358-.275.463-.474a1.42 1.42 0 0 0 .161-.677c0-.25-.053-.476-.158-.677a1.176 1.176 0 0 0-.46-.477c-.2-.12-.443-.179-.732-.179Zm.545 1.333a.795.795 0 0 1-.085.38.574.574 0 0 1-.238.241.794.794 0 0 1-.375.082H.788V12.48h.66c.218 0 .389.06.512.181.123.122.185.296.185.522Zm2.817-1.333h-1.6v3.999h.791v-1.342h.803c.287 0 .531-.057.732-.173.203-.117.358-.275.463-.474.108-.201.161-.427.161-.677 0-.25-.052-.476-.158-.677a1.176 1.176 0 0 0-.46-.477c-.2-.12-.443-.179-.732-.179Zm.545 1.333a.795.795 0 0 1-.085.38.574.574 0 0 1-.238.241.793.793 0 0 1-.375.082H4.15V12.48h.66c.218 0 .389.06.512.181.123.122.185.296.185.522Zm2.767-.67v3.336H7.48v-3.337H6.346v-.662h3.065v.662H8.274Z"
                                                                          />
                                                                        </svg>
                                                                        <span className="p ms-2 mt-2">
                                                                          {this.getFileNameWithEx(
                                                                            element?.attachments
                                                                          )
                                                                            .files ||
                                                                            "-"}
                                                                        </span>
                                                                      </a>
                                                                    </p>
                                                                    <div className="dropdown-content1 text-center mt-2">
                                                                      <Dropdown className="dropdown float-center ms-4">
                                                                        <Dropdown.Toggle
                                                                          as="div"
                                                                          className="btn-link i-false p-1"
                                                                        >
                                                                          <svg
                                                                            width="20"
                                                                            height="20"
                                                                            viewBox="0 0 24 24"
                                                                            fill="none"
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                          >
                                                                            <circle
                                                                              cx="12.4999"
                                                                              cy="3.5"
                                                                              r="2.5"
                                                                              fill="#121212"
                                                                            />
                                                                            <circle
                                                                              cx="12.4999"
                                                                              cy="11.5"
                                                                              r="2.5"
                                                                              fill="#121212"
                                                                            />
                                                                            <circle
                                                                              cx="12.4999"
                                                                              cy="19.5"
                                                                              r="2.5"
                                                                              fill="#121212"
                                                                            />
                                                                          </svg>
                                                                        </Dropdown.Toggle>
                                                                        <Dropdown.Menu className="dropdown-menu dropdown-menu-right">
                                                                          <Dropdown.Item
                                                                            onClick={() => {
                                                                              this.handleDeleteChatClick(
                                                                                false,
                                                                                oneMessage.id
                                                                              );
                                                                            }}
                                                                          >
                                                                            Delete
                                                                            for
                                                                            me
                                                                          </Dropdown.Item>
                                                                        </Dropdown.Menu>
                                                                      </Dropdown>
                                                                    </div>
                                                                  </div>
                                                                </>
                                                              ) : this.getFileNameWithEx(
                                                                element?.attachments
                                                              ).ext ===
                                                                "mp4" ||
                                                                this.getFileNameWithEx(
                                                                  element?.attachments
                                                                ).ext ===
                                                                "webm" ||
                                                                this.getFileNameWithEx(
                                                                  element?.attachments
                                                                ).ext ===
                                                                "mov" ||
                                                                this.getFileNameWithEx(
                                                                  element?.attachments
                                                                ).ext ===
                                                                "avi" ||
                                                                this.getFileNameWithEx(
                                                                  element?.attachments
                                                                ).ext ===
                                                                "m4v" ||
                                                                this.getFileNameWithEx(
                                                                  element?.attachments
                                                                ).ext ===
                                                                "mp3" ||
                                                                this.getFileNameWithEx(
                                                                  element?.attachments
                                                                ).ext ===
                                                                "mkv" ||
                                                                this.getFileNameWithEx(
                                                                  element?.attachments
                                                                ).ext ===
                                                                "m4a" ||
                                                                this.getFileNameWithEx(
                                                                  element?.attachments
                                                                ).ext ===
                                                                "mpg" ? (
                                                                <>
                                                                  <div className="d-flex justify-content-between mb-0 ">
                                                                    <p
                                                                      className="mb-1 message"
                                                                      key={
                                                                        index
                                                                      }
                                                                      data-toggle="tooltip"
                                                                      data-placement="right"
                                                                      title="Download attachment"
                                                                      style={{
                                                                        minWidth:
                                                                          "20rem",
                                                                      }}
                                                                    >
                                                                      <a
                                                                        className="text-center"
                                                                        href={
                                                                          element?.attachments
                                                                        }
                                                                        target="_blank"
                                                                        rel="noopener noreferrer"
                                                                        download
                                                                        key={
                                                                          index
                                                                        }
                                                                        data-toggle="tooltip"
                                                                        data-placement="right"
                                                                        title="Download attachment"
                                                                      >
                                                                        <svg
                                                                          color="#96e60b"
                                                                          xmlns="http://www.w3.org/2000/svg"
                                                                          width="30"
                                                                          height="30"
                                                                          fill="currentColor"
                                                                          className="bi bi-file-play"
                                                                          viewBox="0 0 16 16"
                                                                        >
                                                                          <path d="M9.293 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.707A1 1 0 0 0 13.707 4L10 .293A1 1 0 0 0 9.293 0zM9.5 3.5v-2l3 3h-2a1 1 0 0 1-1-1zM6 6.883a.5.5 0 0 1 .757-.429l3.528 2.117a.5.5 0 0 1 0 .858l-3.528 2.117a.5.5 0 0 1-.757-.43V6.884z" />
                                                                        </svg>
                                                                        <span className="p ms-2 mt-2">
                                                                          {this.getFileNameWithEx(
                                                                            element?.attachments
                                                                          )
                                                                            .files ||
                                                                            "-"}
                                                                        </span>
                                                                      </a>
                                                                    </p>
                                                                    <div className="dropdown-content1 text-center mt-2">
                                                                      <Dropdown className="dropdown float-center ms-4">
                                                                        <Dropdown.Toggle
                                                                          as="div"
                                                                          className="btn-link i-false p-1"
                                                                        >
                                                                          <svg
                                                                            width="20"
                                                                            height="20"
                                                                            viewBox="0 0 24 24"
                                                                            fill="none"
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                          >
                                                                            <circle
                                                                              cx="12.4999"
                                                                              cy="3.5"
                                                                              r="2.5"
                                                                              fill="#121212"
                                                                            />
                                                                            <circle
                                                                              cx="12.4999"
                                                                              cy="11.5"
                                                                              r="2.5"
                                                                              fill="#121212"
                                                                            />
                                                                            <circle
                                                                              cx="12.4999"
                                                                              cy="19.5"
                                                                              r="2.5"
                                                                              fill="#121212"
                                                                            />
                                                                          </svg>
                                                                        </Dropdown.Toggle>
                                                                        <Dropdown.Menu className="dropdown-menu dropdown-menu-right">
                                                                          <Dropdown.Item
                                                                            onClick={() => {
                                                                              this.handleDeleteChatClick(
                                                                                false,
                                                                                oneMessage.id
                                                                              );
                                                                            }}
                                                                          >
                                                                            Delete
                                                                            for
                                                                            me
                                                                          </Dropdown.Item>
                                                                        </Dropdown.Menu>
                                                                      </Dropdown>
                                                                    </div>
                                                                  </div>
                                                                </>
                                                              ) : (
                                                                <>
                                                                  <div className="d-flex justify-content-between mb-0 ">
                                                                    <p
                                                                      className="mb-1 message"
                                                                      key={
                                                                        index
                                                                      }
                                                                      data-toggle="tooltip"
                                                                      data-placement="right"
                                                                      title="Download attachment"
                                                                      style={{
                                                                        minWidth:
                                                                          "20rem",
                                                                      }}
                                                                    >
                                                                      <a
                                                                        className="text-center py-1 "
                                                                        href={
                                                                          element?.attachments
                                                                        }
                                                                        target="_blank"
                                                                        rel="noopener noreferrer"
                                                                        download
                                                                        key={
                                                                          index
                                                                        }
                                                                      >
                                                                        <img
                                                                          src={
                                                                            element?.attachments
                                                                          }
                                                                          className="img-fluid img-thumbnail p-1 m-0"
                                                                          alt="attachments"
                                                                          title="attachments"

                                                                          style={{
                                                                            height:
                                                                              "50px",
                                                                            width:
                                                                              "50px",
                                                                          }}
                                                                        ></img>
                                                                      </a>
                                                                      <h5 className="p mt-2">
                                                                        {this.getFileNameWithEx(
                                                                          element?.attachments
                                                                        )
                                                                          ?.files ||
                                                                          "-"}
                                                                      </h5>
                                                                    </p>
                                                                    <div className="dropdown-content1 text-center mt-2">
                                                                      <Dropdown className="dropdown float-center ms-4">
                                                                        <Dropdown.Toggle
                                                                          as="div"
                                                                          className="btn-link i-false p-1"
                                                                        >
                                                                          <svg
                                                                            width="20"
                                                                            height="20"
                                                                            viewBox="0 0 24 24"
                                                                            fill="none"
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                          >
                                                                            <circle
                                                                              cx="12.4999"
                                                                              cy="3.5"
                                                                              r="2.5"
                                                                              fill="#121212"
                                                                            />
                                                                            <circle
                                                                              cx="12.4999"
                                                                              cy="11.5"
                                                                              r="2.5"
                                                                              fill="#121212"
                                                                            />
                                                                            <circle
                                                                              cx="12.4999"
                                                                              cy="19.5"
                                                                              r="2.5"
                                                                              fill="#121212"
                                                                            />
                                                                          </svg>
                                                                        </Dropdown.Toggle>
                                                                        <Dropdown.Menu className="dropdown-menu dropdown-menu-right">
                                                                          <Dropdown.Item
                                                                            onClick={() => {
                                                                              this.handleDeleteChatClick(
                                                                                false,
                                                                                oneMessage.id
                                                                              );
                                                                            }}
                                                                          >
                                                                            Delete
                                                                            for
                                                                            me
                                                                          </Dropdown.Item>
                                                                        </Dropdown.Menu>
                                                                      </Dropdown>
                                                                    </div>
                                                                  </div>
                                                                </>
                                                              )
                                                          )}
                                                        </div>
                                                      </>
                                                    )}
                                                </>
                                              </>
                                            ) : (
                                              ""
                                            )}
                                          </div>
                                        </>
                                      )}
                                  </div>
                                </>
                              )
                            )}
                          <div
                            style={{ height: "30px" }}
                            id="#tracker"
                            ref="trackerRef"
                          ></div>
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <LoadingSpinner data={{ padding: "20px", margin: "120px" }} />
                  </>
                )}
              </PerfectScrollbar>
            </div>
            {!this.state.isEdit ? (
              <>
                <Dropzone
                  onDrop={(acceptedFiles) =>
                    acceptedFiles.forEach((files) => {
                      let size = parseFloat(files.size / (1024 * 1024)).toFixed(
                        2
                      );
                      if (size > 30) {
                        this.setState({
                          ErrorMessage:
                            "Uploaded file too Big, please select a file less than 30mb",
                        });
                        setTimeout(() => {
                          this.setState({ ErrorMessage: "" });
                        }, 4000);
                      } else {
                        this.setState({ message: files?.name });
                        const data = sendAttachmentMessageAction(
                          this.userData?.id,
                          this.state?.textMessage,
                          JSON.parse(localStorage.getItem("ActiveChat"))?.id,
                          files,
                          this.token
                        );
                        data?.then(async (res) => {
                          WebSocketInstance.newChatMessage(res.data.data);
                          this.setState({ message: "" });
                        });
                        data?.catch(async (err) => { });
                      }
                    })
                  }
                >
                  {({ getRootProps, getInputProps }) => (
                    <div
                      className="chat mt-1 mx-1 me-2"
                      {...getRootProps({
                        onClick: (event) => event.stopPropagation(),
                      })}
                    >
                      <input {...getInputProps()} />
                      <div className="row d-flex justify-content-between">
                        <form
                          onSubmit={(e) => {
                            this.setState({
                              counter: 0,
                            });
                            this.sendMessageHandler(
                              e,
                              this.state.message,
                              JSON.parse(localStorage.getItem("ActiveChat"))
                                ?.thread_id
                            );
                          }}
                        >
                          <div className="d-flex justify-content-between m-1 me-2">
                            <input
                              className="figma-input p py-2.5 float-start m-1"
                              type="text"
                              onChange={this.messageChangeHandler}
                              value={this.state.message}
                              placeholder="Type a Message"
                              required
                            />

                            <span
                              className="border-0 pxfont-w500-3 me-1 float-end m-1 rounded"
                              style={{ cursor: "pointer" }}
                              data-toggle="tooltip"
                              data-placement="right"
                              title="Upload attachments"
                              onClick={() => this.fileRef.current.click()}
                            >
                              <i
                                className="bi bi-paperclip figma-link h2  float-end"
                                type="file"
                                id="img"
                                name="img"
                                accept="image/*"
                                style={{ color: "#62a5d1" }}
                              ></i>
                            </span>

                            <button
                              type="submit"
                              className="figma-btn float-end mx-2 my-1"
                            >
                              <i className="far fa-paper-plane me-1"></i>
                              <span>SEND</span>
                            </button>

                            <input
                              ref={this.fileRef}
                              onChange={(e) =>
                                this.sendAttachmentHandler(e.target.files)
                              }
                              multiple={true}
                              type="file"
                              accept="image/*,.doc,.csv,text/csv,.xlsx,.xls,.docx,.ppt, .pptx,.txt,.pdfvideo/mp4,video/x-m4v,video/*"
                              hidden
                            />
                          </div>
                          <Row>
                            <h5 className="text-red ms-3">
                              {this.state?.ErrorMessage}
                            </h5>
                          </Row>
                        </form>
                      </div>
                    </div>
                  )}
                </Dropzone>
              </>
            ) : (
              <div className="chat mt-1 mx-1 me-3">
                <div className="row">
                  <form
                    onSubmit={(e) => {
                      this.handleEditMessageClick(
                        e,
                        this.state.msgID,
                        this.state.message
                      );
                    }}
                  >
                    <div className="d-flex m-1 me-2">
                      <input
                        className="figma-input float-start m-1"
                        type="text"
                        onChange={this.messageChangeHandler}
                        value={this.state?.message}
                        placeholder="Type a Message"
                        required
                      />
                      <button
                        type="submit"
                        className="figma-btn text-white float-end col-sm-2 m-1 px-1"
                      >
                        <i className="far fa-paper-plane me-1"></i>Update
                      </button>
                      <button
                        type="submit"
                        className="figma-btn text-white float-end col-sm-2 m-1 p-2 bg-gray"
                        onClick={() => {
                          this.setState({ message: "", isEdit: false });
                        }}
                        style={{ backgroundColor: "gray" }}
                      >
                        <i className="fa fa-times me-1" aria-hidden="true"></i>
                        Cancel
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            )}
          </Col>
          <Col className="chat-slider">
            <div className="m-0">
              {this.state?.notification && this.state?.toggle && (
                <Sidebar
                  data={this?.state?.notification}
                  close={this?.setUpdate}
                  noti={false}
                />
              )}
            </div>
          </Col>
        </Row>
      </>
    );
  }
}

export default memo(Chat);
