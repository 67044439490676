import React, { useState, useEffect, useRef } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import {
  getOrgMembers,
  createTeamAction,
  getAllTeams,
  editTeamAction,
  deleteTeamAction,
  getSubTeamByTeamId,
} from "../../../store/actions/TeamAction";
import { useNavigate, useLocation, Link } from "react-router-dom";
import {
  Row,
  Col,
  Card,
  Tab,
  Nav,
  Modal,
  Dropdown,
  Button,
  Table,
} from "react-bootstrap";
import orgImg from "./../../../images/org1.png";
import Vector3 from "../../../images/vector/Vector3.png";
import LoadingSpinner from "../Component/LoadingSpinner";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";


const CustomClearText = () => "clear all";

const ClearIndicator = (props) => {
  const {
    children = <CustomClearText />,
    getStyles,
    innerProps: { ref, ...restInnerProps },
  } = props;
  return (
    <div
      {...restInnerProps}
      ref={ref}
      style={getStyles("clearIndicator", props)}
    >
      <div style={{ padding: "0px 5px" }}>{children}</div>
    </div>
  );
};

const ClearIndicatorStyles = (base, state) => ({
  ...base,
  cursor: "pointer",
  color: state.isFocused ? "blue" : "black",
});

const Teams = (props) => {
  let fileRef = useRef();
  const location = useLocation();
  const navigate = useNavigate();
  const tabData = [
    {
      name: "Team members",
      icon: "user",
    },
    {
      name: "Sub-team",
      icon: "user",
    },
  ];
  const [spinner, setSpinner] = useState(false);
  let fileRef1 = useRef(location?.state?.team);
  const userDetail = localStorage.getItem("userDetails");
  const filterUserListData = [];
  const selectedUserListData = [];
  const [teamList, getTeamDetails] = useState([]);

  console.log(teamList, "teamList")
  const stateData = useSelector((state) => state);
  const dispatch = useDispatch();
  const [userDetails, setUserDetails] = useState({});
  const orgId = localStorage.getItem("organization_id");
  const userInfoDetails = JSON.parse(userDetail);
  const [largeModal, setLargeModal] = useState(false);
  const [userIds, setUserIds] = useState("");
  const [userSelected, setSelectedUsers] = useState("");
  const [teamName, setTeamName] = useState("");
  const [teamId, setTeamId] = useState("");
  const [editTeamModal, setTeamEditModal] = useState(false);
  const [subteamList, getSubTeamDetails] = useState("");
  let errorsObj = { teamName: "", userList: "" };
  const [errors, setErrors] = useState(errorsObj);
  const [goalsdelete, setGoalsdelete] = useState(false);
  const [goalsupdate, setGoalsupdate] = useState(false);
  const [goalscreate, setGoalscreate] = useState(false);
  const [selectedTeam, setSelectedTeam] = useState();
  const [teamManager, setTeamManager] = useState({});
  const [OpenDeleteModal, SetOpenDeleteModal] = useState(false);
  const [SelectedTeamDelete, setSelectedTeamDelete] = useState();
  const [OpenWarningModal, setOpenWarningModal] = useState();
  const [selectedTeamForSearch, setSelectedTeamForSearch] = useState(
    location?.state?.Team
  );
  const [memberKey, setMemberKey] = useState(false)

  console.log(location?.state?.Team,"77777777777777777777777")

  stateData?.data?.forEach(team => {
    // Check if the userId matches the team_manager value
    team?.team_members.forEach(member => {
      console.log(member, "JJjjJJjJJJjJ")
        if (member.id === team.team_manager) {
            console.log(member.id, "JJjjJJjJJJjJ")
            member.editedKey = true;
        }
    });
});




  const changeHandler = (userSelected) => {
    setSelectedUsers(userSelected);
  };

  const handleCreateSubTeamClick = (event, team) => {
    setTeamId(team.id);
    team.team_members.forEach((member) => {
      const userDetailsValue = {
        name: member.first_name + " " + member.last_name,
        userID: member.id,
        label: member.first_name + " " + member.last_name,
        value: member.id,
      };
      selectedUserListData.push(userDetailsValue);
    });
    setUserDetails(selectedUserListData);
    setGoalsdelete(false);
    setGoalsupdate(false);
    setGoalscreate(false);
    setLargeModal(true);
  };

  const selectedTeamForDelete = (event, team) => {
    event.preventDefault();
    setSelectedTeamDelete(team);
  };

  useEffect(() => {
    setGoalsdelete(false);
    setGoalsupdate(false);
    setGoalscreate(false);
    setSelectedTeamForSearch(location?.state?.key);
  }, [location?.state?.key]);

  const handleDeleteTeamClick = () => {
    dispatch(
      deleteTeamAction(
        SelectedTeamDelete?.id,
        orgId,
        userInfoDetails.data.accessToken
      )
    );
    setGoalsdelete(false);
    setGoalsupdate(false);
    setGoalscreate(false);
  };

  const handleTeamEditClick = (event, team) => {
    setGoalsdelete(false);
    setGoalsupdate(false);
    setGoalscreate(false);
    event.preventDefault();
    setTeamId(team.id);
    setTeamName(team.name);
    team.team_members.forEach((member) => {
      const userDetailsValue = {
        name: member.first_name + " " + member.last_name,
        userID: member.id,
        label: member.first_name + " " + member.last_name,
        value: member.id,
      };
      if (team.team_manager === member.id) {
        setTeamManager({
          name: member.first_name + " " + member.last_name,
          userID: member.id,
          label: member.first_name + " " + member.last_name,
          value: member.id,
        });
      }
      selectedUserListData.push(userDetailsValue);
    });
    setSelectedUsers(selectedUserListData);
    errorsObj = { teamName: "", userList: "" };
    setErrors(errorsObj);
    setTeamEditModal(true);
  };

  useEffect(() => {
    setGoalscreate(false);
    setGoalsdelete(false);
    setGoalsupdate(false);
    if (
      localStorage.getItem("previousLocation") === "/stripe" &&
      sessionStorage.getItem("strategyImported") === "false"
    ) {
      toast.info(
        <div className="m-1">
          <h3>Payment Pending for Strategy </h3>
        </div>,
        { toastId: "strategyPaymentFail" }
      );
      sessionStorage.removeItem("strategyImported");
    }

    if (localStorage.getItem("organization_id") === null) {
      setOpenWarningModal(true);
    } else {
      getAlOrgMem();
      getAlTeams();
    }
  }, []);

  useEffect(() => {
    setGoalscreate(false);
    setGoalsdelete(false);
    setGoalsupdate(false);
    setErrors("");
  }, [teamName]);

  useEffect(() => {
    if (location?.state == "teams" && location?.pathname === "/teams") {
      setGoalsdelete(false);
      setGoalsupdate(false);
      setGoalscreate(false);
      setLargeModal(true);
    }
  }, [location]);

  

  useEffect(() => {
    if (stateData.team?.orgMembers?.message === "Organization Members") {
      stateData.team.orgMembers.data.forEach((element) => {
        const userDetailsValue = {
          name: element.first_name + " " + element.last_name,
          userID: element.id,
          label: element.first_name + " " + element.last_name,
          value: element.id,
        };
        filterUserListData.push(userDetailsValue);
      });
      setUserDetails(filterUserListData);
    }

    if (stateData?.team?.team?.message === "Teams found successfully") {
      getTeamDetails(stateData?.team?.team?.data);
      setSpinner(false);
      setGoalsdelete(false);
      setGoalsupdate(false);
      setGoalscreate(false);
    }
    if (stateData.team?.team?.message === "Team created successfully") {
      getTeamDetails(stateData?.team?.team?.data);
      setLargeModal(false);
      setGoalsdelete(false);
      setGoalsupdate(false);
      setGoalscreate(true);
      setTeamId("");
      getAlTeams();
      getAlOrgMem();
      setTeamName("");
      setUserIds("");
      errorsObj = { teamName: "", userList: "" };
    }

    if (stateData.team?.team?.message === "Team Data updated successfully") {
      getTeamDetails(stateData?.team?.team?.data);
      setTeamEditModal(false);
      setGoalsdelete(false);
      setGoalsupdate(true);
      setGoalscreate(false);
      getAlTeams();
      getAlOrgMem();
      setTeamName("");
      setUserIds("");
      errorsObj = { teamName: "", userList: "" };
    }
    if (stateData.team?.team?.message === "Team deleted successfully") {
      getTeamDetails(stateData?.team?.team?.data);
      getAlTeams();
      getAlOrgMem();
      setGoalsdelete(true);
      setGoalsupdate(false);
      setGoalscreate(false);
    }
    if (stateData.team?.team?.message === "Sub-Team found successfully") {
      getSubTeamDetails(stateData.team?.team?.data);
      setGoalsdelete(false);
      setGoalsupdate(false);
      setGoalscreate(false);
    }
  }, [stateData]);

  useEffect(() => {
    setGoalsdelete(false);
    setGoalsupdate(false);
    setGoalscreate(false);
    let userIds = [userInfoDetails?.data?.id];
    if (userSelected.length !== 0) {
      userSelected.forEach((element) => {
        userIds.push(element?.userID);
      });
    }
    setUserIds([...new Set(userIds)]);
  }, [userSelected]);

  function getNavItem(event, data, i, team) {
    if (data.name === "Sub-Team") {
      dispatch(getSubTeamByTeamId(team.id, userInfoDetails.data.accessToken));
    }
  }

  function getAlOrgMem() {
    dispatch(getOrgMembers(userInfoDetails.data.accessToken));
  }

  function getAlTeams() {
    setSpinner(true);
    dispatch(getAllTeams(orgId, userInfoDetails.data.accessToken));
  }

  function openCreateTeamModal() {
    errorsObj = { teamName: "", userList: "" };
    setErrors(errorsObj);
    if (localStorage.getItem("organization_id") === null) {
      setOpenWarningModal(true);
    } else {
      setLargeModal(true);
      setGoalsdelete(false);
      setGoalsupdate(false);
      setGoalscreate(false);
    }
  }

  function submitTeam(e) {
    setGoalsdelete(false);
    setGoalsupdate(false);
    setGoalscreate(false);
    e.preventDefault()
    let error = false;
    const errorObj = { ...errorsObj };
    if (teamName === "") {
      errorObj.teamName = "Team name is Required*";
      error = true;
    }
    if (userIds.length === 0) {
      errorObj.userList = "Please select atleast one user*";
      error = true;
    }
    setErrors(errorObj);
    if (error) return;
    dispatch(
      createTeamAction(
        teamName,
        orgId,
        userInfoDetails.data.id,
        userIds,
        teamId,
        userInfoDetails.data.accessToken
      )
    );
    // setLargeModal(false);
  }

  function submitEditTeam(e) {
    setGoalsdelete(false);
    setGoalsupdate(false);
    setGoalscreate(false);
    e.preventDefault();
    let error = false;
    const errorObj = { ...errorsObj };
    if (teamName === "") {
      errorObj.teamName = "Team name is Required*";
      error = true;
    }
    if (userIds.length === 0) {
      errorObj.userList = "Please select atleast one user*";
      error = true;
    }
    console.log(teamName.length, "55555555TeamNameLength")
    if(teamName.length> 100 ){
      errorObj.teamName = "Team name must not be greater than 100 characters"
    }
    setErrors(errorObj);
    if (error) return;
    dispatch(
      editTeamAction(
        teamName,
        orgId,
        userInfoDetails.data.id,
        "",
        userIds,
        teamId,
        userInfoDetails.data.accessToken
      )
    );
  }

  const handleTeamAttachmentSubmit = (event) => {
    event.preventDefault();
    selectedTeam?.team_members.forEach((member) => {
      const userDetailsValue = {
        name: member.first_name + " " + member.last_name,
        userID: member.id,
        label: member.first_name + " " + member.last_name,
        value: member.id,
      };
      if (selectedTeam?.team_manager === member.id) {
        setTeamManager({
          name: member.first_name + " " + member.last_name,
          userID: member.id,
          label: member.first_name + " " + member.last_name,
          value: member.id,
        });
      }
      selectedUserListData.push(userDetailsValue);
    });
    setSelectedUsers(selectedUserListData);
    setTimeout(() => {
      dispatch(
        editTeamAction(
          selectedTeam?.name,
          orgId,
          "",
          event.target.files[0],
          userIds,
          selectedTeam?.id,
          userInfoDetails.data.accessToken
        )
      );
      getAlTeams();
    }, 1000);
  };
  const close = () => {
    setGoalsdelete(false);
    setGoalsupdate(false);
    setGoalscreate(false);
  };
  const toastSuccess = () => {
    toast(
      <>
        <div className="m-1 d-flex">
          <i
            class="bi bi-check-circle fs-26 me-3"
            style={{ color: "#131F4C" }}
          ></i>
          <div>
            <h2
              style={{
                fontSize: "22px",
              }}
            >
              Completed
            </h2>
            {goalsdelete ? (
              <h4>Your team has been deleted successfully!</h4>
            ) : goalsupdate ? (
              <h4>Your team has been updated successfully!</h4>
            ) : goalscreate ? (
              <h4>Your team has been created successfully!</h4>
            ) : (
              ""
            )}
          </div>
        </div>
      </>,
      {
        toastId: "teams",
        className: "toast-success-container toast-success-container-after ",
      }
    );
  };

  return (
    <>
      {goalscreate && toastSuccess()}
      {goalsdelete && toastSuccess()}
      {goalsupdate && toastSuccess()}

      <Modal
        show={OpenWarningModal}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        top
        data-testid="modal1"
        style={{ borderRadius: "0rem" }}
      >
        <Modal.Title className="my-3 mx-4" id="contained-modal-title-vcenter">
          <img title="Team-warning" alt="Team-warning" className="float-start me-1 mt-1 p-2" src={Vector3} />
          <h4 className="fs-20 p-2">Warning!</h4>
        </Modal.Title>
        <Modal.Body className="modal-body mx-4 p-0">
          <p className="p-2 pt-0" style={{ color: "#717994" }}>
            Please create organization or select default organization from
            header before creating a team
            <Link className="figma-link mt-2 me-3" to="/organization-list">
              &nbsp;here...
            </Link>
          </p>
        </Modal.Body>
        <div className="d-flex  justify-content-end mx-4 mb-3">
          <button
            className="figma-btn text-white"
            onClick={() => {
              setOpenWarningModal(false);
            }}
          >
            Ok
          </button>
        </div>
      </Modal>

      {/* Delete modal */}
      <Modal
        data-testid="modal2"
        show={OpenDeleteModal}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        style={{ borderRadius: "0rem" }}
      >
        <Modal.Title className="my-3 mx-4" id="contained-modal-title-vcenter">
          <img className="float-start me-1 mt-1 p-2" title="Delete-modal-img" alt="Delete-modal-img" src={Vector3} />
          <h4 className="fs-20 p-2">Are you sure you want to delete Team? </h4>
        </Modal.Title>
        <Modal.Body className="modal-body mx-4 p-0">
          <p className="p-2 pt-0" style={{ color: "#717994" }}>
            You will not be able to recover this Team!
          </p>
        </Modal.Body>
        <div className="d-flex  justify-content-end mx-4 mb-3">
          <Link
            className="figma-link mt-2 me-3"
            to=""
            onClick={(e) => {
              e.preventDefault();
              SetOpenDeleteModal(false);
            }}
          >
            No, cancel it!
          </Link>
          <button
            className="figma-btn text-white"
            onClick={() => {
              {
                handleDeleteTeamClick();
                SetOpenDeleteModal(false);
              }
            }}
          >
            Yes, I am Sure!
          </button>
        </div>
      </Modal>

      <Modal
        className="fade bd-example-modal-lg"
        show={largeModal}
        size="lg"
        data-testid="modal3"
      >
        <Modal.Body>
          <button
            variant=""
            className="btn-close btn-danger float-end align-center m-1 p-2"
            onClick={() => {
              setLargeModal(false);
              setErrors("");
            }}
          ></button>
          <h2 className="text-center mb-4">Create team </h2>
          <form onSubmit={(e) => submitTeam(e)}>
        <Row>
          <Col md={6} className="mb-3">
            <input
              type="text"
              className="form-control"
              style={{height:"37px"}}
              placeholder="Team name"
              data-testid="team-name"
              onChange={(e) => setTeamName(e.target.value)}
            />
            {errors.teamName && (
              <div className="text-danger fs-12 ms-2">{errors.teamName}</div>
            )}
          </Col>
          <Col md={6}>
            <Select
              placeholder="Add team members"
              closeMenuOnSelect={false}
              components={{ ClearIndicator }}
              styles={{ clearIndicator: ClearIndicatorStyles }}
              defaultValue={""}
              onChange={changeHandler}
              data-testid="team-users"
              isMulti
              options={userDetails}
            />
            {errors.userList && (
              <div className="text-danger fs-12">{errors.userList}</div>
            )}
            <h4 className="mt-2 float-end custom-h4">
            Create users
            <Link className="text-danger" to={"/invite-users"}>
              {" "}
              <u>here</u>
            </Link>
          </h4>
          </Col>
        </Row>

          <div>
            <div className="text-center">
              <button
                type="submit"
                className="figma-btn text-white mt-3"
                style={{ alignSelf: "flex-end" }}
              >
                Create team
              </button>
            </div>
          </div>
        </form>
        </Modal.Body>
      </Modal>

      <Modal
        className="fade bd-example-modal-lg"
        show={editTeamModal}
        size="lg"
        data-testid="modal4"
      >
        <Modal.Body>
          <button
            variant=""
            className="btn-close btn-danger float-end align-center m-1 p-2"
            onClick={() => setTeamEditModal(false)}
          ></button>
          <h2 className="text-center mb-4">Edit team </h2>
          <form onSubmit={(e) => submitEditTeam(e)}>
          <div className="row">
            <div className="col-md-6 mb-3">
              <input
                type="text"
                className="figma-input p"
                placeholder="Enter team name"
                defaultValue={teamName}
                data-testid="team-name-edit"
                onChange={(e) => setTeamName(e.target.value)}
                style={{ height: "36px" }}
              />
              {errors.teamName && (
                <div className="text-danger fs-12 ms-2">{errors.teamName}</div>
              )}
            </div>

            <div className="col-md-6 form-group">
              <Select
                closeMenuOnSelect={false}
                components={{ ClearIndicator }}
                styles={{ clearIndicator: ClearIndicatorStyles }}
                defaultValue={userSelected}
                onChange={changeHandler}
                isMulti
                data-testid="team-users-edit"
                options={userDetails}
              />
              {errors.userList && (
                <div className="text-danger fs-12">{errors.userList}</div>
              )}
            </div>
          </div>

          <div className="text-center mt-4">
            <button type="submit" className="figma-btn text-white">
              Save
            </button>
          </div>
        </form>

        </Modal.Body>
      </Modal>

      <Row>
        <Col xl={6}>
          {/* {localStorage.getItem("previousLocation") !==
            localStorage.getItem("currentLocation") && (
              <span className="float-start me-3 mt-2">
                <i
                  className="fa fa-reply fs-20"
                  title={`Navigate back to ${localStorage.getItem("previousLocation").split("/")[1]
                    }`}
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    navigate(localStorage.getItem("previousLocation"));
                  }}
                />
              </span>
            )} */}
          <h1>Teams</h1>
        </Col>

        <Col xl={6}>
          <button
            className="figma-btn text-white"
            data-testid="open-create-team"
            style={{ float: "right" }}
            onClick={(e) => openCreateTeamModal()}
          >
            <span className="mt-3">+ &nbsp; Create team</span>
          </button>
        </Col>
      </Row>
      <Row xl={12}>
        <h4>
          Before you create a team, make sure to add all the users you need. You
          can add more users{" "}
          <Link className="figma-link " to={"/invite-users"}>
            <u>here</u>
          </Link>
        </h4>
      </Row>
      {spinner ? (
        <center>
          <div style={{ padding: "20px", margin: "300px" }}>
            <LoadingSpinner />
          </div>
        </center>
      ) : (
        <>
          <Row data-testid="team-row">
            {teamList ? (
              teamList.map((team, index) => (
                <>
                  <Col xl={6} label="test-column" data-testid="team-col">
                    <Card
                      className="contact-bx"
                      data-testid="team-card"
                      style={{
                        border: team?.id === selectedTeamForSearch ? "1px solid red" : "1px solid #F3F4F6",
                        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)"
                      }}
                    >
                      <Card.Header
                        data-testid="team-header"
                        className="header-bg"
                        style={{ position: "unset", borderRadius: "0rem" }}
                      >
                        <div
                          className="profile-photo me-2"
                          style={{ display: "inline-flex" }}
                        >
                          <img
                            src={team?.team_image || orgImg}
                            title="Organization Image"
                            alt="Organization Image"
                            className="rounded-circle"
                            style={{ height: "50px", width: "50px" }}
                            onClick={() => {
                              setSelectedTeam(team);
                              fileRef.current.click();
                            }}
                          />
                          <p className="my-1">
                            <Row>
                              <span
                                class="fas fa-pen"
                                onClick={(e) => {
                                  setSelectedTeam(team);
                                  fileRef.current.click();
                                }}
                                style={{
                                  float: "left",
                                  width: "15px",
                                  cursor: "pointer",
                                  borderRadius: "20px",
                                  fontSize: "7px",
                                  padding: "3px",
                                  color: "white",
                                  backgroundColor: "#356FD4",
                                }}
                                type="file"
                                id="edit"
                                name="img"
                                accept="image/*"
                              ></span>
                            </Row>
                          </p>
                          <input
                            ref={fileRef}
                            onChange={(e) => {
                              handleTeamAttachmentSubmit(e);
                            }}
                            multiple={false}
                            type="file"
                            accept="image/*"
                            hidden
                          />
                        </div>
                        <Card.Title data-testid="team-title">
                        <OverlayTrigger
                  delay={{ hide: 100, show: 10 }}
                  overlay={(props) => (
                    <Tooltip {...props}>
                      <h5 className="p text-white">
                      
                        {team?.name}
                      </h5>
                    </Tooltip>
                  )}
                  placement="top"
                >
                          <h3>{team?.name?.length > 10 && window.screen.width<=768 ? `${team?.name.substring(0, 10)}...` : `${team?.name.substring(0, 15)}...`}</h3>
                        </OverlayTrigger>  
                        
                        </Card.Title>

                       {team?.team_manager === userInfoDetails?.data?.id  &&   <Dropdown
                          className="ms-auto"
                          data-testid="team-dropdown"
                        >
                          <Dropdown.Toggle
                            variant=""
                            as="div"
                            className="btn-link i-false"
                          >
                            <svg
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <circle
                                cx="12.4999"
                                cy="3.5"
                                r="2.5"
                                fill="#131F4C"
                              />
                              <circle
                                cx="12.4999"
                                cy="11.5"
                                r="2.5"
                                fill="#131F4C"
                              />
                              <circle
                                cx="12.4999"
                                cy="19.5"
                                r="2.5"
                                fill="#131F4C"
                              />
                            </svg>
                          </Dropdown.Toggle>
                          <Dropdown.Menu
                            data-testid="team-menu"
                            className="dropdown-menu-right"
                          >
                            <Dropdown.Item
                              data-testid="open-team-edit"
                              onClick={(event) =>
                                handleTeamEditClick(event, team)
                              }
                            >
                              Edit
                            </Dropdown.Item>
                            <Dropdown.Item
                              data-testid="create-sub-team"
                              onClick={(event) =>
                                handleCreateSubTeamClick(event, team)
                              }
                            >
                              Create sub-team
                            </Dropdown.Item>
                            <Dropdown.Item
                              className="text-danger"
                              data-testid="delete-team"
                              onClick={(event) => {
                                selectedTeamForDelete(event, team);
                                SetOpenDeleteModal(true);
                              }}
                            >
                              Delete
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>}
                      </Card.Header>
                      <Card.Body>
                        <div className="default-tab">
                          <Tab.Container
                            defaultActiveKey={tabData[0].name.toLowerCase()}
                          >
                            <Nav as="ul" className="nav-tabs">
                              {tabData.map((data, i) => (
                                <Nav.Item
                                  onClick={(e) =>
                                    getNavItem(e, data, index, team)
                                  }
                                  as="li"
                                  key={i}
                                >
                                  <Nav.Link eventKey={data.name.toLowerCase()}>
                                    <i className={`la la-${data.icon} me-2`} />
                                    <span className="fs-16">{data.name}</span>
                                  </Nav.Link>
                                </Nav.Item>
                              ))}
                            </Nav>
                            <Tab.Content className="pt-4">
                              {tabData.map((data, i) => (
                                <Tab.Pane
                                  eventKey={data.name.toLowerCase()}
                                  key={i}
                                >
                                  <>
                                    {data.name === "Team members" ? (
                                      <div>
                                        <Col lg={12}>
                                          <Table
                                            responsive
                                            className="display w-100 dataTable "
                                            id="example5"
                                            role="grid"
                                            aria-describedby="example5_info"
                                          >
                                            <thead>
                                              <tr>
                                                <th>Name</th>
                                                <th>Email</th>
                                                <th>Timezone</th>
                                                <th>Daily working hours</th>
                                                <th>Weekly working hours</th>
                                                <th>Working days</th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              {team.team_members.map(
                                                (member, i) => (
                                                  <tr>
                                                    <th>
                                                      {member.first_name +
                                                        " " +
                                                        member.last_name}
                                                    </th>
                                                    <td>{member.email}</td>
                                                    <td>{member.timezone}</td>
                                                    <td>
                                                      {
                                                        member.daily_working_hours
                                                      }
                                                    </td>
                                                    <td>
                                                      {
                                                        member.weekly_working_hours
                                                      }
                                                    </td>
                                                    <td>
                                                      {member.working_days}
                                                    </td>
                                                  </tr>
                                                )
                                              )}
                                            </tbody>
                                          </Table>
                                        </Col>
                                        {/* ))} */}
                                      </div>
                                    ) : (
                                      <>
                                        <div className="">
                                        {team?.team_manager === userInfoDetails?.data?.id  &&    <Button
                                            data-testid="create-sub-team"
                                            onClick={(event) =>
                                              handleCreateSubTeamClick(
                                                event,
                                                team
                                              )
                                            }
                                            className="figma-btn text-white create-sub-team-button"
                                          >
                                            Create sub-team
                                          </Button>}
                                          {team?.sub_team_present && (
                                            <Link
                                              to="/subTeams"
                                              state={{ team: team }}
                                              className="figma-btn text-white float-right show-sub-team-button"
                                              style={{
                                                float: "right",
                                                padding: "0.5rem 0.45rem",
                                              }}
                                            >
                                              <span className="">
                                                Show sub-teams
                                              </span>
                                            </Link>
                                          )}
                                        </div>
                                      </>
                                    )}
                                  </>
                                </Tab.Pane>
                              ))}
                            </Tab.Content>
                          </Tab.Container>
                        </div>
                      </Card.Body>
                    </Card>
                  </Col>
                </>
              ))
            ) : (
              <></>
            )}
          </Row>
        </>
      )}
    </>
  );
};

export default Teams;
