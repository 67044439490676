import React, { useEffect, useState } from "react";
import { Row, Col, Card } from "react-bootstrap";
import PlannedObjectives from "./PlannedObjectives";
import PlannedTasks from "./PlannedTasks";
import { getMyDay } from "../../../services/OrganizationService";
import PlanMessages from "./PlanMessages";
import PlannedTeams from "./PlannedTeams";
import PlannedStatistics from "./PlannedStatistics";
import star from "../../../images/vector/Vector.jpg";
import { useNavigate } from "react-router-dom";
import { Box, Grid, Typography, Avatar, Button, Menu, MenuItem, IconButton } from "@mui/material";
import { getQuickWinsData } from "../../../services/OrganizationService";
import { set } from "date-fns";
import UmaAvtar from '../../../images/profile/Favicon.png';
import { taskFeedback } from "../../../services/TaskService";
import { toast } from "react-toastify";

import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates';
import CloseIcon from '@mui/icons-material/Close';
// import Stack from '@mui/material/Stack';
import { Stack } from '@mui/material';
import { BsEmojiLaughingFill } from "react-icons/bs";
import { BsEmojiSmileFill } from "react-icons/bs";
import { BsEmojiNeutralFill } from "react-icons/bs";
import { FaCheckCircle } from "react-icons/fa";
import { CustomScrollbars } from "../Component/customScrollbarComponent";
import BarChartObjective from "../Chartjs/BarChartObjective";
import Streaks from "../Chartjs/streak";
import MyDayBarChartObjective from "../Chartjs/MyDayBarChartObjective";


export default function Plan({ route, teamId }) {
  const userDetail = localStorage.getItem("userDetails");
  const userInfoDetails = JSON.parse(userDetail);
  const [dayDetails, setDayDetails] = useState(null);
  const [quikWinsData, setQuickWinsData] = useState([])
  const [showingTask, setShowingTask] = useState(true)

  // const [timeScope, setTimeScope] = useState(localStorage.getItem("timescope"))
  // const [trackScope, setTrackScope] = useState(localStorage.getItem("scope"))
  // const [teamIds, setTeamIds] = useState(localStorage.getItem("team_id"))
  // const [teamName, setTeamName] = useState(localStorage.getItem("team_name"))

  const navigate = useNavigate()
  const [anchorEl, setAnchorEl] = useState(null);

  // Handle right-click (or normal click) to open the dropdown
  const handleClick = (event) => {
    event.preventDefault(); // Prevent default right-click menu if needed
    setAnchorEl(event.currentTarget);
  };

  const handleDoneButton = () => {
    getQuickWinsData()
    setShowingTask(false)
  }


  const handleClick1 = (event) => {
    setAnchorEl(event.currentTarget); // Set the clicked element as the anchor
  };




  // Close the dropdown
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClose1 = () => {
    setAnchorEl(null);
  };



  console.log(localStorage.getItem("team_name"), "teamName")
  // useEffect(()=>{
  //   setTrackScope(localStorage.getItem("scope"))
  // },[localStorage.getItem("scope") ])

  // useEffect(()=>{
  //   console.log(teamIds, "teamIdsteamIds")
  //   setTeamIds(localStorage.getItem("team_id"))
  //   console.log(teamIds, "teamIdsteamIds")
  // },[localStorage.getItem("team_id")])


  // useEffect(()=>{
  // setTeamName(localStorage.getItem("team_name"))
  // },[localStorage.getItem("team_name")])



  console.log("objectiveListobjectiveList", dayDetails?.image_link == [])
  console.log("objectiveListobjectiveList", dayDetails?.task_list)

  useEffect(() => {
    getMyPanDetails(localStorage.getItem("scope"), localStorage.getItem("team_id"));
  }, []);

  const getMyPanDetails = async (scope, teamId) => {
    try {
      const data = await getMyDay(userInfoDetails?.data?.accessToken, teamId);
      setDayDetails(data?.data?.data);
    } catch (err) {
      console.error("Error fetching data", err);
    }
  };

  function getfeedbackTask(task, selectedTaskStatus) {
    const data = taskFeedback(task?.id, userInfoDetails?.data?.accessToken, true, selectedTaskStatus, task?.task_effort);
    data?.then(async (res) => {
      setAnchorEl(null);
      getQuickWinsDatas()
      setShowingTask(true)
      toast(
        <>
          <div className="m-1 d-flex">
            <i
              class="bi bi-check-circle fs-26 me-3"
              style={{ color: "#131F4C" }}
            ></i>
            <div>
              <h2
                style={{
                  // color: "#131F4C",
                  fontSize: "22px",
                }}
              >
                Completed
              </h2>
              <h3
                style={{
                  // color: "#131F4C",
                  fontSize: "18px",
                }}
              >
                Your task has been mark as complete successfully!
              </h3>
            </div>
          </div>
        </>,
        {
          toastId: "task-schedule",
          className: "toast-success-container toast-success-container-after ",
        }
      );

    })
    data?.catch(async (err) => {
      toast(
        <>
          <div className="m-1 d-flex">
            <i className="bi bi-check-circle fs-26 me-3" style={{ color: "#131F4C" }}></i>
            <div>
              <h2 style={{ fontSize: "22px" }}>Failed</h2>
              <h3 style={{ fontSize: "18px" }}>
                {err?.response?.data?.message}
              </h3>
            </div>
          </div>
        </>,
        {
          toastId: "task-schedule",
          className: "toast-error-container toast-error-container-after",
        }
      );
    })


  }

  const getQuickWinsDatas = () => {

    const data = getQuickWinsData(userInfoDetails?.data?.accessToken);
    data.then(async (res) => {
      setQuickWinsData(res?.data?.data)
      console.log(res?.data?.data, "9999999999999999999QuickDta")
    });
    data.catch(async (err) => {
      // setSubShowTableSpinner(false);
    });
  };

  useEffect(() => {
    getQuickWinsDatas()
  }, [])



  useEffect(() => {

    getMyPanDetails(localStorage.getItem("scope"), localStorage.getItem("team_id"))
  }, [localStorage.getItem("timescope"), localStorage.getItem("scope"), localStorage.getItem("team_id")])

  // useEffect(()=>{
  //   setTimeScope(localStorage.getItem("timescope"))
  //   console.log("useEfffff", timeScope,localStorage.getItem("timescope"))
  //  },[localStorage.getItem("timescope")])

  //  const handleTeamChange = (team) => {
  //     console.log('eeeeeeeee', team);
  //     getMyPanDetails('team',team?.id);
  //     setTeamIds(team?.id)
  //     // setTrackScope("team")
  //     setTeamName(team?.name)
  //  }


  // function formatResponse(response) {
  //   response = response.replace(/###### (.*$)/gim, '<h6>$1</h6>');
  //   response = response.replace(/##### (.*$)/gim, '<h5>$1</h5>');
  //   response = response.replace(/#### (.*$)/gim, '<h4>$1</h4>');
  //   response = response.replace(/### (.*$)/gim, '<h3>$1</h3>');
  //   response = response.replace(/## (.*$)/gim, '<h2>$1</h2>');
  //   response = response.replace(/# (.*$)/gim, '<h1>$1</h1>');

  //   let formattedText = response.replace(/```([\s\S]*?)```/g, '<pre><code>$1</code></pre>');
  //   formattedText = formattedText.replace(/`([^`]+)`/g, '<code>$1</code>');
  //   formattedText = formattedText.replace(/^> (.*)$/gm, '<blockquote>$1</blockquote>');

  //   formattedText = formattedText.replace(/(?:\d+\..*\n?)+/g, function (match) {
  //     let items = match.trim().split(/\n(?=\d+\.\s)/);
  //     let listItems = items.map(item => {
  //       let number = item.match(/^\d+/)[0];
  //       let content = item.replace(/^\d+\.\s*/, '');
  //       return `<li><b>${number}</b>. ${content}</li>`;
  //     }).join('');
  //     return `<ol>${listItems}</ol>`;
  //   });

  //   formattedText = formattedText.replace(/(?:- .*\n?)+/g, function (match) {
  //     let items = match.trim().split(/\n(?=-\s)/);
  //     let listItems = items.map(item => `<li>${item.replace(/^- /, '')}</li>`).join('');
  //     return `<ul>${listItems}</ul>`;
  //   });

  //   formattedText = formattedText.replace(/\[(.*?)\]\((.*?)\)/g, '<a href="$2">$1</a>');
  //   formattedText = formattedText.replace(/\*\*(.*?)\*\*/g, '<b>$1</b>');
  //   formattedText = formattedText.replace(/\*(.*?)\*/g, '<i>$1</i>');
  //   formattedText = formattedText.replace(/~~(.*?)~~/g, '<s>$1</s>');
  //   formattedText = formattedText.replace(/\n/g, '<br>');
  //   formattedText = formattedText.replace(/(<br>\s*)+/g, '<br>');

  //   return formattedText;
  // }

  function formatResponse(response) {
    // Replace headers
    response = response?.replace(/### (.*$)/gim, '<h3>$1</h3>');

    // Replace bold text
    response = response?.replace(/\*\*(.*?)\*\*/g, '<b>$1</b>');

    // Replace inline code
    response = response?.replace(/`([^`]+)`/g, '<code>$1</code>');

    // Replace ordered lists
    response = response?.replace(/(?:\d+\..*\n?)+/g, function (match) {
      let items = match?.trim().split(/\n(?=\d+\.\s)/); // Split based on numbers
      let listItems = items?.map(item => {
        let number = item?.match(/^\d+/)[0]; // Extract number
        let content = item?.replace(/^\d+\.\s*/, ''); // Remove number
        return `<li><b>${number}</b>. ${content}</li>`;
      }).join('');
      return `<ol>${listItems}</ol>`;
    });

    // Replace line breaks with <br>
    response = response?.replace(/\n/g, '<br>');

    return response;
  }




  return (
    <>

      {/* {
        trackScope === "team"  && <Row className="">
          <Col>
          <Card>
            <div  className="p-4" style={{display:"flex",  alignItems: "center"}}>
            <h2>Team's Day</h2>  -
            <h2 style={{fontWeight:"300",  wordBreak: 'break-word'}}>{teamName}</h2>
              </div>
          </Card>
          </Col>
      </Row>} */}
      {
        dayDetails && (dayDetails?.image_link.length != 0) ? <>
          <Card>
            <Card.Body>
              <div className="container-fluid" style={{ height: '100%' }}>
                <div className="row " style={{ height: '100%' }}>
                  {quikWinsData.length > 0 ? (
                    quikWinsData.map((win, index) => (<div className="col-12 col-md-8 " key={index} style={{ padding: "5px" }}>


                      <Box sx={{ flexGrow: 1, padding: 0 }}>
                        <Grid container spacing={0} >
                          {/* Left side (Avatar) */}
                          <Grid item xs={12} sm={12} md={4} lg={2} sx={{ display: 'flex',transform: 'translateY(-38px)' }}>
                            <Avatar
                              alt="Avatar Image"
                              src={UmaAvtar}
                              sx={{ width: 130, height: 130 }} // Adjust size of the Avatar
                            />
                          </Grid>

                          {/* Right side (Text) */}
                          <Grid item xs={12} sm={12} md={8} lg={10}>
                            <Typography variant="h6" display="flex" justifyContent="center" gutterBottom
                              sx={{ fontFamily: "Open Sans" }}
                            >
                              {win?.ai_message}
                            </Typography>

                          </Grid>
                        </Grid>
                        {showingTask && win?.task?.name && (    <Typography variant="h4" display="flex" justifyContent="center" color="rgb(38, 153, 109)">
                          QUICK WIN FOR TODAY!
                        </Typography>)}
                      </Box>




                      {showingTask && win?.task?.name && (<Box mt={2} display="flex" >
                        <Grid display="flex" alignItems="center" marginRight="4px" >
                          {/* Icon that triggers the menu */}
                          <TipsAndUpdatesIcon

                            fontSize="large"
                            onClick={handleClick1}
                            style={{ cursor: 'pointer', color: "#F7C326" }}
                          />

                          {/* The Menu component */}
                          <Menu
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            onClose={handleClose1}

                            sx={{
                              '& .MuiPaper-root': {
                                // minHeight: '200px',
                                height: '350px',
                                minWidth: '200px',
                                maxWidth: '320px',
                                padding: '10px',
                                position: 'relative',
                              },
                            }}
                            anchorOrigin={{
                              vertical: 'bottom',
                              horizontal: 'left',
                            }}
                            transformOrigin={{
                              vertical: 'top',
                              horizontal: 'left',
                            }}
                          >
                            {/* Close button and heading */}
                            <Box sx={{
                              display: 'flex',
                              flexDirection: 'column', // Stack elements vertically
                              alignItems: 'flex-end',  // Align to the right
                              position: 'absolute',
                              top: 0,
                              right: 0,
                            }}>
                              {/* Heading above the close button */}

                              {/* Close button */}
                              <IconButton onClick={handleClose1}>
                                <CloseIcon />
                              </IconButton>
                              <CustomScrollbars height={"30vh"}>
                                <Typography variant="p" sx={{ marginTop: '2px', padding: '5px' }}>
                                  <div
                                    dangerouslySetInnerHTML={{ __html: formatResponse(win?.tips) }}
                                  />
                                </Typography>
                              </CustomScrollbars>
                            </Box>

                            {/* Menu items */}
                            <Typography variant="h5" sx={{ paddingLeft: "4px" }}>Tips</Typography>


                          </Menu>
                        </Grid>









                        <Grid padding={2} border="1px solid rgb(38, 153, 109)" borderRadius="50px" width="100%"  >

                          <Grid container spacing={2} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <Grid item xs={12} sm={12} md={8} lg={10}>
                              <Typography variant="h6" sx={{ paddingLeft: 4, paddingRight: 4, textWrap: 'wrap', fontFamily: 'Open Sans' }}>
                                {win?.task?.name}
                              </Typography>
                            </Grid>
                            {/* <Grid item xs={12} sm={12} md={4} lg={2}>
                          <Typography  sx={{ paddingLeft: 4, paddingRight: 4 }}>
                       
                          <Button style={{ background: "#d4ebe2" }}> Done</Button>
                          </Typography>
                          </Grid> */}

                            <Grid item xs={12} sm={12} md={4} lg={2}>
                              <Typography sx={{ paddingLeft: 4, paddingRight: 4 }}>
                              <Button
    style={{ backgroundColor: '#356FD4',  }}  // Use any shade of blue here
    onClick={handleDoneButton}
>
    <FaCheckCircle size={17} style={{ marginRight: '5px', color: 'white' }} /><span style={{color:"white"}}> Done</span>
</Button>


                                {/* The Dropdown Menu */}
                                {/* <Menu
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={handleClose}
    >
      <MenuItem onClick={() => getfeedbackTask(win?.task, "beat")}>Epik</MenuItem>
      <MenuItem onClick={() => getfeedbackTask(win?.task, "hit")}>Ok</MenuItem>
      <MenuItem onClick={() => getfeedbackTask(win?.task, "miss")}>Miss</MenuItem>
    </Menu> */}
                              </Typography>
                            </Grid>


                          </Grid>
                        </Grid>
                      </Box>)}

                      {!showingTask && (
                        <Box mt={2}     >

                          <Grid padding={1} border="1px solid rgb(38, 153, 109)" borderRadius="100px" width="100%"  >
                            <Typography variant="h5" sx={{ display: 'flex', justifyContent: 'center', paddingLeft: "25px" }}>How was your task outcome?</Typography>
                            <Typography sx={{ display: 'flex', justifyContent: 'center' }}>
                              <Stack direction="row" spacing={2} sx={{ paddingTop: '13px' }} >
                                {/* First Menu Item */}
                                <Box>

                                  <Typography variant="body2" align="center"><BsEmojiLaughingFill color="#26996d"
                                    size={25}></BsEmojiLaughingFill></Typography> {/* Emoji below Item 1 */}
                                  <MenuItem onClick={() => getfeedbackTask(win?.task, "beat")}>Epic</MenuItem>
                                </Box>

                                {/* Second Menu Item */}
                                <Box>

                                  <Typography variant="body2" align="center"><BsEmojiSmileFill color="#e5a139" size={25}></BsEmojiSmileFill></Typography>
                                  <MenuItem onClick={() => getfeedbackTask(win?.task, "hit")}>Ok</MenuItem>
                                </Box>

                                {/* Third Menu Item */}
                                <Box>

                                  <Typography variant="body2" align="center"><BsEmojiNeutralFill color="#F8B1B1"
                                    size={25}></BsEmojiNeutralFill> </Typography>
                                  <MenuItem onClick={() => getfeedbackTask(win?.task, "miss")}>Miss</MenuItem>
                                </Box>
                              </Stack>

                            </Typography>

                          </Grid>
                        </Box>)}


                    </div>))) : (
                    <Typography variant="body1" sx={{ paddingLeft: 4 }}>
                      No Quick Wins available.
                    </Typography>
                  )}
                  <div className="col-12 col-md-4">
                    <img
                      src={dayDetails?.image_link}
                      className="img-fluid"
                      alt="Responsive Image"
                      style={{ padding: '5px', borderRadius: '10px', width: '100%', height: '100%' }}
                    />
                  </div>
                </div>
              </div>
            </Card.Body>
          </Card>
        </> : <></>
      }

      <Row>
        {/* <Col xl={8}> */}
        <Card>
          <Card.Body>
            {/* {dayDetails &&  */}
            <PlannedTasks taskList={dayDetails?.task_list} taskTitle={dayDetails?.task_title} />
            {/* } */}
          </Card.Body>
        </Card>
        {/* </Col>
        <Col xl={4}> */}
        {/* <Card>
            <Card.Body>
              <PlanMessages messages={dayDetails?.message_list} />
            </Card.Body>
          </Card> */}
        {/* </Col> */}
      </Row>
      <Row>
        {/* <Col xl={6}> */}
        {/* <Card>
            <Card.Body>
             <PlannedObjectives objectiveList={dayDetails?.objective_list}/>
            </Card.Body>
            <Card.Footer style={{border:"none"}}>
              <p style={{float:"right", paddingRight:"10px"}}><span  onClick={handleClick} class="badge" style={{backgroundColor: '#356FD4'}}>View all</span></p>
            </Card.Footer>
          </Card> */}
        {/* </Col> */}
        {/* <Col xl={3}> */}
        <PlannedObjectives objectiveList={dayDetails?.objective_list} />
        <Streaks />
        <MyDayBarChartObjective />
        {/* </Col> */}
        {/* <Col xl={3}> */}
        {/* <PlannedStatistics /> */}
        {/* </Col> */}
      </Row>
      {/* {
        trackScope != "team"  && <Row>
        <Col xl={4}>
          <Card>
            <Card.Body>
              <PlannedTeams setTeam={handleTeamChange}/>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      } */}

    </>
  );
}