import React, { useState, useEffect } from "react";
import Select from "react-select";
import { Col, Dropdown, Modal, Row ,Form} from "react-bootstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Link } from "react-router-dom";
import profilePicture from "../../../images/profile/profilePic.png";
import { getOrgMem } from "../../../services/TeamService";
import {
  AddnewMemberTOThread,
  DeleteMemberFromThread,
} from "../../../services/messageService";
import { toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
import { Alert } from "react-bootstrap";
import { confirmationToAddMemberInThread } from "../../../services/messageService";



export const CustomClearText = () => {
  "clear all";
};

function Threadmember(props) {
  const userDetails = localStorage.getItem("userDetails");
  const userInfoDetails = JSON.parse(userDetails);
  const [activeTab, setActiveTab] = useState("tab1");
  const [user, setUser] = useState();
  const [usersSelected, setUserSelected] = useState();
  const [selectedUsers, setSelectedUsers] = useState();
  const [addMember, setaddMember] = useState(props?.addMember);
  const [selectedUser, setSelectedUser] = useState(props?.user);
  const [data, setData] = useState()

  console.log("HHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHH",selectedUser)
  

  console.log("usersSelected",selectedUsers)


  useEffect(() => {
    setSelectedUser(props?.user);
    handleAddRoomMember();
  }, []);

  useEffect(() => {
    setSelectedUser(props?.user);
    handleAddRoomMember();
  }, [props?.user]);

  useEffect(() => {
    setSelectedUser(selectedUser);
  }, [JSON.parse(localStorage.getItem("ActiveChat"))?.thread_id]);


  const HandleNoClick = (item, e) =>{
    console.log("selectedUser",selectedUsers )
    e.preventDefault()
    console.log("itemm", item);
   
      const updatedSelectedUsers = selectedUsers?.filter((element) => element?.userId !== item?.id);
      setSelectedUsers(updatedSelectedUsers)
      setUserSelected(updatedSelectedUsers)
     
      console.log("updatedSelectedUsers",updatedSelectedUsers)


      let usersid = [];
      console.log("selectedUsersDDDDDDDDDDDDD", selectedUsers)
      updatedSelectedUsers?.forEach((element) => {
        if (element.userId !== selectedUser?.user?.initiator?.id)
          usersid.push(element?.userId);
      });
      const data = confirmationToAddMemberInThread(
        selectedUser?.user?.thread_id,
        [...new Set(usersid)],
        userInfoDetails?.data?.accessToken
      );
      data?.then(async (res) => {
        setData(res?.data?.data)
        console.log(res?.data?.data,"UUUUUUUUUUUUUUUUUU")
    
      });
      data?.catch(async (err) => {
        console.log("error user org list");
      });
     
       
     
    
     

   
  }

  const handleAddRoomMember = () => {
    let prevMembers = [];
    prevMembers.push(selectedUser?.user?.initiator?.id);
    selectedUser?.user?.receiver?.forEach((element) => {
      prevMembers.push(element?.id);
    });
    let unique = prevMembers.filter((item, i, ar) => ar.indexOf(item) === i);
    prevMembers = unique;
    const data = getOrgMem(userInfoDetails?.data?.accessToken);
    data?.then(async (res) => {
      if (res.data.message === "Organization Members") {
        let optionData = res?.data?.data;
        let optionList = [];
        let allOptionList = [];
        let usersSelected = [];
        optionData?.forEach((option) => {
          if (prevMembers.includes(option?.id)) {
            const userDetailsValue = {
              name: option.first_name
                ? option.first_name + " " + option.last_name
                : option?.email,
              userId: option.id,
              label: option.first_name
                ? option.first_name + " " + option.last_name
                : option?.email,
              value: option.id,
            };
            usersSelected.push(userDetailsValue);
          } else {
            const data = {
              label: option.first_name
                ? option.first_name + " " + option.last_name
                : option?.email,
              value: option.first_name
                ? option.first_name + " " + option.last_name
                : option?.email,
              userId: option.id,
            };
            optionList.push(data);
          }
          if (option.id != selectedUser?.user?.initiator?.id) {
            const datas = {
              label: option.first_name
                ? option.first_name + " " + option.last_name
                : option?.email,
              value: option.id,
              userId: option.id,
            };
            allOptionList.push(datas);
          }
        });
        setUser(optionList);
        setUserSelected(usersSelected);
        setSelectedUsers(usersSelected);
      }
    });
    data?.catch(async (err) => {
      console.log("error user org list");
    });
  };

  const changeHandler = (userSelected) => {
    setSelectedUsers(userSelected);
    // AddNewGroupMembers()
    console.log("ksdnfksnk", userSelected)
    let usersid = [];
    userSelected?.forEach((element) => {
      if (element.userId !== selectedUser?.user?.initiator?.id)
        usersid.push(element.userId);
    });
    const data = confirmationToAddMemberInThread(
      selectedUser.user.thread_id,
      [...new Set(usersid)],
      userInfoDetails.data.accessToken
    );
    data?.then(async (res) => {
      setData(res?.data?.data)
      console.log(res?.data?.data,"UUUUUUUUUUUUUUUUUU")
      
    });
    data?.catch(async (err) => {
      console.log("error user org list");
    });
   
  };

  const AddNewGroupMembers = () => {
    // e.preventDefault();
    let usersid = [];
    console.log("selectedUsersDDDDDDDDDDDDD", selectedUsers)
    selectedUsers?.forEach((element) => {
      if (element.userId !== selectedUser?.user?.initiator?.id)
        usersid.push(element.userId);
    });
    const data = confirmationToAddMemberInThread(
      selectedUser.user.thread_id,
      [...new Set(usersid)],
      userInfoDetails.data.accessToken
    );
    data?.then(async (res) => {
      setData(res?.data?.data)
      console.log(res?.data?.data,"UUUUUUUUUUUUUUUUUU")
      
    });
    data?.catch(async (err) => {
      console.log("error user org list");
    });
  };


  const AddNewGroupMembersInThread = ( e) => {
    e.preventDefault();
    let usersid = [];
    selectedUsers?.forEach((element) => {
      if (element.userId !== selectedUser?.user?.initiator?.id)
        usersid.push(element.userId);
    });
    const data = AddnewMemberTOThread(
      selectedUser.user.thread_id,
        [...new Set(usersid)],
      userInfoDetails.data.accessToken
    );
    data?.then(async (res) => {
      toast(
        <>
          <div className="m-1 d-flex">
            <i
              class="bi bi-check-circle fs-26 me-3"
              style={{ color: "#131F4C" }}
            ></i>
            <div>
              <h2
                style={{
                  fontSize: "22px",
                }}
              >
                Completed
              </h2>
              <h3
                style={{
                  fontSize: "18px",
                }}
              >
                Thread members has been updated successfully!
              </h3>
            </div>
          </div>
        </>,
        {
          toastId: "thread-member-update",
          className: "toast-success-container toast-success-container-after ",
        }
      );
     
      setSelectedUser(props?.user);
    setUserSelected(usersSelected)
      // handleAddRoomMember();
      AddNewGroupMembers(e)

      selectedUser.handler("member");
      setTimeout(() => {
        handleTab1();
      
        setSelectedUser(props?.user);
      }, 1000);

     
    });
    data?.catch(async (err) => {
      console.log("error user org list");
    });
  };


  const handleTab1 = () => {
    // handleAddRoomMember();
    setActiveTab("tab1");
    setSelectedUser(props?.user);
    setUserSelected(usersSelected)
    setData([])
  };

  const handleTab2 = () => {
    handleAddRoomMember()
    setActiveTab("tab2");
    setSelectedUser(props?.user);
    setUserSelected(usersSelected)
  };

  const ClearIndicator = (props) => {
    const {
      children = <CustomClearText />,
      getStyles,
      innerProps: { ref, ...restInnerProps },
    } = props;
    return (
      <div
        {...restInnerProps}
        ref={ref}
        style={getStyles("clearIndicator", props)}
      >
        <div style={{ padding: "0px 5px" }}>{children}</div>
      </div>
    );
  };

  const ClearIndicatorStyles = (base, state) => ({
    ...base,
    cursor: "pointer",
    color: state.isFocused ? "blue" : "black",
  });

  const DeleteGroupMembers = (e, member_id) => {
    e.preventDefault();
    const data = DeleteMemberFromThread(
      selectedUser.user.thread_id,
      [member_id],
      userInfoDetails.data.accessToken
    );
    data?.then(async (res) => {
      console.log("error user org list", res);
      toast(
        <>
          <div className="m-1 d-flex">
            <i
              class="bi bi-check-circle fs-26 me-3"
              style={{ color: "#131F4C" }}
            ></i>
            <div>
              <h2
                style={{
                  fontSize: "22px",
                }}
              >
                Completed
              </h2>
              <h3
                style={{
                  fontSize: "18px",
                }}
              >
                Thread members has been deleted successfully!
              </h3>
            </div>
          </div>
        </>,
        {
          toastId: "thread-member-delete",
          className: "toast-success-container toast-success-container-after ",
        }
      );
      selectedUser.handler("member");
      setSelectedUser(props?.user)
      // handleAddRoomMember();
      // setSelectedUsers(selectedUsers)
      // setTimeout(() => {
      //   handleTab1();
      // }, 1000);
    });
    data?.catch(async (err) => {
      console.log("error user org list", err);
      if (err.response.data?.code === 400) {
        toast(
          <>
            <div className="mx-1 d-flex">
              <i
                class="bi bi-check-circle fs-26 me-3"
                style={{ color: "#131F4C" }}
              ></i>
              <div>
                <h2
                  style={{
                    fontSize: "22px",
                  }}
                >
                  Failed
                </h2>
                <h3
                  style={{
                    fontSize: "18px",
                  }}
                >
                  {err.response.data?.message}
                </h3>
              </div>
            </div>
          </>,
          {
            toastId: "thread-data",
            className: "toast-error-container toast-error-container-after ",
          }
        );
      }
    });
  };

  return (
    <Modal className="fade bd-example-modal-lg" show={addMember} size="lg">
      <Modal.Body style={{ maxHeight: "30%" }}>
        <div className="text-center mb-4">
          <button
            variant=""
            className="btn-close btn-danger float-end align-center m-2 p-2"
            onClick={() => {
              setaddMember(false);
              props.setAdd(false);
            }}
          ></button>
          <h2 className="text-center" >Add new member</h2>
        </div>

        <Row className="mx-1" >
          <Col className="col-sm-12 ">
            <ul className="nav3 nav-tabs">
              <li
                className={activeTab === "tab1" ? "active" : ""}
                onClick={handleTab1}
              >
                <span className="h4">Group members</span>
              </li>
              {selectedUser?.user?.initiator?.id ===
                userInfoDetails?.data?.id && (
                <>
                  <li
                    className={activeTab === "tab2" ? "active" : ""}
                    onClick={handleTab2}
                  >
                    <span className="h4">Add members</span>
                  </li>
                </>
              )}
            </ul>
          </Col>
        </Row>

        {activeTab === "tab1" ? (
          <>
            <div style={{ maxHeight: "400px" }} className="m-2">
              <PerfectScrollbar
                className="dlab-scroll"
                id="chartBox"
              >
                <div
                  className="media mt-2 justify-content-end align-items-end"
                  key={-1}
                >
                  <div
                    className="message-sentUsers dropdown2 me-3"
                    style={
                      selectedUser?.user?.initiator?.id ===
                      userInfoDetails?.data?.id
                        ? { cursor: "auto" }
                        : { cursor: "pointer" }
                    }
                  >
                    <div className="d-flex justify-content-between align-center">
                      <div className="chat-img mt-3 ms-3">
                        <img
                          src={
                            selectedUser?.user?.initiator?.profile_picture ===
                            null
                              ? profilePicture
                              : selectedUser?.user?.initiator?.profile_picture
                          }
                          alt="profile-photo"
                          title="profile-photo"
                          style={{ height: "50px", width: "50px" }}
                        />
                      </div>
                      <p className="mb-1 message" key={-1}>
                        <h4>
                          {selectedUser?.user?.initiator?.first_name +
                            " " +
                            selectedUser?.user?.initiator?.last_name}
                        </h4>
                        <h5>{selectedUser?.user?.initiator?.email}</h5>
                      </p>
                    </div>
                  </div>
                </div>
                {selectedUser?.user?.receiver &&
                  selectedUser?.user?.receiver.map((member, index) => (
                    <div
                      className="media mt-2 justify-content-end align-items-end"
                      key={index}
                    >
                      {member?.id !== userInfoDetails?.data?.id ? (
                        <div className="message-sentUsers dropdown2 me-3">
                          <div
                            className="d-flex justify-content-end align-center"
                            style={
                              selectedUser?.user?.initiator?.id ===
                              userInfoDetails?.data?.id
                                ? { cursor: "pointer" }
                                : { cursor: "arrow" }
                            }
                          >
                            <div className="chat-img mt-3 ms-3">
                              <img
                                src={
                                  member?.profile_picture === null
                                    ? profilePicture
                                    : member?.profile_picture
                                }
                                alt="chat-img"
                                title="chat-img"
                                style={{ height: "50px", width: "50px" }}
                              />
                            </div>
                            <p className="mb-1 message" key={index}>
                              <h4>
                                {member?.first_name + " " + member?.last_name}
                              </h4>
                              <h5>{member?.email}</h5>
                            </p>
                            {selectedUser?.user?.initiator?.id ===
                              userInfoDetails?.data?.id && (
                              <div class="dropdown-content1 text-center mt-2 ">
                                <Dropdown className="dropdown1 float-center mt-3 me-3">
                                  <Dropdown.Toggle
                                    as="div"
                                    className="btn-link i-false p-1"
                                  >
                                    <svg
                                      width="20"
                                      height="20"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <circle
                                        cx="12.4999"
                                        cy="3.5"
                                        r="2.5"
                                        fill="#000000"
                                      />
                                      <circle
                                        cx="12.4999"
                                        cy="11.5"
                                        r="2.5"
                                        fill="#000000"
                                      />
                                      <circle
                                        cx="12.4999"
                                        cy="19.5"
                                        r="2.5"
                                        fill="#000000"
                                      />
                                    </svg>
                                  </Dropdown.Toggle>
                                  <Dropdown.Menu className="dropdown-menu dropdown-menu-right">
                                    <Dropdown.Item
                                      onClick={(e) => {
                                        DeleteGroupMembers(e, member?.id);
                                      }}
                                      data-testid="remove-members"
                                    >
                                      Remove user
                                    </Dropdown.Item>
                                  </Dropdown.Menu>
                                </Dropdown>
                              </div>
                            )}
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  ))}
              </PerfectScrollbar>
            </div>
          </>
        ) : (
          <>
            <Row className="mx-1">
            <form className="add-member" onSubmit={(e) => AddNewGroupMembersInThread( e)}>
              <Row className="align-items-center justify-content-center my-2">
                <Col xs={12} className="mb-3">
                  <label className="h5">Select members</label>
                  <Select
                    placeholder="Add new members"
                    closeMenuOnSelect={false}
                    components={ClearIndicator}
                    styles={{ clearIndicator: ClearIndicatorStyles }}
                    onChange={changeHandler}
                    defaultValue={usersSelected}
                    value={selectedUsers}
                    isMulti
                    className="p add-member-select"
                    options={user}
                  />
                </Col>
                  <div className="text-center d-block">
                    <span className="d-block mb-3 float-end">
                      Create users &nbsp;
                      <Link className="figma-link" to={"/invite-users"}>
                        <u>here...</u>
                      </Link>
                    </span>
                   </div>
                   <Col>
                   <PerfectScrollbar
                className="dlab-scroll"
                id="chartBox"
                style={{ height: "25vh" }}
              >
                <div>
                   
                   {data?.map((item) => (<div  className="alert alert-dark fs-16" role="alert">
    <div class="row align-items-center">
        <div class="col">
            User {item?.first_name +" "+ item?.last_name} do not have access to goal
            <div>Click NO to remove user from member list</div>
        </div>
        <div class="col-auto">
            <button type="button" className="btn mx-2  fs-12" style={{borderColor:"#FC2E53"}} onClick={(e) => HandleNoClick(item,e)}>No</button> <span className="mr-4"></span>
            {/* <button type="button" className="btn fs-12" style={{borderColor:"#09BD3C"}} onClick={(e) => AddNewGroupMembersInThread(item, e)} >Yes</button> */}
        </div>
    </div>
</div>

 ))}
 </div>
  </PerfectScrollbar>
                   </Col>
                   <div className="text-center mt-4"> 
                    <button type="submit" className="figma-btn add-member-save">
                      Save
                    </button>
                  </div>
              </Row>
            </form>
          </Row>
          </>
        )}
      </Modal.Body>
    </Modal>
  );
}

export default Threadmember;
