import React from "react";
import ReactApexChart from "react-apexcharts";
import { Row } from "react-bootstrap";

class Donut extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      height:330,
      width:330,
      series: props?.series,
      lable: props?.lable,
      options: {
        chart: {
          type: "donut",
          // height: 100,
          // width: 100,
        },
        labels: props?.lable,
        plotOptions: {
          radialBar: {
            donut: {
              size: "80%",
            },
            startAngle: -0,
            endAngle: 360,
            track: {
              background: "var(--rgba-primary-1)",
              strokeWidth: 100,
              margin: 1,
            },
          },
        },
        dataLabels: {
          enabled: false,
          name: {
            show: true,
          },
          value: {
            offsetY: 1,
            fontSize: "22px",
            color: "var(--primary)",
            fontWeight: 100,
            show: false,
          },
        },
        grid: {
          padding: {
            top: 0,
          },
        },
        fill: {
          type: "gradient",
        },
        legend: {
          formatter: function (val, opts) {
            return (
              props?.lable[opts?.seriesIndex] + " %"
            );
          },
        },
        responsive: [
          {
            breakpoint: 0,
            options: {
              chart: {
                type: "donut",
                // width: 100,
                // height: 100,
              },
              legend: {
                position: "bottom",
                show: false,
              },
            },
          },
        ],
      },
    };
  }

  componentDidMount() {
    this.setState({
      series: this.state?.series,
      lable: this.state?.lable,
    });
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps?.lable[0] !== this.state?.lable[0] &&
      (this.state?.lable[0] !== "Hit-undefined" ||
        this.state?.lable[0] !== undefined) &&
      this.state?.lable[0] !== "Hit-undefined"
    ) {
      const labels = this.state?.lable.map((item) => item);
      this.setState((prevState) => ({
        lable: labels,
        options: {
          ...this.state?.options,
          labels: labels,
          legend: {
            formatter: function (val, opts) {
              return labels[opts?.seriesIndex] + " %";
            },
          },
        },
      }));
    }
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    this.setState({
      series: newProps?.series,
      lable: newProps?.lable,
    });
  }
  render() {
    const isMobile = window.matchMedia('(max-width: 573px)')?.matches;
    const isTablet = window.matchMedia('(min-width: 574px) and (max-width: 1024px)')?.matches;
    const isDesktop = window.matchMedia('(min-width: 1025px) and (max-width: 1600px)')?.matches;
    const isDesktop1 = window.matchMedia('(min-width: 1601px) and (max-width: 2000px)')?.matches;
    const isDesktop2 = window.matchMedia('(min-width: 2001px) and (max-width: 2300px)')?.matches;
    const isDesktop3 = window.matchMedia('(min-width: 2301px)and (max-width: 2500px)')?.matches;
    const isDesktop4 = window.matchMedia('(min-width: 2501px)and (max-width: 3000px)')?.matches;
    const isDesktop5 = window.matchMedia('(min-width: 3001px)and (max-width: 3800px)')?.matches;
    const options = this.state?.options;
    const allZeros = this.state?.series.every((value) => value === 0);
    return (
      <div id="chart" className=" row h-100 w-100 p-0 m-0">
        {allZeros ? (
          <>
          <div className="w-100">
        <Row className="m-0 p-0">
          <div className="text-center">
              <span className="h5 d-flex p-0 m-0" data-testid="hit">
                <i class="bi bi-circle-fill me-1" style={{ color: "#8bdec0" }}></i>{" "}
                Hit - &nbsp;&nbsp; <p className="mx-2 fs-16">{this.state?.series[0]}%</p>
              </span>
              <span className="h5 d-flex p-0 m-0" data-testid="beat">
                <i class="bi bi-circle-fill me-1" style={{ color: "#efc078" }}></i>{" "}
                Beat - <p className="mx-2 fs-16">{this.state?.series[0]}%</p>
              </span>
              <span className="h5 d-flex p-0 m-0" data-testid="miss">
                <i class="bi bi-circle-fill me-1" style={{ color: "#ec798e" }}></i>{" "}
                Miss - <p className="mx-2 fs-16">{this.state?.series[0]}%</p>
              </span>
              </div>
        </Row>

        </div>
        {/* < ReactApexChart
        id="donutchart"
          options={options}
          series={this.state.series}
          type="donut"
          backgroundColor="#946767"
          position='absolute'
          height={isMobile? 230: isTablet ? 235:isDesktop?240:isDesktop1?270:isDesktop2?310:isDesktop3?325:isDesktop4?345:isDesktop5?355:350 }
          width={isMobile? 230: isTablet ? 235:isDesktop?240:isDesktop1?270:isDesktop2?310:isDesktop3?325 :isDesktop4?345:isDesktop5?355:350}
        /> */}
          </>
        
      ) : (
        <ReactApexChart
        id="donutchart"
        options={options}
        series={this.state?.series}
        type="donut"
        backgroundColor="#946767"
        height={isMobile ? 235 : isTablet ? 235 : isDesktop ? 235 : isDesktop1 ? 350 : isDesktop2 ? 310 : isDesktop3 ? 325 : isDesktop4 ? 345 : isDesktop5 ? 355 : 350}
        width={isMobile ? 235 : isTablet ? 235 : isDesktop ? 235 : isDesktop1 ? 350 : isDesktop2 ? 310 : isDesktop3 ? 325 : isDesktop4 ? 345 : isDesktop5 ? 355 : 350}
        style={{ marginLeft: isMobile ? '0px' : '0' }} // Set margin-left for mobile view
        
      />
      )}
     
    </div>
    );
  }
}

export default Donut;
