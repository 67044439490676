import { act } from "react-test-renderer";
import { READ_NOTIFICATION_CONFIRMED_ACTION } from "../actions/NotificationAction";
import { FILE_UPLOAD_CONFIRMED_ACTION } from "../actions/NotificationAction";
import { GET_THREADS_CONFIRMED_ACTION } from "../actions/NotificationAction";
import { GET_THREADS_FAILED_ACTION } from "../actions/NotificationAction";
import { GET_STRATEGY_IMPORTED_ACTION } from "../actions/NotificationAction"
const initialState = {
    notificationId:"",
    FileUploadId:"",
    threadList: {},
    errorMessage : "",
    strategyImported : ''
};

export function ReadNotificationReducer(state = initialState, action) {
    if (action.type === READ_NOTIFICATION_CONFIRMED_ACTION) {
        return {
            ...state,
            notificationId:action.payload,
        };
    }
    return state;
}

export function FileUploadReducer(state = initialState, action) {
    if (action.type === FILE_UPLOAD_CONFIRMED_ACTION) {
        return {
            ...state,
            FileUploadId:action.payload,
        };
    }
    return state;
}
export function MessagesReducer(state = initialState, action ){
    if (action.type === GET_THREADS_CONFIRMED_ACTION) {
        return {
            ...state,
            threadList : action.payload,
        };
    }
    if(action.type === GET_THREADS_FAILED_ACTION) {
        return {
            ...state,
            errorMessage : 'Thread list not found',
        };
    }
    if(action.type ===  GET_STRATEGY_IMPORTED_ACTION) {
        return {
            ...state,
            strategyImported : action.payload
        };
    }
    return state;
}