import axios from "axios";
import config from "./config";
const baseURL = config.BASE_URL;
const userDetails = localStorage.getItem("userDetails");
const userInfoDetails = JSON.parse(userDetails);
const org_id = parseInt(localStorage.getItem("organization_id"));
const time_scope = localStorage.getItem("timescope");

export function getSubscriptionPlan(token) {
  return axios.get(baseURL + `billing/get-plan-list`, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
}

export function makeSubscriptionPlanPayment(plan_type, user_quantity,org_id,token) {
  const data = {
    organization_id: org_id,
    plan_type: plan_type,
    currency: "usd",
    new_user_quantity: parseInt(user_quantity),
  };
  return axios.post(baseURL + `billing/make-payment/subscription`, data, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
}

export function getTransactionHistory(start, stop,time_scope, token) {
  const data = {
    start_date: "",
    time_scope: time_scope,
    end_date: "",
    start_index: start,
    end_index: stop
  };
  return axios.post(baseURL + `billing/transaction/list`, data, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
}


export function getCurrentSubscription(token) {
  return axios.get(baseURL + `billing/get_subsciption`, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
}


export function getPlanExpireNotification() {
  const data = {
    start_date: "",
    time_scope: time_scope,
    end_date: "",
  };
  return axios.post(baseURL + `billing/subscription/notification`, data, {
    headers: {
      Authorization: "Bearer " + userInfoDetails?.data?.accessToken,
    },
  });
}



export function getInvoice(invoice_id, token) {
  return axios.get(baseURL + `billing/transaction/receipt/${invoice_id}`, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
}