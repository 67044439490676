import React, { useEffect, useState } from 'react';
import TaskEdit from './TaskEdit';
import { useLocation, useNavigate } from 'react-router-dom';
import TaskModal from '../Component/CreateTaskModal';
import Select from 'react-select';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { toast } from 'react-toastify';
import { taskFeedback } from '../../../services/TaskService';
import { getMyDay } from '../../../services/OrganizationService';
import { Dropdown } from 'react-bootstrap';
import GoalModal from '../Component/GoalModal';
import { getGoalByScope } from '../../../store/actions/GoalsActions';
import { useDispatch } from 'react-redux';

import { useFormik } from "formik";
import * as Yup from 'yup';
import { createTaskService } from '../../../services/TaskService';
import { getAllGoalsByScope } from '../../../services/GoalService';
import { useSelector } from 'react-redux';
import { getOrgMembers } from '../../../store/actions/TeamAction';
import { getTeamMemByTeamId } from '../../../store/actions/TeamAction';
import { taskShowingFilter } from '../../../store/actions/TaskActions';
import { Link } from 'react-router-dom';
// import { Button } from '@mui/material';
import { FaPlus } from "react-icons/fa6";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import "bootstrap/dist/css/bootstrap.css";
import Tooltip from "react-bootstrap/Tooltip";
import Button from '@mui/joy/Button';
import ButtonGroup from '@mui/joy/ButtonGroup';
import CircularRadioButtons from '../Component/CircularRadioButton';
import { IoIosCheckmark } from "react-icons/io";
import { VscCircleLargeFilled } from "react-icons/vsc";
import { FaCircleCheck } from "react-icons/fa6";
import { Stack } from '@mui/material';
import ReactDOM from "react-dom";
import ShareTaskModal from '../Component/ShareTaskModal';
import { Modal } from 'react-bootstrap';
import { deleteTask } from '../../../services/TaskService';
import Vector3 from '../../../images/vector/Vector3.png'

const PlannedTasks = React.memo(({ taskList, taskTitle }) => {
  const location = useLocation()
  const [tasks, setTasks] = useState(taskList || []);
  const [taskDetails, setTaskDetails] = useState();
  const [showTaskMoadal, setshowTaskMoadal] = useState(false);
  const [showObjectiveModal, setShowObjectiveModal] = useState(false)
  const userDetail = localStorage.getItem("userDetails");
  const userInfoDetails = userDetail ? JSON.parse(userDetail) : null;
  const [CreateTask, setCreateTask] = useState(false);
  const dispatch = useDispatch();

  const [selectedShareTask, setSelectedShareTask] = useState()
  const [shareModal, setShareModal] = useState(false)
  const [OpenDeleteModal, SetOpenDeleteModal] = useState(false);
  const [SelectedTaskDelete, setSelectedTaskDelete] = useState();
  const [isTaskUpdated, setIsTaskUpdated] = useState(false);
  const [isTaskCompleted, setIsTaskCompleted] = useState(false);


  const [goalAlignId, setGoalAlign] = useState();
  const [defaultOptions, setDefaultOptions] = useState();
  const orgId = localStorage.getItem("organization_id");
  const [goalOption, setGoalOption] = useState([]);
  // const [scope, setScope ]= useState(localStorage.getItem("scope"));
  const [options, setOptions] = useState([]);
  // const startDate = localStorage.getItem("startDate");
  // const endDate = localStorage.getItem("endDate");
  // const timeScope = localStorage.getItem("timescope");
  // const teamId = localStorage.getItem("team_id");
  const stateData = useSelector((state) => state);
  const [tasksdelete, setTasksdelete] = useState(false);

  const [markAsStatus, setMarkAsStatus] = useState('')

  const [hitChecked, setHitChecked] = useState(false)

  const [UpdateTaskRefresh, setUpdateTaskRefresh] = useState(false)
  const [filterScheduleTaskIcon, setFilterScheduleTaskIcon] = useState(true);
  const [ScheduledTaskInputFieldOpen, setScheduledTaskInputFieldOpen] =
    useState(false);
  const [ScheduledTaskNameFilter, setScheduledTaskNameFilter] = useState();
  const [ScheduledTaskEffortFieldOpen, setScheduledTaskEffortFieldOpen] =
    useState();
  const [ScheduledTaskEffortFilter, setScheduledTaskEffortFilter] = useState();
  const [ScheduledTaskEffortFilterForShow, setScheduledTaskEffortFilterForShow] = useState();
  const [
    ScheduledTasksGoalInputFieldOpen,
    setScheduledTasksGoalInputFieldOpen,
  ] = useState(false);

  const [ScheduledTasksGoalNameFilter, setScheduledTasksGoalNameFilter] =
    useState();
  const [selectedScheduledTaskImpact, setselectedScheduledTaskImpact] =
    useState();





  console.log(goalOption, "pppppppppppppppppppppppppppp")


  // const [selectedGoal, setSelectedGoal] = useState("");
  console.log(showObjectiveModal, "showObjectiveModalshowObjectiveModal")


  const navigate = useNavigate();


  const [validationMessages, setValidationMessages] = useState('');
  const [taskStatus, setTaskStatusValue] = useState({ value: "", label: "" });


  const [taskStatuses, setTaskStatuses] = useState({});


  // const statusOption = [
  //   { value: "Hit", name: "Hit" },
  //   { value: "Miss", name: "Miss" },
  //   { value: "Beat", name: "Beat" },
  // ];

  const statusOption = [
    { value: "beat", label: "Epic" },
    { value: "hit", label: "OK" },
    { value: "miss", label: "Miss" },
  ]

  const TaskImpact = [
    {
      name: "Low",
      value: "Low",
    },
    {
      name: "Medium",
      value: "Medium",
    },
    {
      name: "High",
      value: "High",
    },
  ];

  const tasktype = [
    {
      name: "Game changers",
      value: "Game changers",
    },
    {
      name: "Support",
      value: "Support",
    },
    {
      name: "Quick wins",
      value: "Quick wins",
    },
    // {
    //   name: "Thankless",
    //   value: "Thankless",
    // },
    // {
    //   name: "Black hole",
    //   value: "Black hole",
    // },
    // {
    //   name: "Low hanging fruit",
    //   value: "Low hanging fruit",
    // },
    // {
    //   name: "Game changer",
    //   value: "Game changer",
    // },
    // {
    //   name: "Core",
    //   value: "Core",
    // },
    // {
    //   name: "Hard-core",
    //   value: "Hard-core",
    // },
    // {
    //   name: "Support",
    //   value: "Support",
    // },
    // {
    //   name: "Time sink",
    //   value: "Time sink",
    // },
  ];
  const [seletcedScheduledTaskType, setselectedScheduledTaskType] = useState();
  const [seletcedScheduledTaskStatus, setseletcedScheduledTaskStatus] = useState()
  const [taskStatusOptions, settaskStatusOptions] = useState(statusOption);



  // useEffect(()=>{
  // setScope(localStorage.getItem("scope"))
  // },[localStorage.getItem("scope")])

  // function getAlOrgMem() {
  //   dispatch(getOrgMembers(userInfoDetails.data.accessToken));
  // }

  // function getTeamMem() {
  //   if (teamId)
  //     dispatch(getTeamMemByTeamId(teamId, userInfoDetails.data.accessToken));
  // }

  useEffect(() => {
    setScheduledTaskInputFieldOpen(ScheduledTaskInputFieldOpen);
    // setScheduledTaskEffortFieldOpen(ScheduledTaskEffortFieldOpen);
    // setScheduledTasksGoalInputFieldOpen(ScheduledTasksGoalInputFieldOpen);
  }, [
    ScheduledTaskInputFieldOpen,
    // ScheduledTaskEffortFieldOpen,
    // ScheduledTasksGoalInputFieldOpen,
  ]);


  const clearAllScheduleTaskFilter = (e) => {
    e.preventDefault();
    setScheduledTaskNameFilter("");
    setScheduledTaskEffortFilter("")
    setScheduledTasksGoalNameFilter("")
    setselectedScheduledTaskImpact("")
    setselectedScheduledTaskType("")
    setseletcedScheduledTaskStatus("")

  };


  const getMyPanDetails = async () => {
    try {
      const data = await getMyDay(userInfoDetails?.data?.accessToken, ScheduledTaskNameFilter, ScheduledTaskEffortFilter, ScheduledTasksGoalNameFilter, selectedScheduledTaskImpact, seletcedScheduledTaskType, seletcedScheduledTaskStatus);
      setTasks(data?.data?.data?.task_list);
      setUpdateTaskRefresh(false)
    } catch (err) {
      console.error("Error fetching data", err);
    }
  };

  useEffect(() => {
    getMyPanDetails()
  }, [UpdateTaskRefresh])

  useEffect(() => {
    getMyPanDetails()
  }, [ScheduledTaskNameFilter, ScheduledTaskEffortFilter, ScheduledTasksGoalNameFilter, selectedScheduledTaskImpact, seletcedScheduledTaskType, seletcedScheduledTaskStatus])




  const showGoals = (event, goal) => {
    event.preventDefault();
    navigate("/goals", { state: { goal: goal, key: true } });
    console.log("selected goal", goal);
  };

  console.log("objectiveListobjectiveList", taskList)
  //   const [showEdit, setShowEditTask] = useState(false);
  //   const [isTaskUpdated, setIsTaskUpdated] = useState(false);



  useEffect(() => {
    setTasks(taskList || []);
  }, [taskList]);

  const handleTaskClick = (task) => {
    console.log('taakssssssssss', task)
    setTaskDetails(task);
    // setIsTaskUpdated(true);
  };

  const handleClose = (event) => {

    setshowTaskMoadal(false);
    setShowObjectiveModal(false)
    setCreateTask(false);


  };
  const handleModalOpenCase = () => {

    // setshowTaskMoadal(false);
    setShowObjectiveModal(false)
    // setCreateTask(false);
    // if (scope && orgId)
    dispatch(
      getGoalByScope(localStorage.getItem('scope'), localStorage?.getItem('organization_id'), localStorage.getItem('team_id'), userInfoDetails.data.accessToken)
    );


  };


  const changeTaskStatus = (selectedOption, task) => {
    console.log(selectedOption, "selectedOptionselectedOption")
    setTaskStatusValue((prev) => ({ ...prev, [task?.id]: selectedOption }));
    setValidationMessages((prev) => ({ ...prev, [task?.id]: '' }));
    if (markAsStatus?.taskId == task?.id) {
      if (markAsStatus[task?.id] == true) {
        getfeedbackTask(task, selectedOption)
      }
    }
  };


  function getfeedbackTask(task, selectedTaskStatus) {
    console.log(selectedTaskStatus, "selectedOptionselectedOptionASSSSSSSS")
    const data = taskFeedback(task?.id, userInfoDetails?.data?.accessToken, true, selectedTaskStatus, task?.task_effort);
    data?.then(async (res) => {
      // getAllScheduledTasks();
      // getUserSpecificTask();
      // getHistoryTask();
      getMyPanDetails()
      setTaskStatusValue({ value: "", label: "" })
      toast(
        <>
          <div className="m-1 d-flex">
            <i
              class="bi bi-check-circle fs-26 me-3"
              style={{ color: "#131F4C" }}
            ></i>
            <div>
              <h2
                style={{
                  // color: "#131F4C",
                  fontSize: "22px",
                }}
              >
                Completed
              </h2>
              <h3
                style={{
                  // color: "#131F4C",
                  fontSize: "18px",
                }}
              >
                Your task has been mark as complete successfully!
              </h3>
            </div>
          </div>
        </>,
        {
          toastId: "task-schedule",
          className: "toast-success-container toast-success-container-after ",
        }
      );

    })
    data?.catch(async (err) => {
      toast(
        <>
          <div className="m-1 d-flex">
            <i className="bi bi-check-circle fs-26 me-3" style={{ color: "#131F4C" }}></i>
            <div>
              <h2 style={{ fontSize: "22px" }}>Failed</h2>
              <h3 style={{ fontSize: "18px" }}>
                {err?.response?.data?.message}
              </h3>
            </div>
          </div>
        </>,
        {
          toastId: "task-schedule",
          className: "toast-error-container toast-error-container-after",
        }
      );
    })


  }

  const handleMarkAsComplete = (task, status) => {
    console.log('Task status value of this row', taskStatus[task.id]?.value === undefined)

    if (status) {
      getfeedbackTask(task, status)
    } else {
      setValidationMessages((prev) => ({
        ...prev,
        [task.id]: `* Please select Task Success to mark it as complete`,
      }));

      setMarkAsStatus((prev) => ({
        ...prev,
        [task.id]: true,
        taskId: task.id
      }));
    }
  };


  useEffect(() => {

    if (stateData?.team?.successMessage === "Organization Members") {
      let optionData = stateData?.team?.orgMembers?.data;
      let optionList = [];
      optionData?.forEach((option) => {
        const data = {
          label: option.first_name
            ? option.first_name + " " + option.last_name
            : option?.email,
          value: option.first_name
            ? option.first_name + " " + option.last_name
            : option?.email,
          userId: option.id,
        };
        optionList.push(data);
        if (option.id === userInfoDetails.data.id) {
          const data = {
            label: option?.first_name + " " + option?.last_name,
            value: option?.first_name + " " + option?.last_name,
            userId: option?.id,
          };
          // setGoalOwnerValue(data);
        }
      });
      setOptions(optionList);
    }

  }, [stateData]);


  const changeGoalOption = (userRole) => {
    setDefaultOptions(userRole);
    setGoalAlign(userRole?.userId);
  };

  const getGaol = () => {
    let optionGoalLists = [];
    let orgdata;
    orgdata = getAllGoalsByScope(
      orgId,
      // scope,
      userInfoDetails?.data?.accessToken,
      true,
      false
    );
    orgdata?.then(async (res) => {
      let optionGoalData = res?.data?.data;
      if (optionGoalData.length !== 0) {
        optionGoalData?.forEach((option) => {
          const data = {
            label: option.name,
            value: option.name,
            userId: option.id,
          };
          optionGoalLists.push(data);
        });
      }
    });
    orgdata?.catch(async (err) => { });
    setGoalOption(optionGoalLists);
  }

  useEffect(() => { getGaol() }, [])


  // useEffect(() => {
  //   getGaol()
  // }, [scope, timeScope,  teamId, startDate, endDate ]);


  const formik = useFormik({
    initialValues: {
      task_name: "",
      task_effort: "",
      task_owner: "",
      task_impact: { value: "medium", label: "Medium" },
      task_control: { value: "medium", label: "Medium" },
      task_success: { value: "", label: "" },
      goal_align: "",
    },
    validationSchema: Yup.object({
      task_name: Yup.string().required("*Task name is required"),
      task_effort: Yup.number()
        .required("*Task effort is required")
        .min(1, "Task effort must be at least 1")
        .max(20, "Task effort must be 20 or less")
        .integer("Task effort must be a whole number"),
    }),
    onSubmit: (values, { setSubmitting, setFieldValue, resetForm }) => {
      const formButton = document.activeElement;
      console.log(formButton, "Submit button 1 clicked")

      // if (formButton.className.includes('submitButtonformikForm')) {
      console.log('Submit button 1 clicked', values, goalAlignId, values?.task_impact?.value, values?.task_impact?.value);
      const createTask = {
        task_name: values?.task_name,
        // description: "",
        task_effort: values?.task_effort,
      }
      const data = createTaskService(
        orgId,
        userInfoDetails.data.id,
        values?.task_owner?.userId,
        createTask,
        values?.task_impact?.value,
        values?.task_control?.value,
        { id: goalAlignId },
        "",
        userInfoDetails.data.accessToken
      );
      // setSpinner(true);
      data?.then(async (res) => {
        // getUserSpecificTask();
        toast(
          <>
            <div className="m-1 d-flex">
              <i
                class="bi bi-check-circle fs-26 me-3"
                style={{ color: "#131F4C" }}
              ></i>
              <div>
                <h2
                  style={{
                    // color: "#131F4C",
                    fontSize: "22px",
                  }}
                >
                  Completed
                </h2>
                <h3
                  style={{
                    // color: "#131F4C",
                    fontSize: "18px",
                  }}
                >
                  {res?.data?.message}
                </h3>
              </div>
            </div>
          </>,
          {
            toastId: "task-schedule",
            className: "toast-success-container toast-success-container-after ",
          }
        );
        if (res?.data?.message === "Tasks created successfully") {
          resetForm();


        }
      });
      data?.catch(async (err) => {
        console.log(err?.response?.data?.message, "errorrrrrrrrrrrrr")

        toast(
          <>
            <div className="m-1 d-flex">
              <i class="bi bi-check-circle fs-26 me-3" style={{ color: "#131F4C" }}></i>
              <div>
                <h2 style={{
                  fontSize: "22px"
                }}>
                  Failed
                </h2>
                <h3 style={{
                  fontSize: "18px"
                }}>
                  {err?.response?.data?.message}
                </h3>
              </div>
            </div>
          </>,
          {
            toastId: "task-create",
            className: 'toast-error-container toast-error-container-after ',
          }
        )

      });
      // } 
      setSubmitting(false);
    },
  });


  const handleCircleClick = (index, event, task) => {

    console.log(index, "PIndexsss", task?.id)
    setTaskStatuses((prevStatuses) => ({
      ...prevStatuses,
      [index]: event?.value // Update the status for the specific row (task) by its index
    }));

    handleMarkAsComplete(task, event?.value);
    // changeTaskStatus("hit", task);
    changeTaskStatus(event?.value, task); // Trigger your custom function to handle the task status change
  };

  const selectedTask = (event, Task) => {
    event.preventDefault();
    setSelectedTaskDelete(Task);
  };

  const handleDeleteTaskClick = () => {
    // setTasksupdate(false);
    setTasksdelete(false);
    const data = deleteTask(
      SelectedTaskDelete?.id,
      userInfoDetails.data.accessToken
    );
    data?.then(async (res) => {
      getMyPanDetails()

    });
    data?.catch(async (err) => {
      console.log("error deleteTask");
    });
  };

  const getAdjustedTaskEffort = (effort) => {
    if (typeof effort !== 'number') return effort;
    const [integerPart, decimalPart] = effort.toString().split(".");
    const adjustedDecimal = decimalPart ? Math.floor(parseFloat("0." + decimalPart) * 60) : 0;
    const formattedDecimal = adjustedDecimal.toString().padStart(2, "0").slice(0, 2);
    return `${integerPart}:${formattedDecimal}`;
  };

  const reverseAdjustedTaskEffort = (timeString) => {
    setScheduledTaskEffortFilter(timeString);
    const [hours, minutes] = timeString.split(":").map(Number);
    if (isNaN(hours) || isNaN(minutes)) return timeString;

    const decimalMinutes = (minutes / 60).toFixed(2).slice(1);
    setScheduledTaskEffortFilter(parseFloat(hours + decimalMinutes));
    return parseFloat(hours + decimalMinutes);
  };


  return (
    <>

      {/* {taskDetailsinfo && (
        <TaskEdit
          task={taskDetailsinfo}
          // refresh={{
          //   getAllScheduledTasks,
          //   // getUserSpecificTask,
          //   getHistoryTask,
          // }}
          setTask={setTaskDetailsinfo}
          setIsTaskUpdated={setIsTaskUpdated}
          setIsTaskCompleted={setIsTaskCompleted}
        />
      )} */}



      {shareModal && (
        <ShareTaskModal
          shareModel={shareModal}
          task={selectedShareTask}
          setShareModelParent={setShareModal}

        ></ShareTaskModal>)}
      {showTaskMoadal && (
        <TaskModal
          show={showTaskMoadal}
          goal={null}
          owner=""
          handle={handleClose}
        // setIsTaskCreated={setIsTaskCreated}
        ></TaskModal>
      )}
      {showObjectiveModal && (
        <GoalModal
          show={showObjectiveModal}
          changeState={handleModalOpenCase}
          goal={CreateTask}
          handle={handleClose}
        // Subgoal={{ createSubGoal, selectedGoal, EditTask }}
        ></GoalModal>
      )}

      <Modal
        show={OpenDeleteModal}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        style={{ borderRadius: "0rem" }}
      >
        <Modal.Title className="my-3 mx-4" id="contained-modal-title-vcenter">
          <img className="float-start me-1 mt-1 p-2" alt="delete-modal" title="delete-modal" src={Vector3} />
          <h4 className="fs-20 p-2">Are you sure you want to delete Task?</h4>
        </Modal.Title>
        <Modal.Body className="modal-body mx-4 p-0">
          <p className="p-2 pt-0" style={{ color: "#717994" }}>
            You will not be able to recover this Task!
          </p>
        </Modal.Body>
        <div className="d-flex  justify-content-end mx-4 mb-3">
          <Link
            className="figma-link mt-2 me-3"
            to="/my-day"
            onClick={() => SetOpenDeleteModal(false)}
          >
            No, cancel it!
          </Link>
          <button
            className="figma-btn text-white"
            onClick={() => {
              handleDeleteTaskClick();
              SetOpenDeleteModal(false);
            }}
          >
            Yes, I am Sure!
          </button>
        </div>
      </Modal>



      <div>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <h3>{taskTitle}</h3>   <Button
            style={{ background: '#356FD4' }}
            onClick={(e) => {
              e.preventDefault();
              navigate("/tasks")
            }}
          // onClick={handleDoneButton}
          // onContextMenu={handleClick} // Right-click handler
          >
            <span style={{ color: "white" }}> See all tasks </span>
          </Button>
          {(ScheduledTaskNameFilter || ScheduledTaskEffortFilter || ScheduledTasksGoalNameFilter || selectedScheduledTaskImpact || seletcedScheduledTaskType || seletcedScheduledTaskStatus) && (
            <span
              className=" mx-3 py-0 float-end"
              title="Clear all filter"
              onClick={(e) => {
                clearAllScheduleTaskFilter(e);
              }}
              style={{
                cursor: "pointer",
                color: "#DC5D74",
              }}
            >
              Clear all filter
              <i className="fas fa-filter fs-18"></i>
              <span>x</span>
            </span>
          )}

        </div>
        <div className='table-responsive ' >
          <form onSubmit={formik.handleSubmit}>
            <table className="display w-100 dataTable mt-4 mb-4" id="example5" role="grid" aria-describedby="example5_info" style={{ marginBottom: "40px" }}>
              <thead style={{ border: "1px solid #d7dae3" }}>
                <tr role="row" style={{ border: "1px solid #d7dae3", textAlign: 'center', verticalAlign: 'center' }} >
                  {/* <th>Who</th> */}
                  <th
                    className="sorting"
                    style={{ border: "1px solid #d7dae3", textAlign: "center", verticalAlign: "middle" }}
                  >
                    <div className="d-flex justify-content-center align-items-center m-0 p-0">
                      <span className="float-start" style={{ paddingRight: "10px", fontWeight: "bold", color: "#131F4C" }}>
                        Task
                      </span>

                      {filterScheduleTaskIcon ? (
                        <Dropdown
                          title="Filter"
                          onClick={(e) => {
                            e.preventDefault();
                            setScheduledTaskInputFieldOpen(!ScheduledTaskInputFieldOpen);
                          }}
                        >
                          <Dropdown.Toggle
                            style={{
                              background: "none",
                              border: "none",
                            }}
                            className="m-0 p-0"
                          >
                            <i
                              className="fas fa-filter fs-12"
                              style={{
                                cursor: "pointer",
                                color: ScheduledTaskNameFilter ? "#DC5D74" : "#898fa5",
                              }}
                            ></i>
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <input
                              type="text"
                              name="name"
                              className="figma-input m-0 p-2"
                              placeholder="Enter task name"
                              autoComplete="off"
                              value={ScheduledTaskNameFilter}
                              onChange={(e) => setScheduledTaskNameFilter(e.target.value)}
                              style={{
                                border: "1px solid black",
                                width: "150px",
                              }}
                            />
                          </Dropdown.Menu>
                        </Dropdown>
                      ) : (
                        ""
                      )}
                    </div>
                  </th>

                  <th className="sorting ">
                    <div className="d-flex justify-content-center align-items-center m-0 p-0">
                      <span className="" style={{ paddingRight: "10px", fontWeight: "bold", color: "#131F4C" }}>
                        {/* <i
                                      class="fas fa-sort"
                                      onClick={() => {
                                        handleClick1("goal", !sortAsc);
                                      }}
                                    ></i> */}
                        Goal
                      </span>
                      {filterScheduleTaskIcon ? (
                        <Dropdown
                          // className="setDropDownposition float-end"
                          title="Filter"
                          // style={{ right: "68%" }}
                          onClick={() => {
                            setScheduledTasksGoalInputFieldOpen(
                              !ScheduledTasksGoalInputFieldOpen
                            );
                          }}
                        >
                          <Dropdown.Toggle
                            style={{
                              background: "none",
                              border: "none",
                            }}
                            className="m-0 p-0"
                          >
                            <i
                              className="fas fa-filter fs-12"
                              style={{
                                cursor: "pointer",
                                color: ScheduledTasksGoalNameFilter
                                  ? "#DC5D74"
                                  : "#898fa5",
                              }}
                            ></i>
                          </Dropdown.Toggle>
                          <div>
                            <Dropdown.Menu
                              menuposition="top-right"
                              className=""
                              style={{
                                width: "auto",
                                height: "200px",
                                overflowY: "scroll",
                                scrollbarWidth: "0px",
                              }}
                            >
                              {goalOption?.map((goal, i) => (
                                <Dropdown.Item
                                  key={i}
                                  style={
                                    ScheduledTasksGoalNameFilter ===
                                      goal?.userId
                                      ? { backgroundColor: "#B8E5F1" }
                                      : { backgroundColor: "#ffffff" }
                                  }
                                  value={goal?.value}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    setScheduledTasksGoalNameFilter(
                                      goal?.userId
                                    );
                                  }}
                                  className="p fs-14"
                                >
                                  {goal?.label}
                                </Dropdown.Item>
                              ))}
                            </Dropdown.Menu>
                          </div>
                        </Dropdown>
                      ) : (
                        ""
                      )}
                    </div>
                  </th>
                  <th className="sorting perFields" style={{ border: "1px solid #d7dae3" }}>
                    <div className="d-flex justify-content-center align-items-center m-0 p-0">
                      <span className="" style={{ paddingRight: "10px", fontWeight: "bold", color: "#131F4C" }}>
                        {/* <i
                                      class="fas fa-sort"
                                      onClick={() => {
                                        handleClick1("impact", !sortAsc);
                                      }}
                                    ></i> */}
                        Impact
                      </span>
                      {filterScheduleTaskIcon ? (
                        <Dropdown
                          // className="setDropDownposition float-end"
                          title="Filter"
                        // style={{ right: "49%" }}
                        >
                          <Dropdown.Toggle
                            style={{
                              background: "none",
                              border: "none",
                            }}
                            className="m-0 p-0"
                          >
                            <i
                              className="fas fa-filter fs-12"
                              style={{
                                cursor: "pointer",
                                color: selectedScheduledTaskImpact
                                  ? "#DC5D74"
                                  : "#898fa5",
                              }}
                            ></i>
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            {TaskImpact?.map((scope, i) => (
                              <Dropdown.Item
                                style={
                                  selectedScheduledTaskImpact ===
                                    scope.value
                                    ? { backgroundColor: "#B8E5F1" }
                                    : { backgroundColor: "#ffffff" }
                                }
                                onClick={(e) => {
                                  e.preventDefault();
                                  setselectedScheduledTaskImpact(
                                    scope.value
                                  );
                                }}
                                key={i}
                                className="p fs-14"
                              >
                                {scope?.name}
                              </Dropdown.Item>
                            ))}
                          </Dropdown.Menu>
                        </Dropdown>
                      ) : (
                        ""
                      )}
                    </div>
                  </th>
                  <th className="sorting perFields">
                    <div className="d-flex justify-content-center align-items-center m-0 p-0">
                      <span className="float-start" style={{ paddingRight: "10px", fontWeight: "bold", color: "#131F4C" }}>
                        {/* <i
                                      class="fas fa-sort"
                                      onClick={() => {
                                        handleClick1("effort", !sortAsc);
                                      }}
                                    ></i> */}
                        Effort
                      </span>
                      {filterScheduleTaskIcon ? (
                        <Dropdown
                          // className="setDropDownposition float-end"
                          title="Filter"
                          // style={{ right: "39%" }}
                          onClick={(e) => {
                            e.preventDefault();
                            setScheduledTaskEffortFieldOpen(
                              !ScheduledTaskEffortFieldOpen
                            );
                          }}
                        >
                          <Dropdown.Toggle
                            style={{
                              background: "none",
                              border: "none",
                            }}
                            className="m-0 p-0"
                          >
                            <i
                              className="fas fa-filter fs-12"
                              style={{
                                cursor: "pointer",
                                color: ScheduledTaskEffortFilter
                                  ? "#DC5D74"
                                  : "#898fa5",
                              }}
                            ></i>
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            {
                              <input
                                type="string"
                                name="name"
                                className="figma-input p m-0 p-2"
                                placeholder="Enter task effort"
                                autocomplete="off"
                                value={ScheduledTaskEffortFilterForShow}
                                onChange={(e) =>
                                // setScheduledTaskEffortFilter(e.target.value)
                                {
                                  setScheduledTaskEffortFilterForShow(e.target.value);
                                  reverseAdjustedTaskEffort(e.target.value);
                                }
                                }
                                style={{
                                  border: "1px solid black",
                                  width: "150px",
                                }}
                              />
                            }
                          </Dropdown.Menu>
                        </Dropdown>
                      ) : (
                        ""
                      )}
                    </div>
                  </th>
                  <th style={{ border: "1px solid #d7dae3" }}>
                    <OverlayTrigger
                      delay={{ hide: 100, show: 10 }}
                      overlay={
                        <Tooltip id="tooltip-top">
                          <span className='fs-18'>AI sorted this task as [core/quick/support] to help you prioritize effectively. Learn more here!</span>
                        </Tooltip>
                      }
                      placement="top"
                    >
                      <div className="d-flex justify-content-center align-items-center m-0 p-0">
                        <span className="float-start" style={{ paddingRight: "10px", fontWeight: "bold", color: "#131F4C" }}>
                          {" "}
                          {/* <i
                                      class="fas fa-sort"
                                      onClick={() => {
                                        handleClick1("type", !sortAsc);
                                      }}
                                    ></i> */}
                          Type
                        </span>
                        {filterScheduleTaskIcon ? (
                          <Dropdown
                            // className="setDropDownposition float-end"
                            title="Filter"
                          // style={{ right: "79.5%" }}
                          >
                            <Dropdown.Toggle
                              style={{
                                background: "none",
                                border: "none",
                              }}
                              className="m-0 p-0"
                            >
                              <i
                                className="fas fa-filter fs-12"
                                style={{
                                  cursor: "pointer",
                                  color: seletcedScheduledTaskType
                                    ? "#DC5D74"
                                    : "#898fa5",
                                }}
                              ></i>
                            </Dropdown.Toggle>
                            <Dropdown.Menu
                              style={{
                                width: "auto",
                                height: "120px",
                              }}
                            >
                              {tasktype?.map((scope, i) => (
                                <Dropdown.Item
                                  style={
                                    seletcedScheduledTaskType ===
                                      scope.value
                                      ? { backgroundColor: "#B8E5F1" }
                                      : { backgroundColor: "#ffffff" }
                                  }
                                  onClick={(e) => {
                                    e.preventDefault();
                                    setselectedScheduledTaskType(
                                      scope.value
                                    );
                                  }}
                                  key={i}
                                  className="p fs-14"
                                >
                                  {scope?.name}
                                </Dropdown.Item>
                              ))}
                            </Dropdown.Menu>
                          </Dropdown>
                        ) : (
                          ""
                        )}
                      </div>
                    </OverlayTrigger>
                  </th>

                  {/* <th style={{ paddingRight: "80px" }}>Complete</th> */}
                  <th style={{ border: "1px solid #d7dae3" }}>
                    <div className="d-flex justify-content-center align-items-center m-0 p-0">
                      <span className="float-start" style={{ paddingRight: "10px", fontWeight: "bold", color: "#131F4C" }}>
                        {" "}
                        {/* <i
                                      class="fas fa-sort"
                                      onClick={() => {
                                        handleClick1("type", !sortAsc);
                                      }}
                                    ></i> */}
                        Outcome
                      </span>
                      {/* {filterScheduleTaskIcon ? (
                        <Dropdown
                          // className="setDropDownposition float-end"
                          title="Filter"
                        // style={{ right: "79.5%" }}
                        >
                          <Dropdown.Toggle
                            style={{
                              background: "none",
                              border: "none",
                            }}
                            className="m-0 p-0"
                          >
                            <i
                              className="fas fa-filter fs-12"
                              style={{
                                cursor: "pointer",
                                color: seletcedScheduledTaskStatus
                                  ? "#DC5D74"
                                  : "#898fa5",
                              }}
                            ></i>
                          </Dropdown.Toggle>
                          <Dropdown.Menu
                            style={{
                              width: "auto",
                              height: "120px",
                            }}
                          >
                            {statusOption?.map((scope, i) => (
                              <Dropdown.Item
                                style={
                                  seletcedScheduledTaskStatus ===
                                    scope.value
                                    ? { backgroundColor: "#B8E5F1" }
                                    : { backgroundColor: "#ffffff" }
                                }
                                onClick={(e) => {
                                  e.preventDefault();
                                  setseletcedScheduledTaskStatus(
                                    scope.value
                                  );
                                }}
                                key={i}
                                className="p fs-14"
                              >
                                {scope?.name}
                              </Dropdown.Item>
                            ))}
                          </Dropdown.Menu>
                        </Dropdown>
                      ) : (
                        ""
                      )} */}
                    </div>
                  </th>
                  <th style={{ border: "1px solid #d7dae3" }}><span style={{ fontWeight: "bold", color: "#131F4C" }}>Action</span></th>
                  {/* <th>Outcome</th> */}
                </tr>
              </thead>
              <tbody style={{ border: "1px solid #d7dae3" }} >
                {tasks.length === 0 ? (
                  <tr role="row" className='mb-2' >
                    <td colSpan="3" style={{ textAlign: 'center' }}>No data available</td>
                  </tr>
                ) : (
                  tasks.map((task, index) => (
                    <tr key={index} role="row" style={{ border: "1px solid #d7dae3", textAlign: "center", verticalAlign: "center" }}>

                      <td onClick={(event) => handleTaskClick(event, task)} style={{ cursor: "pointer", border: "1px solid #d7dae3" }}>{task?.name}</td>
                      <td onClick={(event) => handleTaskClick(event, task)} style={{ cursor: "pointer", border: "1px solid #d7dae3" }}>{task?.goal?.name}</td>
                      <td onClick={(event) => handleTaskClick(event, task)} style={{
                        // backgroundColor:
                        //   task.task_impact === "High" ? "#ADD8E6" :
                        //     task.task_impact === "Medium" ? "#FFFF0080" :
                        //       "#FFB6C1",
                        cursor: "pointer", border: "1px solid #d7dae3"
                      }}>{task?.task_impact}</td>
                      <td style={{ cursor: "pointer", border: "1px solid #d7dae3" }} onClick={(event) => handleTaskClick(event, task)}>
                        {getAdjustedTaskEffort(task.task_effort)}
                      </td>
                      <td onClick={(event) => handleTaskClick(event, task)} style={{
                        // backgroundColor:
                        //   task.task_type === "Game Changer" ? "#4B008280" :   // Deep Purple for Game Changer
                        //     task.task_type === "Quick" ? "#FF69B4" :           // Hot Pink for Quick
                        //       "#D3D3D3",
                        cursor: "pointer", border: "1px solid #d7dae3",
                      }}>{task?.task_type}</td>
                      <td style={{
                        cursor: "pointer",
                        // border: "1px solid #d7dae3", 
                        textAlign: 'center',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '100%'  // Ensure full height to properly center vertically
                      }}>
                        <>
                          {/* <div className="">
                            <a
                              data-tooltip-id="my-tooltip"
                              data-tooltip-content={userInfoDetails?.data?.id !== task?.task_owner?.id &&
                                userInfoDetails?.data?.id !== task?.task_creator?.id &&
                                userInfoDetails?.data?.user_role !== "owner" ?
                                "You don't have permission to edit this field!" : ""}
                              style={userInfoDetails?.data?.id !== task?.task_owner?.id ||
                                userInfoDetails?.data?.id !== task?.task_creator?.id ?
                                { cursor: "not-allowed" } : {}}
                            >
                              {task?.task_completed_time ? (
                                <>
                                  <Stack
                                    gap={2}
                                    direction={"row"}
                                    borderRadius="50px"
                                    padding={"1px"}
                                    style={{
                                      opacity: taskStatuses[index] ? 0.6 : 1,
                                      cursor: "not-allowed",
                                    }}
                                  >
                                    {task?.task_success === "Beat" ? (
                                      <FaCircleCheck size={22} color='#737373' style={{ cursor: "not-allowed" }} />
                                    ) : (
                                      <VscCircleLargeFilled size={22} color='#737373' style={{ cursor: "not-allowed" }} />
                                    )}

                                    {task?.task_success === "Hit" ? (
                                      <FaCircleCheck size={22} color='#FF980090' style={{ cursor: "not-allowed" }} />
                                    ) : (
                                      <VscCircleLargeFilled size={24} color='#FF980090' style={{ cursor: "not-allowed" }} />
                                    )}

                                    {task?.task_success === "Miss" ? (
                                      <FaCircleCheck size={22} color='#8BC34A' style={{ cursor: "not-allowed" }} />
                                    ) : (
                                      <VscCircleLargeFilled size={22} color='#8BC34A' style={{ cursor: "not-allowed" }} />
                                    )}
                                  </Stack>
                                </>
                              ) : (
                                <>
                                  <Stack gap={2} direction={"row"} borderRadius="50px" padding={"1px"}>
                                    {taskStatuses[index] === "beat" ? (
                                      <FaCircleCheck size={22} color='#737373' />
                                    ) : (
                                      <VscCircleLargeFilled
                                        size={22} color='#737373'
                                        onClick={(event) => handleCircleClick("beat", index, event, task)}
                                      />
                                    )}

                                    {taskStatuses[index] === "hit" ? (
                                      <FaCircleCheck size={22} color='#FF980090' />
                                    ) : (
                                      <VscCircleLargeFilled
                                        size={22} color='#FF980090'
                                        onClick={(event) => handleCircleClick("hit", index, event, task)}
                                      />
                                    )}

                                    {taskStatuses[index] === "miss" ? (
                                      <FaCircleCheck size={22} color='#8BC34A' />
                                    ) : (
                                      <VscCircleLargeFilled
                                        size={24} color='#8BC34A'
                                        onClick={(event) => handleCircleClick("miss", index, event, task)}
                                      />
                                    )}
                                  </Stack>
                                </>
                              )}
                            </a>
                            <ReactTooltip effect="solid" id="my-tooltip" />
                          </div> */}
                          <div className="d-flex justify-content-center align-items-center m-0 p-0">
                            <div className="d-flex mb-1 align-items-center">
                              <a data-tooltip-id="my-tooltip"
                                data-tooltip-content={userInfoDetails?.data?.id !== task?.task_owner?.id &&
                                  userInfoDetails?.data?.id !== task?.task_creator?.id ?
                                  "You don't have permission to edit this field!" : ""}
                                style={userInfoDetails?.data?.id !== task?.task_owner?.id ||
                                  userInfoDetails?.data?.id !== task?.task_creator?.id ?
                                  { cursor: "not-allowed" } : {}}
                              >
                                <Select
                                  className='p'
                                  name="task_status"
                                  isClearable
                                  value={
                                    taskStatusOptions.find(option => option.value === setTaskStatuses[task.id]) || null
                                  }
                                  options={taskStatusOptions}
                                  // defaultValue={taskStatuses[index] === "beat" ? { value: "beat", label: "Epic" } :
                                  //   taskStatuses[index] === "hit" ? { value: "hit", label: "Ok" } : taskStatuses[index] === "miss" ? { value: "miss", label: "Miss" }
                                  //     : { value: "", label: "" }
                                  // }
                                  onChange={(event) => handleCircleClick(index, event, task)}
                                  isDisabled={
                                    task?.task_owner?.id !==
                                    userInfoDetails?.data?.id &&
                                    task?.task_creator?.id !==
                                    userInfoDetails?.data?.id
                                  }
                                />
                              </a>
                              <ReactTooltip effect="solid" id="my-tooltip" />
                            </div>
                          </div>
                          <div>
                            {validationMessages[task.id] && (
                              <span className="text-danger">{validationMessages[task.id]}</span>
                            )}
                          </div>
                        </>
                      </td>

                      <td style={{ cursor: "pointer", border: "1px solid #d7dae3", }}>


                        {task?.task_owner?.id ===
                          userInfoDetails?.data?.id ||
                          task?.task_creator?.id ===
                          userInfoDetails?.data?.id ||
                          userInfoDetails?.data?.user_role === "owner" ? (<>


                            <Dropdown className="ms-auto">
                              <Dropdown.Toggle
                                variant=""
                                as="div"
                                className="btn-link i-false"
                              >
                                <svg
                                  width="20"
                                  height="20"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <circle cx="12.4999" cy="3.5" r="2.5" fill="#223A69" />
                                  <circle cx="12.4999" cy="11.5" r="2.5" fill="#223A69" />
                                  <circle cx="12.4999" cy="19.5" r="2.5" fill="#223A69" />
                                </svg>
                              </Dropdown.Toggle>

                              {ReactDOM.createPortal(
                                <Dropdown.Menu
                                  alignRight={true}
                                  data-testid="org-menu"
                                  className="dropdown-menu-right"
                                  style={{ position: 'absolute', zIndex: 1050 }}
                                >
                                  <Dropdown.Item
                                    data-testid="org-edit"
                                    onClick={() => handleTaskClick(task)}

                                  >
                                    Edit
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    data-testid="org-delete"
                                    onClick={(event) => {
                                      setShareModal(true)
                                      setSelectedShareTask(task)
                                    }}
                                  >
                                    Share
                                  </Dropdown.Item>



                                  <Dropdown.Item
                                    className="text-danger"
                                    data-testid="org-delete"
                                    onClick={(event) => {
                                      selectedTask(event, task);
                                      SetOpenDeleteModal(true);
                                    }}
                                  >
                                    Delete
                                  </Dropdown.Item>
                                </Dropdown.Menu>,
                                document.body // This ensures the Dropdown.Menu is rendered outside the regular DOM hierarchy
                              )}
                            </Dropdown>

                          </>) : (<>
                            -
                          </>)}







                      </td>




                    </tr>
                  ))


                )}
              </tbody>
              <>
                <tr style={{ borderTop: "1px solid #d7dae3", marginTop: "20px" }}>

                  <div className='mt-3'>
                    <OverlayTrigger
                      delay={{ hide: 100, show: 10 }}
                      overlay={
                        <Tooltip id="tooltip-right">
                          <span className='fs-18'>Quick Add Task</span>
                        </Tooltip>
                      }
                      placement="right"
                    >

                      <Button
                        style={{ background: '#356FD4', border: "1px solid #356FD4", height: '50px', width: '50px' }}
                        onClick={(e) => {
                          e.preventDefault();
                          setshowTaskMoadal(true)
                        }}
                      // onClick={handleDoneButton}
                      // onContextMenu={handleClick} // Right-click handler
                      >
                        <FaPlus color='#FFFFFF'></FaPlus>
                      </Button>
                    </OverlayTrigger>
                  </div>
                </tr>

              </>
            </table>


          </form>
        </div>
      </div>
      {(taskDetails) && (
        <TaskEdit
          task={taskDetails}
          setIsTaskUpdated={() => setTaskDetails()}
          setTask={() => setTaskDetails()}
          setUpdateTaskRefresh={() => setUpdateTaskRefresh()}
        />
      )}
    </>
  );
});

export default PlannedTasks;


