import { lazy, Suspense, useEffect, useState } from "react";
/// Components
import Index from "./jsx/index";
import { connect, useDispatch } from "react-redux";
import { useNavigate, Routes } from "react-router-dom";
import { Route } from "react-router";
// action
import { checkAutoLogin } from "./services/AuthService";
import { isAuthenticated } from "./store/selectors/AuthSelectors";
import { useLocation } from "react-router-dom";
/// Style
import "./vendor/bootstrap-select/dist/css/bootstrap-select.min.css";
import "./css/style.css";
import "./App.css";
import "./css/app.css"
import StoreFront from "./jsx/layouts/StoreFront/StoreFront";
import swal from "sweetalert";
// import ProductPage from "./jsx/layouts/StoreFront/ProductPage";
// import CategoryPage from "./jsx/layouts/StoreFront/CategoryPage";
// import SelectedProductList from "./jsx/layouts/StoreFront/SelectedProductList";
// import AboutUs from "../src/jsx/layouts/StoreFront/AboutUs";
// import UnsubscribeMail from "./jsx/layouts/StoreFront/UnsubscribeMail";
// import BecomingPlansomPartner from "./jsx/layouts/StoreFront/BecomigPlansomPartner";
// import ContacUsAndSupport from "./jsx/layouts/StoreFront/ContactUsAndSupport";
// import Privacy from "./jsx/layouts/StoreFront/Privacy";
// import Pricing from "./jsx/layouts/StoreFront/Pricing";
// import TermsAndConditions from "./jsx/layouts/StoreFront/TermsAndConditions";
// import CookiePolicy from "./jsx/layouts/StoreFront/CookiePolicy";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import Solutions from "./jsx/layouts/StoreFront/Solutions";
import PartnerRegistration from "./jsx/pages/PartnerApply";
import PartnerSignUp from "./jsx/pages/PartnerSignUp";
import Registration from "./jsx/pages/Registration";
import WebSocketInstance from "./jsx/services/WebSocket";



const Pricing = lazy(() => import("./jsx/layouts/StoreFront/Pricing"));
const TermsAndConditions = lazy(() => import("./jsx/layouts/StoreFront/TermsAndConditions"));
const Privacy = lazy(() => import("./jsx/layouts/StoreFront/Privacy"));
const CookiePolicy = lazy(() => import("./jsx/layouts/StoreFront/CookiePolicy"));
const ContacUsAndSupport = lazy(() => import("./jsx/layouts/StoreFront/ContactUsAndSupport"));
const BecomingPlansomPartner = lazy(() => import("./jsx/layouts/StoreFront/BecomigPlansomPartner"));
const UnsubscribeMail = lazy(() => import("./jsx/layouts/StoreFront/UnsubscribeMail"));
const AboutUs = lazy(() => import("../src/jsx/layouts/StoreFront/AboutUs"));
const SelectedProductList = lazy(() => import("./jsx/layouts/StoreFront/SelectedProductList"));
const CategoryPage = lazy(() => import("./jsx/layouts/StoreFront/CategoryPage"));
const ProductPage = lazy(() => import("./jsx/layouts/StoreFront/ProductPage"));
// const StoreFront = lazy(() => import("./jsx/layouts/StoreFront/StoreFront"));


const PlansomPage = lazy(() => import("./jsx/layouts/PlansomPage"));
const NewPlansomPage = lazy(() => import("./jsx/layouts/NewPlansomPage"));
const NewPlansomPage2 = lazy(() => import("./jsx/layouts/NewPlansomPage2"));
const SignUp = lazy(() => import("./jsx/pages/Registration"));
const LoginWithGooglePage = lazy(() => import("./jsx/layouts/auth/LoginWithGooglePage"));
const SignUpPage = lazy(() => import("./jsx/layouts/auth/SignUpPage"));
const SignInPage = lazy(() => import("./jsx/layouts/auth/SignInPage"));
const ResetPasswordPage = lazy(() => import("./jsx/layouts/auth/ResetPasswordPage"));
const VerifyEmailPage = lazy(() => import('./jsx/layouts/auth/VerifyEmailPage'));
const ForgotPassword = lazy(() => import("./jsx/pages/ForgotPassword"));
const Login = lazy(() => {
  return new Promise((resolve) => {
    setTimeout(() => resolve(import("./jsx/pages/Login")), 500);
  });
});




function App(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  console.log(props.isAuthenticated, "index index index1")

  // const [loggedInUsers, setLoggedInUsers] = useState(localStorage.getItem("loggedIn"))


  console.log("hiiiiiiii", localStorage.getItem("remember"), localStorage.getItem("loggedIn"))

  useEffect(() => {
    if (!location?.pathname.includes("/messages")) {
      console.log("Websocket hiiiii hhh")
      WebSocketInstance.logout()
    }
    if (location?.pathname?.includes("/messages")) {
      WebSocketInstance.connect(localStorage.getItem("websocketIds"), JSON.parse(localStorage?.getItem("userDetails")).data)
    }

    console.log((location?.pathname.includes("/tasks") && (
      localStorage.getItem("loggedIn") == null ||
      localStorage.getItem("loggedIn") === "null" ||
      localStorage.getItem("loggedIn") === "false"
    )), "111111111111111100000000000000111111111111111")

    if (location?.pathname.includes("/tasks") && (
      localStorage.getItem("loggedIn") == null ||
      localStorage.getItem("loggedIn") === "null" ||
      localStorage.getItem("loggedIn") === "false"
    )) {
      navigate("/login")
      swal("please login to see task");
    }
  }, [location.pathname])

  useEffect(() => {


    console.log((location?.pathname.includes("/goals") && (
      localStorage.getItem("loggedIn") == null ||
      localStorage.getItem("loggedIn") === "null" ||
      localStorage.getItem("loggedIn") === "false"
    )), "111111111111111100000000000000111111111111111")

    if (location?.pathname.includes("/goals") && (
      localStorage.getItem("loggedIn") == null ||
      localStorage.getItem("loggedIn") === "null" ||
      localStorage.getItem("loggedIn") === "false"
    )) {
      navigate("/login")
      swal("please login to see goal");
    }
  }, [location.pathname])



  const generateCanonicalURL = () => {
    console.log(window.location.href, "8888888888888111")
    if (window.location.href === "http://localhost:45678/") {
      return "/"
    } else {
      return window.location.href;
    }
  };

  const generatePageTitle = () => {
    const pathSegments = location.pathname.split('/');
    const pageName = pathSegments[pathSegments.length - 1].replace(/\.[^/.]+$/, '');
    const pageTitle = pageName.replace(/-/g, ' ').replace(/\b\w/g, word => word.toUpperCase());
    console.log(pageTitle.length, "@@@@@@@@@@@@@@@@@@@@@@@@")
    const limitedPageTitle = pageTitle.length > 30 ? pageTitle.substring(0, 27) + '...' : pageTitle;
    console.log(limitedPageTitle, pageTitle, pageName, "limitedPageTitle")
    return limitedPageTitle;
  };

  useEffect(() => {
    const canonicalLink = document.querySelector('link[rel="canonical"]');

    if (!canonicalLink) {
      const newCanonicalLink = document.createElement('link');
      newCanonicalLink.rel = 'canonical';
      document.head.appendChild(newCanonicalLink);
    }

    document.title = generatePageTitle();
  }, [location.pathname]);

  useEffect(() => {
    document.querySelector('link[rel="canonical"]').href = generateCanonicalURL();
  }, [location.pathname, generateCanonicalURL]);

  useEffect(() => {
    if (
      localStorage.getItem("loggedIn") == null ||
      localStorage.getItem("loggedIn") === "null" ||
      localStorage.getItem("loggedIn") === false
    ) {
      localStorage.setItem("loggedIn", false);
    }
    if (
      localStorage.getItem("remember") === null ||
      localStorage.getItem("remember") === "null" ||
      localStorage.getItem("remember") === true
    ) {
      localStorage.setItem("remember", true);
    }
    // if (location?.search) {
    //   const params = new URLSearchParams(location.search);
    //   const obj = Object.fromEntries(params.entries())
    //   console.log("Email", obj.email)
    // }
    if (location?.pathname.includes("/unsubscribe-mail")) {
      // navigate('/unsubscribe-mail');
    }
    else if (location?.pathname?.includes("/users/signup/invitation")) {
      // localStorage.clear();
      let url = location?.pathname.split("/");
      let email = url[2];
      localStorage.setItem("invite_key", url[6]);
      console.log("Navigate register")
      navigate("/register", { state: { email: email } });
    } else if (location?.pathname.includes("/users/login/invitation")) {
      localStorage.clear();
      let url = location?.pathname.split("/");
      let email = url[2];
      localStorage.setItem("invite_key", url[6]);
      console.log("Navigate login")
      navigate("/sign-in", { state: { email: email } });
    } else if (location.pathname === "/page-register") {
      console.log("Navigate register 22")
      navigate("/page-register");
    } else if (location.pathname === "/page-forgot-password") {
      navigate("/page-forgot-password");
    } else if (
      localStorage.getItem("loggedIn") === "false" &&
      localStorage.getItem("remember") === "false" &&
      location.pathname === "/"
    ) {
      console.log("Navigate ////")
      // navigate("/");
    } else if (
      localStorage.getItem("loggedIn") === "false" &&
      localStorage.getItem("remember") === "true" &&
      location.pathname === "/login"
    ) {
      console.log("Navigate login 2222")
      navigate("/login");
    } else if (
      localStorage.getItem("remember") === "false" &&
      localStorage.getItem("loggedIn") === "true" &&
      location.pathname === "/login"
    ) {
      console.log("Navigate login 33333")
      navigate("/login");
    } else if (location.pathname === "/login") {
      console.log("Authlogin 22")
      // navigate("/login");
    } else if (location.pathname === "/store-front") {
      console.log("Authlogin 33,", location.pathname)
      navigate(location.pathname);
    } else if (location.pathname === "/product-page") {
      console.log("Authlogin 44")
      // navigate(location.pathname);
    } else if (location.pathname === "/category-page") {
      console.log("Authlogin 55"); navigate(location.pathname);
    } else if (location.pathname === "/privacy") {
      console.log("Authlogin 66"); navigate(location.pathname);
    } else if (location.pathname === "/terms-and-conditions") {
      console.log("Authlogin 77"); navigate(location.pathname);
    } else if (location.pathname === "/cookie-policy") {
      console.log("Authlogin 88"); navigate(location.pathname);
    } else if (location.pathname === "/about-us") {
      console.log("Authlogin 99"); navigate(location.pathname);
    } else if (location.pathname === "/plansom-partner") {
      console.log("Authlogin 10"); navigate(location.pathname);
    } else if (location.pathname === "/contactUs-support") {
      console.log("Authlogin 111100"); navigate(location.pathname);
    } else if (location.pathname === "/pricing") {
      console.log("Authlogin 111144"); navigate(location.pathname);
    }
    // else if (location.pathname === "/unsubscribe-mail") {
    //   navigate(location.pathname);
    // }

    else if (
      localStorage.getItem("remember") === "true" &&
      localStorage.getItem("loggedIn") === "true" &&
      localStorage.getItem("userDetails") && !location.pathname.includes("/product") && !location.pathname.includes("/category") && !location.pathname.includes("/subcategory")
    ) {
      console.log("Authlogin 33333");
      if (
        location.pathname !== "/login" &&
        location.pathname !== "/page-forgot-password" &&
        location.pathname !== "/page-register" &&
        location.pathname !== "/" &&
        location.pathname !== "/store-front" &&
        location.pathname !== "/product-page" &&


        location.pathname !== "/category-page" &&
        location.pathname !== "/privacy" &&
        location.pathname !== "/terms-and-conditions" &&
        location.pathname !== "/cookie-policy" &&
        location.pathname !== "/about-us" &&
        location.pathname !== "/plansom-partner" &&
        location.pathname !== "/contactUs-support" &&
        location.pathname !== "/pricing" &&
        location.pathname !== "/premium-customer-experience-program" &&
        location.pathname !== "/solutions"&&
        location.pathname !== "/sign-up" &&
        location.pathname !== "/sign-in" &&
        location.pathname !== "/page-register" &&
        location.pathname !== "/register" &&
        location.pathname !== "/reset-password" &&
        location.pathname !== "/verify-email" 
        // location.pathname !== "/unsubscribe-mail"
      ) {
        checkAutoLogin(dispatch, navigate);
      }
    }
    if (location?.pathname.includes("/user-register/success")) {
      navigate("/organization-list");
    } else if (location?.pathname.includes("/invite-user-register/success")) {
      navigate("/home");
    }

    if (location?.pathname.includes("users/partner/signup")) {
      localStorage.clear();
      let url = location?.pathname.split("/");
      let email = url[5];
      localStorage.setItem("invite_keys", url[4]);
      console.log("Navigate login", url[5], url[4])
      // navigate("/login", { state: { email: email } });
      navigate("/page-partner", { state: { email: email, key: url[4] } })
    }
  }, [location.pathname]);

  let routes = (
    <Routes>
      {/* <Route path="/login" element={<Login />} title="Login" /> */}
      <Route path="/login" element={<SignInPage />} title="Login" />
      <Route path="/sign-in" element={<SignInPage />} title="Login" />
      {/* <Route path="/page-register" element={<SignUp />} title="Sign Up" /> */}
      <Route path="/page-register" element={<LoginWithGooglePage />} title="Sign Up" />
      <Route path="/sign-up" element={<LoginWithGooglePage />} title="Sign Up" />
      <Route path="/register" element={<SignUpPage />} title="Sign Up" />
      <Route path="/reset-password" element={<ResetPasswordPage />} title="Forgot Password" />
      <Route path="/verify-email" element={<VerifyEmailPage />} title="Verify Email" />
      <Route path="/page-partner" element={<PartnerSignUp />} title="Sign Up" />
      <Route path="/page-partner-register" element={<PartnerRegistration />} title="Sign Up" />
      <Route path="/page-forgot-password" element={<ForgotPassword />} title="Forgot Password" />
      {/* <Route path="/" element={<PlansomPage />} title="Home" />
      <Route path="/:plansom" element={<PlansomPage />} title="Home" />
      <Route path="/plansom-page" element={<PlansomPage />} title="Home" /> */}
      <Route path="/" element={<NewPlansomPage2 />} title="Home" />
      <Route path="/:plansom" element={<NewPlansomPage2 />} title="Home" />
      <Route path="/plansom-page" element={<NewPlansomPage2 />} title="Home" />
      <Route path="/new-plansom-page" element={<NewPlansomPage />} title="Home" />
      <Route path="/new-plansom-page-ant-design" element={<NewPlansomPage2 />} title="Home" />
      {/* <Route path="/store-front" element={<StoreFront />} title="Store Front" /> */}
      {/* <Route path="/store-front/" element={<StoreFront />} title="Store Front" /> */}
      <Route path="/product-page" element={<ProductPage />} title="Product Page" />
      {/* <Route path="/:productName" element={<ProductPage />}  title="Product Page"/> */}
      {/* <Route path="/product/:productName" element={<ProductPage />}  title="Product Page"/> */}
      <Route exact path="/product-page/:id" element={<ProductPage />} title="Product Page" />
      <Route path="/category-page" element={<CategoryPage />} title="Category Page" />
      <Route path="/category/:categoryName" element={<CategoryPage />} />
      <Route path="/category/:id/:categoryName" element={<CategoryPage />} />
      <Route path="/subcategory/:id/:categoryName" element={<CategoryPage />} />
      <Route path="/:categoryName/:id" element={<CategoryPage />} title="Category Page" />
      {/* <Route path="/:categoryName/:subCategoryName" element={<CategoryPage />} /> */}
      <Route exact path="/category-page/:id" element={<CategoryPage />} title="Category Page" />
      <Route path="/privacy" element={<Privacy />} title="Privacy" />
      <Route path="/terms-and-conditions" element={<TermsAndConditions />} title="Terms and Conditions" />
      <Route path="/cookie-policy" element={<CookiePolicy />} title="Cookie Policy" />
      <Route path="/about-us" element={<AboutUs />} title="About Us" />
      <Route path="/solutions" element={<Solutions />} title="Solutions" />
      <Route path="/pricing" element={<Pricing />} title="Pricing" />
      <Route path="/plansom-partner" element={<BecomingPlansomPartner />} title="Becoming Plansom Partner" />
      <Route path="/contactUs-support" element={<ContacUsAndSupport />} title="Contact Us and Support" />
      <Route path="/unsubscribe-mail" element={<UnsubscribeMail />} title="Unsubscribe Mail" />
      <Route path="/product/:id" element={<ProductPage />} />
      <Route path="/category-page/:id" element={<CategoryPage />} />
    </Routes>
  );


  if (props.isAuthenticated) {
    console.log("Index Route")
    return (
      <>


        <Suspense
          fallback={
            <div id="preloader">
              <div className="sk-three-bounce">
                <div className="sk-child sk-bounce1"></div>
                <div className="sk-child sk-bounce2"></div>
                <div className="sk-child sk-bounce3"></div>
              </div>
            </div>
          }
        >
          <Index />
        </Suspense>

      </>
    );
  } else {
    console.log("Index else Route")
    return (
      <div
        id="main-wrapper"
        data-testid="index-page"
        className="show"
        style={
          location.pathname === "/store-front"
            ? {
              overflow: "visible",
            }
            : {}
        }
      >
        <div data-testid="main-app">
          <Suspense
            fallback={
              <div id="preloader" data-testid="preloader">
                <div className="sk-three-bounce" data-testid="three-bounce">
                  <div
                    data-testid="bounce"
                    className="sk-child sk-bounce1"
                  ></div>
                  <div
                    data-testid="bounce"
                    className="sk-child sk-bounce2"
                  ></div>
                  <div
                    data-testid="bounce"
                    className="sk-child sk-bounce3"
                  ></div>
                </div>
              </div>
            }
          >
            {routes}
          </Suspense>
        </div>
      </div>
    );
  }
}

// export default App;
const mapStateToProps = (state) => {
  console.log("Index 111111111111111")
  return {
    isAuthenticated: isAuthenticated(state),
  };
};
export default connect(mapStateToProps)(App);
