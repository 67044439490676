import React, { Component } from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

import { Row, Col, Card } from "react-bootstrap";
import RedialApex from "./RedialApex";
import vector from "../../../images/svg/Vector.svg";
import vectorrs from "../../../images/svg/vectorrs.svg"
import Donut from "./Donut";
import Pagination from "../../components/Pagination/Pagination";
import { getAllBarChartData } from "../../../services/OrganizationService";
import LoadingSpinner from "../Component/LoadingSpinner";
import { CircularProgress } from "@material-ui/core";


ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

class MyDayBarChartObjective extends Component {
  constructor(props) {
    super(props);
    this.state = {
      labels: [],
      startDate: "",
      endDate: "",
      seriesData: [],
      lableee: [],
      pagination: false,
      pageCount: 1,
      goToPage: null,
      loading: false,
      circularLoader: false,
    };
    this.userDetail = localStorage.getItem("userDetails");
    this.userInfoDetails = JSON.parse(this.userDetail);
  }
  componentDidMount() {
    this.getGraph();
  }

  getGraph = (goPage = 0) => {
    this.setState({ circularLoader: true });
    const data = getAllBarChartData(this.userInfoDetails?.data?.accessToken);
    data?.then(async (res) => {
      console.log("resssssssssssssssss", res.data.data);
      this.setState({ circularLoader: false });
      this.setState(() => ({
        goToPage: goPage,
        pageCount: Math.ceil(res?.data?.count / 10),
        pagination: res?.data?.next || res?.data?.previous ? true : false,
        labels: res?.data?.data,
      }));
    });
    data?.catch(async (err) => {
      console.log(err, "HEEEEErrrrrr");
    });
  }

  // componentWillReceiveProps(newProps) {
  //   if (newProps?.scope === "custom") {
  //     setTimeout(() => {
  //       this.getGraph(this.state?.goToPage);
  //     }, 1000);
  //   }
  //   else {
  //     setTimeout(() => {
  //       // if (this.state?.goToPage === 0) {
  //       this.getGraph(this.state?.goToPage);
  //       // }
  //     }, 2000);

  //   }
  // }

  ChangePage = (pageNo) => {
    this.setState(() => ({ ...this.state, goToPage: pageNo }));
    this.getGraph(pageNo);
  }

  render() {
    const graphData = this.state?.labels;

    const labels = [];
    const values = [];
    const prediction = [];

    graphData?.forEach((element) => {
      labels.push(element.day);
      values.push(element.value);
      prediction.push(element?.prediction?.weeks);
    });

    const getColorForPercentage = (percentage) => {
      if (percentage <= 25) {
        return "#F44336"; // Red for 0-25%
      } else if (percentage <= 50) {
        return "#FF9800"; // Orange for 26-50%
      } else if (percentage <= 75) {
        return "#FFEB3B"; // Yellow for 51-75%
      } else if (percentage <= 99) {
        return "#8BC34A"; // Light Green for 76-99%
      } else {
        return "#4CAF50"; // Green for 100%
      }
    };

    // Map over the values array to generate background and border colors for each bar
    const backgroundColors = values.map((value) => getColorForPercentage(value));
    const hoverBackgroundColors = values.map((value) => getColorForPercentage(value));
    const borderColors = values.map((value) => getColorForPercentage(value));

    const screenWidth = window.innerWidth;
    const barThickness = screenWidth >= 1020 ? 70 : 30;

    const data = {
      labels: labels,
      datasets: [
        {
          label: "Completion percentage",
          data: values,
          borderColor: borderColors,
          borderWidth: 0,  // No need to use a string here, just 0
          backgroundColor: backgroundColors,
          hoverBackgroundColor: hoverBackgroundColors,
          barThickness: barThickness,
        },
      ],
    };

    const options = {
      plugins: {
        legend: false,
      },
      scales: {
        y: {
          min: 0,
          max: 100,
        },
        x: {
          barPercentage: 1,
        },
      },
      responsive: true,
    };
    return (
      <>
        <Col xl={6} lg={6}>
          <Card style={{ minHeight: "300px" }}>
            {this.state.circularLoader ? <>
              <div className="p-0 m-0 text-center" style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%", minHeight: "50%" }}>
                <CircularProgress />
              </div>
            </> : <>
              <Card.Header>
                <h4>  
                  Daily Tasks Completion
                </h4>
                <div className="text-end">
                </div>
              </Card.Header>
              <Card.Body>

                <div className="mb-2" style={{height:"100%"}}>
                  {labels.length !== 0 ? (<Bar
                    data={data}
                    height={100}
                    options={options}
                    className="h-100 w-100 px-1 ps-2"
                  />
                  ) : (
                    <span className="text-center"> No data available</span>
                  )}
                  <div className=" border-0 mt-4">
                    {this.state.pagination && (
                      <Pagination
                        pageCount={this.state.pageCount}
                        pageIndex={this.state.goToPage}
                        gotoPage={this.ChangePage}
                      />
                    )}
                  </div>
                </div>
              </Card.Body>
            </>}
          </Card>
        </Col>
      </>
    );
  }
}

export default MyDayBarChartObjective;
