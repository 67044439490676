import React, { useState, useEffect, memo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import ChatRoom from "../Messages/ChatRoom";
import profilePicture from "../../../../src/images/profile/profilePic.png";
import WebSocketInstance from "../../services/WebSocket";
import {
  getAllCountByOrg,
  getAllThreadRoomById,
  readAllMessageByRoomId,
} from "../../../services/messageService";
import { useLocation, useNavigate } from "react-router-dom";
import xeroImage from "../../../images/zero_state_chats_1x.png";
import plogo from "../../../images/PlansomLogo2.png";
import { readNotificationConfirmAction } from "../../../store/actions/NotificationAction";
import CreateThread from "../Component/CreateThread";
import {
  getThreadList
} from "../../../store/actions/NotificationAction";
import { ToastContainer, toast } from "react-toastify";
// import { useNavigate } from "react-router-dom";
import config from "../../config";
import { gettingAllThreadByWebsocket } from "../../../store/actions/TaskActions";
import { RiChatNewLine } from "react-icons/ri";


const Messages = () => {
  const stateData = useSelector((state) => state);
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userDetails = localStorage.getItem("userDetails");
  const userInfoDetails = JSON.parse(userDetails);
  const orgId = localStorage.getItem("organization_id");
  const [orgMember, setOrgMember] = useState([]);
  const [UnreadMessages, setUnreadMessages] = useState();

  const notificationRoom = {
    thread_topic: "Plansom",
    thread_type: "notification",
    unread_messages: UnreadMessages?.notification_counter,
    thread_id: "0000-0000-0000-0000",
  };

  const [selectedUser, setSelectedUser] = useState(
    localStorage.getItem("ActiveChat") === "undefined" || localStorage.getItem("ActiveChat") === null
      ? notificationRoom
      : JSON.parse(localStorage.getItem("ActiveChat"))
  );
  const [selectedMessage, setSelectedMessage] = useState(
    location.state?.message
  );
  const [indexs, setindex] = useState();
  const [model, setModel] = useState(false);
  const [compData, setCompData] = useState("");

  const [isupdate, setUpdate] = useState(false);
  const [showTableSpinner, setShowTableSpinner] = useState(true);
  const [PlansomRoomState, setPlansomRoomState] = useState(true);
  const [checkModal, setCheckModal] = useState(false);

  const scrollRef = useRef(false);
  const [loadThread, setLoadThread] = useState(false);
  const [notificationSocket, setNotificationSocket] = useState(null);

  const path = config.API_PATH;

  console.log(selectedUser, "selectedUserselectedUserselectedUser")

  console.log(stateData?.task?.gettingAllThreadStatus, "Testtting purpose thread created")



  useEffect(() => {
    setUnreadMessages(UnreadMessages);
  }, [UnreadMessages]);

  useEffect(() => {
    if (localStorage.getItem('previousLocation') === '/stripe' && sessionStorage.getItem("strategyImported") === "false") {
      toast.info(
        <div className="m-1">
          <h3 >Payment Pending for Strategy </h3>

        </div>
        ,
        { toastId: 'strategyPaymentFail' })
      sessionStorage.removeItem('strategyImported')
    }

    if (localStorage.getItem("ActiveChat") === "undefined" || localStorage.getItem("ActiveChat") === null)
      localStorage.setItem("ActiveChat", JSON.stringify(notificationRoom));
  }, []);

  const onScroll = () => {
    const { scrollTop, scrollHeight, clientHeight } = scrollRef.current;
    if (scrollTop + clientHeight === scrollHeight) {
      loadThread && getAllThreadRoomByIdData(true);
    }
  };
  useEffect(() => {
    if (selectedUser?.thread_id !== location.state?.selectedUser?.thread_id) {
      SelectedUser(selectedUser);
    } else {
      setSelectedUser(() => selectedUser);
      localStorage.setItem("ActiveChat", JSON.stringify(selectedUser));

    }
  }, [selectedUser]);

  useEffect(() => {
    setPlansomRoomState(PlansomRoomState);
  }, [PlansomRoomState]);

  useEffect(() => {
    setOrgMember(() => orgMember);
  }, [orgMember]);

  useEffect(() => {
    if (!model) {
      if (stateData?.messages?.threadList?.data?.message === "Chat thread found successfully") {
        receivedThreads(stateData?.messages?.threadList)
      } else if (stateData?.messages?.threadList === undefined || stateData?.messages?.threadList.length === undefined) {
        getAllThreadRoomByIdData();
      }
      setModel(() => model);
    }
  }, [model, stateData]);

  useEffect(() => {
    if (location.state?.selectedUser?.thread_id) {
      if (location?.state?.message) {
        if (stateData?.messages?.threadList) {
          if (location?.state?.selectedUser?.thread_id === JSON.parse(localStorage.getItem("ActiveChat"))?.thread_id) {
            getAllThreadRoomByIdData()
          } else {
            receivedThreads(stateData?.messages?.threadList)
          }
        } else {
          getAllThreadRoomByIdData()
        }
      }
      if (stateData?.messages?.threadList === undefined || stateData?.messages?.threadList === null) {
        getAllThreadRoomByIdData();
      }
    }
  }, [location.state?.selectedUser]);


  useEffect(() => {
    if (stateData?.task?.gettingAllThreadStatus == true) {
      // getAllThreadRoomByIdData();
      getMessagingList()
      dispatch(gettingAllThreadByWebsocket(false))
    }

  }, [stateData?.task?.gettingAllThreadStatus])



  useEffect(() => {
    setSelectedUser(selectedUser);
    if (
      selectedUser?.thread_id !== undefined &&
      selectedUser?.thread_id !== "0000-0000-0000-0000" &&
      selectedUser?.room_type !== "notification"
    ) {
      localStorage.setItem("ActiveChat", JSON.stringify(selectedUser));
      if (WebSocketInstance?.socketRef?.url.split("/")[5] !== selectedUser?.thread_id)
        if (location?.state?.selectedUser?.thread_id) {
          WebSocketInstance.connect(
            location?.state?.selectedUser?.thread_id,
            JSON.parse(localStorage?.getItem("userDetails")).data
          );
        } else {
          WebSocketInstance.connect(
            selectedUser?.thread_id,
            JSON.parse(localStorage?.getItem("userDetails")).data
          );
        }
      setindex(() => indexs);
    }
  }, [selectedUser?.thread_id]);

  async function getAllThreadRoomByIdData(text = false) {
    const start = text ? orgMember?.length : 0;
    const end = text ? orgMember?.length + 10 : 10;
    if (stateData?.messages?.threadList === undefined || stateData?.messages?.threadList.length === undefined) {
      const data = getAllThreadRoomById(
        localStorage.getItem("organization_id"),
        start,
        end,
        userInfoDetails?.data?.accessToken
      );
      data?.then(
        await ((res) => {
          console.log("list API Calling...", res)
          setLoadThread(res?.data?.data?.length < 10 ? true : false)
          receivedThreads(res, text)
        })
      );
      data?.catch((err) => {
        // console.log("error ucomponentDidMountser org list");
      });
    } else if (stateData?.messages?.threadList?.data?.message === "Chat thread found successfully") {
      receivedThreads(stateData?.messages?.threadList)
    }

  }

  const receivedThreads = (res, text) => {
    if (orgMember?.length > 12) {
      setLoadThread(false);
    } else {
      setLoadThread(true);
    }
    setShowTableSpinner(() => false);
    let memberData = text ? orgMember : [];
    res?.data?.data.forEach((element) => {
      let addData = true;
      memberData.forEach((ele) => {
        if (ele?.thread_id === element?.thread_id) {
          addData = false;
        }
      });
      addData && memberData.push(element);
    });
    setOrgMember(() => memberData);
    res?.data?.data?.map((element, index) =>
      element.plansom
        ? setPlansomRoomState(() => false)
        : setPlansomRoomState(() => true)
    );
    if (location.state?.selectedUser?.thread_id) {
      res?.data?.data?.forEach((element, index) => {
        if (
          location.state?.selectedUser?.thread_id === element?.thread_id
        ) {
          setSelectedMessage(location.state?.message);
          SelectedUser(element);
          setindex(index);
          localStorage.setItem("ActiveChat", JSON.stringify(element));
          setTimeout(() => {
            setSelectedMessage("");
            navigate("/messages", { state: {} });
          }, 1000);
        }
      });
      if (
        JSON.parse(localStorage.getItem("ActiveChat"))?.thread_id !==
        location.state?.selectedUser?.thread_id
      ) {
        getMessagingList();
        getAllThreadRoomByIdData(true);
        return;
      }
    } else if (
      JSON.parse(localStorage.getItem("ActiveChat"))?.thread_id
      !==
      "0000-0000-0000-0000"
    ) {
      res?.data?.data?.forEach((element) => {
        if (
          JSON.parse(localStorage.getItem("ActiveChat"))?.thread_id ===
          element?.thread_id && (JSON.parse(localStorage.getItem("ActiveChat"))?.thread_id !== undefined && element?.thread_id !== undefined)
        ) {
          SelectedUser(element);
          localStorage.setItem(
            "ActiveChat",
            JSON.stringify(JSON.parse(localStorage.getItem("ActiveChat")))
          );
        }
      });
    } else {
      setSelectedUser(() => notificationRoom);
      localStorage.setItem("ActiveChat", JSON.stringify(notificationRoom));
    }
  }
  useEffect(() => {
    if (isupdate && compData?.length !== 0) {
      if (compData === "delete") {
        localStorage.removeItem("ActiveChat");
        setSelectedUser(() => notificationRoom);
        localStorage.setItem("ActiveChat", JSON.stringify(notificationRoom));
        setCompData(() => "");
      }
      if (compData === "member") {
        setCompData(() => "");
      }
      getMessagesByOrg();
      const resp = readAllMessageByRoomId(
        selectedUser?.thread_id,
        userInfoDetails.data.accessToken
      );
      resp?.then((res) => {
        dispatch(readNotificationConfirmAction([]));
        getMessagingList();
        getAllThreadRoomByIdData();
      })
      resp?.catch(() => { })
    }

    if (compData === "update" && selectedUser?.thread_id === "0000-0000-0000-0000") {
      dispatch(readNotificationConfirmAction([]));
      getMessagingList();
      getAllThreadRoomByIdData();
    }
    setUpdate(() => false);
  }, [isupdate]);

  useEffect(() => {
    getMessagesByOrg();
    getAllThreadRoomByIdData();
  }, [])

  const isUpdate = (data) => {
    setCompData(data);
    setUpdate(true);
  };

  const SelectedUser = (element) => {
    if (selectedUser?.thread_id !== element?.thread_id) {
      setSelectedUser(element);
      if (selectedUser !== element) {
        if (localStorage.getItem("ActiveChat") !== "null") {
          if (
            location.state?.selectedUser?.thread_id === element?.thread_id
          ) {
            // console.log('selectedUser55 10', element, location?.state?.selectedUser?.thread_id)
          } else {
            WebSocketInstance.close();
          }

        }
        setSelectedUser(element);
        localStorage.setItem("ActiveChat", JSON.stringify(element));
        if (element.thread_type !== "notification") {
          setUpdate(!isupdate);
        } else {
          setSelectedUser(element);
          localStorage.setItem("ActiveChat", JSON.stringify(notificationRoom));
        }
      }
    }
    else {
      // console.log('selectedUser55 6', element)
      setSelectedUser(element);
      localStorage.setItem("ActiveChat", JSON.stringify(element));
    }
  };

  async function getMessagesByOrg() {
    const data = getAllCountByOrg(orgId, userInfoDetails?.data?.accessToken);
    data?.then(
      await ((res) => {
        setUnreadMessages(() => res?.data?.data);
      })
    );
    data?.catch((err) => { });

  }
  // const navigateC = useNavigate()
  const ClickOnForward = () => {
    setCheckModal(false)
  }

  const ClickOnBack = () => {
    if (localStorage.getItem("gotoChatkey") === "false") {
      navigate(localStorage.getItem("previousLocation"));
      localStorage.setItem("gotoChatkey", false);
    } else if (
      selectedUser?.task &&
      localStorage.getItem("gotoChatkey") === "true"
    ) {
      navigate("/tasks", { state: { task: selectedUser?.task } });
      localStorage.setItem("gotoChatkey", false);
    } else if (
      selectedUser?.goal &&
      localStorage.getItem("gotoChatkey") === "true"
    ) {
      navigate("/goals", { state: { goal: selectedUser?.goal, key: true } });
      localStorage.setItem("gotoChatkey", false);
    }
  };

  const getMessagingList = () => {
    const start = 0;
    const end = 10;
    dispatch(getThreadList(
      localStorage.getItem("organization_id"),
      start,
      end,
      userInfoDetails.data.accessToken
    ));
    // localStorage.removeItem("ActiveChat");
  }

  // const connectToNotificationWebSocket = () => {
  //   const userData = JSON.parse(localStorage.getItem('user'));
  //   const senderIds = userData.id;
  //   const notificationUrl = `ws://stagingbe.plansom.com/ws/notifications/${JSON.parse(localStorage?.getItem("userDetails")).data?.id}`;
  //   const socket = new WebSocket(notificationUrl);

  //   socket.onopen = () => {
  //     console.log('Connected to notification WebSocket');
  //   };

  //   socket.onmessage = (event) => {
  //     const msg = JSON.parse(event.data);
  //     console.log("Notification section.................")
  //     console.log(msg);
  //     if (msg.type === 'notify') {
  //       handleNewMessageNotification(msg, msg.thread, JSON.parse(localStorage?.getItem("userDetails")).data?.id);
  //     }
  //   };

  //   socket.onerror = (error) => {
  //     console.error('Notification WebSocket error:', error);
  //   };

  //   socket.onclose = (event) => {
  //     console.log('Notification WebSocket closed:', event);
  //   };

  //   setNotificationSocket(socket);
  // };


  // const handleNewMessageNotification = (msg, threadId, senderIds) => {
  //   console.log("HANDLING..............", msg, threadId, senderIds)
  //   // Check if the message has been notified already
  //   // if (lastNotificationMessageIds[threadId]?.includes(msg.message.id)) {
  //   //   return;
  //   // }
  //   console.log("%%%%%%%%%%%%%%%%%%%%")
  //   // Update the state to include the new message ID
  //   // setLastNotificationMessageIds(prev => ({
  //   //   ...prev,
  //   //   [threadId]: [...(prev[threadId] || []), msg.message.id],
  //   // }));
  //   console.log("*******************")
  //   if (Notification.permission === 'granted' && JSON.parse(localStorage?.getItem("userDetails")).data?.id !== msg.sender_id) {
  //     // const thread = threads.find(t => t.thread_id === threadId);
  //     const title = `New message in ${msg?.thread_topic || 'Unknown Thread'} from ${msg?.sender_name || ''}`;

  //     const body = msg.message;
  //     console.log("Message title:::", title)
  //     console.log("Creating notification:", title, body);
  //     new Notification(title, { body });

  //     // Increment unread message count
  //     // setUnreadCounts(prevCounts => ({
  //     //   ...prevCounts,
  //     //   [threadId]: (prevCounts[threadId] || -1) + 1,
  //     // }));
  //   }
  // };

  // // if (userInfoDetails) {
  // //   // fetchThreads();
  // //   connectToNotificationWebSocket();
  // // }

  // useEffect(()=>{
  //   connectToNotificationWebSocket();
  // },[])


  // Connect to the WebSocket for notifications
  // const connectToNotificationWebSocket = () => {
  //   const userData = JSON.parse(localStorage.getItem('user'));
  //   const senderIds = userInfoDetails?.data?.id;
  //   const notificationUrl = `${path}${senderIds}`;
  //   const socket = new WebSocket(notificationUrl);

  //   socket.onopen = () => {
  //     console.log('Connected to notification WebSocket');
  //   };

  //   socket.onmessage = (event) => {
  //     const msg = JSON.parse(event.data);
  //     console.log("Notification received:", msg);
  //     if (msg.type === 'notify') {
  //       handleNewMessageNotification(msg, msg.thread, senderIds);
  //     }
  //   };

  //   socket.onerror = (error) => {
  //     console.error('Notification WebSocket error:', error);
  //   };

  //   socket.onclose = (event) => {
  //     console.log('Notification WebSocket closed:', event);
  //   };

  //   setNotificationSocket(socket);
  // };

  // // Handle incoming notification messages
  // const handleNewMessageNotification = (msg, threadId, senderIds) => {
  //   console.log("Handling notification:", msg, threadId, senderIds);

  //   if (Notification.permission === 'granted' && senderIds !== msg.sender_id) {
  //     const title = `New message in ${msg?.thread_topic || 'Unknown Thread'} from ${msg?.sender_name || ''}`;
  //     const body = msg.message;
  //     console.log("Creating notification:", title, body);
  //     new Notification(title, { body });
  //   }
  // };

  // // Request notification permission if not already granted
  // useEffect(() => {
  //   if (Notification.permission !== 'granted') {
  //     Notification.requestPermission().then(permission => {
  //       if (permission === 'granted') {
  //         console.log('Notification permission granted');
  //       }
  //     });
  //   }

  //   connectToNotificationWebSocket();

  //   return () => {
  //     // Cleanup: close the WebSocket on component unmount
  //     if (notificationSocket) {
  //       console.log("hiiiiiiiiiiiiiirrrrrrrrrr")
  //       notificationSocket.close();
  //     }
  //   };
  // }, []);

  // useEffect(()=>{
  //   connectToNotificationWebSocket();

  // },[])

  return (
    <>
      {model && <CreateThread model={model} setModal={setModel} />}
      <div
        className='row group-list '>
        {localStorage.getItem("previousLocation") !==
          localStorage.getItem("currentLocation") && (
            <span className="">
              {!window.innerWidth <= 574 && !checkModal ? (
                <i
                  className="fa fa-reply fs-20"
                  title={
                    !localStorage.getItem("gotoChatkey")
                      ? `Navigate to ${localStorage.getItem("previousLocation").split("/")[1]}`
                      : selectedUser?.task
                        ? "Navigate back to tasks"
                        : "Navigate back to goals"
                  }
                  style={{ cursor: "pointer" }}
                  onClick={ClickOnBack}
                />
              ) : (
                <i
                  className="fa fa-reply fs-20"
                  style={{ cursor: "pointer" }}
                  onClick={ClickOnForward}  // Assuming you have a different function for forward navigation
                />
              )}
            </span>

          )}
        <div className="card mb-2 message-card h-100">
          <div className="card-body py-3 px-0">
            {showTableSpinner ? (
              <center data-testid="loading-spinner">
                <div style={{ padding: "20px", margin: "300px" }}>
                  <center>
                    <div style={{ padding: "20px" }}></div>
                    <div
                      className="loading-dots--dot mx-2"
                      style={{
                        height: "25px",
                        width: "25px",
                        borderRadius: "50%",
                      }}
                    ></div>
                    <div
                      className="loading-dots--dot"
                      style={{
                        height: "25px",
                        width: "25px",
                        borderRadius: "50%",
                      }}
                    ></div>
                    <div
                      className="loading-dots--dot mx-2"
                      style={{
                        height: "25px",
                        width: "25px",
                        borderRadius: "50%",
                      }}
                    ></div>
                  </center>
                </div>
              </center>
            ) : (
              <div className="row p-0 m-0">
                <div className="col-sm-3 p-0 m-0" style={{ display: checkModal ? 'none' : '' }}>
                  <div className="chat-sidebar px-3 m-0 pr-0" id="chatSidebar">
                    <div className="recent-msg  chat-bx py-0">
                      <div className="d-flex justify-content-between align-items-center py-0">
                        <span className="h2 mx-1">Messages</span>
                        <div className="d-flex justify-content-between align-items-center">
                          {/* <Link
                            to={"#"}
                            data-testid="boot-icon"
                            onClick={(e) => {
                              e.preventDefault();
                              setModel(true);
                            }}
                          >
                            <span
                              id="boot-icon"
                              className="bi bi bi-plus me-3"
                              style={{
                                fontSize: "1.5rem",
                                color: "#356fd4",
                                border: "2px solid #356fd4",
                                borderRadius: "5px",
                                padding: "3px 5px",
                              }}
                            ></span>
                          </Link> */}
                          <RiChatNewLine onClick={(e) => {
                            e.preventDefault();
                            setModel(true);
                          }}
                            title="New thread"
                            color={"#356fd4"} size={24} cursor={"pointer"} />
                        </div>
                      </div>
                    </div>
                    <PerfectScrollbar
                      className="dlab-scroll mt-3"
                      style={{ height: "63vh" }}
                      containerRef={(el) => {
                        scrollRef.current = el;
                      }}
                      onScroll={() => onScroll()}
                      option={{ suppressScrollX: true }}
                    >
                      <div className="">
                        {orgMember.length === 0 && PlansomRoomState && (
                          <>
                            <div
                              className="chat-bx  border-bottom"
                              style={{ cursor: "pointer" }}
                              key={-1}
                            >
                              <div
                                className="px-1 d-flex"
                                key={-1}
                                onClick={(e) => {
                                  selectedUser?.thread_id !== "" &&
                                    SelectedUser(notificationRoom);
                                  setindex(-1);
                                  localStorage.setItem(
                                    "ActiveChat",
                                    JSON.stringify(notificationRoom)
                                  );
                                }}
                              >
                                <div className="chat-img">
                                  <img src={plogo} alt="chat-img" title="chat-img" className="pb-1" />
                                </div>
                                <div className="w-100">
                                  <div className="d-flex mb-1 align-items-center">
                                    <span className="h4 ms-2 pt-2 ">
                                      {notificationRoom.thread_topic}
                                    </span>
                                  </div>
                                </div>
                                <Link to={"#"} className="p-0 m-0">
                                  <span className="fs-12 ms-auto badge badge-secondary">
                                    {UnreadMessages?.notification_counter || ""}
                                  </span>
                                </Link>
                              </div>
                            </div>
                          </>
                        )}

                        {orgMember?.map((element, index) => (
                          <>
                            {element.plansom ? (
                              <div className="border-bottom" index>
                                <div
                                  className="chat-bx my-1"
                                  style={
                                    selectedUser?.thread_topic !== "Plansom"
                                      ? { cursor: "pointer" }
                                      : {
                                        backgroundColor: "#F3F4F6",
                                        borderRadius: "10px",
                                      }
                                  }
                                  key={index}
                                >
                                  <div
                                    className="px-1 d-flex"
                                    key={index}
                                    onClick={(e) => {
                                      selectedUser?.thread_id !== "" &&
                                        SelectedUser(notificationRoom);
                                      setindex(index);
                                      localStorage.setItem(
                                        "ActiveChat",
                                        JSON.stringify(notificationRoom)
                                      );
                                      setCheckModal(window.innerWidth <= 574)
                                    }}
                                  >
                                    <div className="chat-img">
                                      <img
                                        src={plogo}
                                        alt="chat-img"
                                        title="chat-img"
                                        className="pb-2"
                                      />
                                    </div>
                                    <div className="w-100">
                                      <div className="d-flex mb-1 align-items-center pt-2">
                                        <span
                                          className={
                                            selectedUser?.thread_id !==
                                              element?.thread_id
                                              ? "h4 ms-2 "
                                              : ""
                                          }
                                        >
                                          <span
                                            style={
                                              selectedUser?.thread_topic !==
                                                "Plansom"
                                                ? { fontWeight: 500 }
                                                : {}
                                            }
                                          >
                                            {notificationRoom.thread_topic}
                                          </span>
                                        </span>
                                      </div>
                                    </div>
                                    <Link to={"#"} className="p-0 m-0">
                                      <span className="fs-12 ms-auto badge badge-secondary">
                                        {notificationRoom.unread_messages !== 0
                                          ? notificationRoom.unread_messages
                                          : ""}
                                      </span>
                                    </Link>
                                  </div>
                                </div>
                              </div>
                            ) : (
                              <div className="border-bottom" index>
                                <div
                                  className="chat-bx my-1"
                                  style={
                                    selectedUser?.thread_id !==
                                      element?.thread_id
                                      ? { cursor: "pointer" }
                                      : {
                                        backgroundColor: "#F3F4F6",
                                        borderRadius: "10px",
                                      }
                                  }
                                  key={index}
                                >
                                  <div
                                    className="px-1 d-flex"
                                    key={index}
                                    onClick={(e) => {
                                      selectedUser?.thread_id !==
                                        element?.thread_id &&
                                        SelectedUser(element);
                                      setindex(index);
                                      setCheckModal(window.innerWidth <= 574)
                                    }}
                                  >
                                    <div
                                      className="d-flex m-0"
                                      style={{ maxWidth: "70px" }}
                                    >
                                      <div className="chat-img">
                                        {element?.thread_type === "personal" ? (
                                          <img
                                            src={element?.thread_image
                                              ? element?.thread_image
                                              : element?.initiator?.profile_picture
                                                ? element?.initiator?.profile_picture
                                                : profilePicture}
                                            alt="chat-img"
                                            title="chat-img"
                                            className="plansom-notification-img"
                                            style={{
                                              height: "50px",
                                              width: "50px",
                                              // padding: "2px",
                                              cursor: "pointer",
                                              // maxWidth: "100%", // Make the image responsive
                                            }}
                                          />
                                        ) : element?.thread_image ? (
                                          <img
                                            src={element?.thread_image}
                                            alt="thread-img"
                                            title="thread-img"
                                            className="plansom-notification-img"
                                            style={{
                                              height: "50px",
                                              width: "50px",
                                              // padding: "2px",
                                              cursor: "pointer",
                                              // maxWidth: "100%", // Make the image responsive
                                            }}
                                          />
                                        ) : element?.initiator?.profile_picture ? (
                                          <img
                                            src={element?.initiator?.profile_picture}
                                            alt="thread-img"
                                            title="thread-img"
                                            className="plansom-notification-img"
                                            style={{
                                              height: "50px",
                                              width: "50px",
                                              // padding: "2px",
                                              cursor: "pointer",
                                              // maxWidth: "100%", // Make the image responsive
                                            }}
                                          />
                                        ) : (
                                          <i
                                            className="fa fa-users"
                                            aria-hidden="true"
                                            style={{
                                              cursor: "pointer",
                                              fontSize: "30px",
                                              color: "#878484",
                                            }}
                                          ></i>
                                        )}
                                      </div>

                                      <span
                                        className="chat-img mt-4"
                                        style={{ left: "-30px" }}
                                      ></span>
                                    </div>
                                    <div className="w-100">
                                      <div className="d-flex mb-1 align-items-center">
                                        <span className="h4 ms-2 topic-name">
                                          <span
                                            style={
                                              selectedUser?.thread_id !==
                                                element?.thread_id
                                                ? { fontWeight: "500" }
                                                : {}
                                            }
                                          >
                                            {element?.thread_topic && (element?.thread_topic.length > 15 && window.screen.width <= 768 ? `${element?.thread_topic.substring(0, 15)}...` : element?.thread_topic)}

                                            {/* {element?.thread_topic.length > 15 && window.screen.width<=768 ? `${element?.thread_topic.substring(0, 15)}...` : element?.thread_topic} */}
                                            {/* {element?.thread_topic.substring(0, 15)}...`  */}
                                          </span>
                                        </span>
                                        {element?.thread_type === "group" && (
                                          <span
                                            className="float-end ms-auto"
                                            style={{ color: "#bfbfbf" }}
                                          >
                                            <i
                                              className="fa fa-users"
                                              aria-hidden="true"
                                            ></i>
                                          </span>
                                        )}
                                        <span className="fs-12 ms-1 badge badge-secondary">
                                          {element?.unread_messages !== 0
                                            ? element?.unread_messages
                                            : ""}
                                        </span>
                                      </div>
                                      {typeof element?.last_message ===
                                        "string" ? (
                                        <div className="d-flex px-2">
                                          <img
                                            src={
                                              element?.last_message_sender
                                                ?.profile_picture ||
                                              profilePicture
                                            }
                                            alt="last-msg"
                                            title="last-msg"
                                            style={{
                                              height: "25px",
                                              width: "25px",
                                              padding: "2px",
                                              cursor: "pointer",
                                              borderRadius: "50%",
                                            }}
                                          />
                                          <span className="p fs-12 font-w200 pt-1">
                                            {element?.last_message?.substr(
                                              0,
                                              25
                                            ) + "  ..."}
                                          </span>
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                          </>
                        ))}
                      </div>
                    </PerfectScrollbar>
                  </div>
                </div>
                {selectedUser ? (
                  <div
                    className={`col-sm-9 ${window.innerWidth <= 574 && !checkModal ? 'd-none' : ''} chat-body`}
                    style={{ height: "100%" }}
                    key={indexs}
                  >
                    <ChatRoom
                      UnreadMessages={UnreadMessages}
                      user={selectedUser}
                      message={selectedMessage}
                      handler={isUpdate}
                    />
                  </div>

                ) : (
                  <div className="col-sm-9">
                    <div className="col text-center mt-5 pt-5">
                      <img
                        src={xeroImage}
                        alt="conversation"
                        title="conversation"
                        style={{
                          backgroundSize: "250px",
                          height: "250px",
                          width: "250px",
                          opacity: "0.7",
                        }}
                      />
                      <h3>Choose a conversation</h3>
                      <span>
                        Click on an existing chat or click
                        <u>
                          <Link
                            style={{ cursor: "pointer", color: "red" }}
                            to={""}
                            onClick={() => {
                              setModel(() => true);
                            }}
                          >
                            here
                          </Link>
                        </u>
                        to create a new conversation.
                      </span>
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default memo(Messages);






















