import { Box, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Col, Card } from 'react-bootstrap';
import { VscFlame } from "react-icons/vsc";
import { FaCheckCircle } from "react-icons/fa";
import { MdOutlineRadioButtonUnchecked } from "react-icons/md";
import { getAllStreaks } from '../../../services/TaskService';

const Streaks = () => {
  const userDetails = localStorage.getItem("userDetails");
  const userInfoDetails = JSON.parse(userDetails);
  const [allStreaks, setAllStreaks] = useState([]);

  const getAllStreaksList = () => {
    const data = getAllStreaks(userInfoDetails.data.accessToken);
    data?.then((res) => {
      setAllStreaks(res?.data?.data);
    });
    data?.catch((err) => { });
  }

  useEffect(() => {
    getAllStreaksList();
  }, []);

  return (
    <Col xl={3} lg={6} md={12} >
      <Card data-testid="line-charts" style={{ minHeight: "350px" }}>
        <Card.Header>
          <h4 className="card-title">Quick Win Streak!</h4>
        </Card.Header>
        <Card.Body>
          <Box display="flex" justifyContent="center" alignItems="center" mb={2}>
            <VscFlame size={90} color="#FF7518" />
          </Box>
          <Box display="flex" justifyContent="space-between" alignItems="center" mt={5}>
            {allStreaks.map((day, index) => (
              <Box key={index} display="flex" flexDirection="column" alignItems="center">
                <Typography>{day.day.charAt(0)}</Typography>
                {day.value ?
                  <FaCheckCircle color="green" size={20} />
                  :
                  <MdOutlineRadioButtonUnchecked size={20} />
                }
              </Box>
            ))}
          </Box>
          <Box display="flex" justifyContent="center" alignItems="center" mt={2}>
            <Typography align="center">
              Complete the quick win everyday to build your streak
            </Typography>
          </Box>
        </Card.Body>
      </Card>
    </Col>
  )
}

export default Streaks;
