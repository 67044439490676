import React from 'react';
import { FormControl, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { styled } from '@mui/system';

// Styled Radio component
const CircularRadio = styled(Radio)(({ theme }) => ({
    '&.Mui-checked': {
        color:"red", // Customize the checked color
    },
    '&:hover': {
        backgroundColor: 'transparent', // Remove hover effect background
    },
}));

const CircularRadioButtons = () => {
    const [value, setValue] = React.useState('option1');

    const handleChange = (event) => {
        setValue(event.target.value);
    };

    return (
        <FormControl component="fieldset">
            <RadioGroup value={value} onChange={handleChange}>
                <FormControlLabel
                    value="option1"
                    control={<CircularRadio />}
                    label="Option 1"
                />
                <FormControlLabel
                    value="option2"
                    control={<CircularRadio />}
                    label="Option 2"
                />
                <FormControlLabel
                    value="option3"
                    control={<CircularRadio />}
                    label="Option 3"
                />
            </RadioGroup>
        </FormControl>
    );
};

export default CircularRadioButtons;
