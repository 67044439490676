import React, { useState, useEffect } from "react";
import { Row, Col, Card, Tab, Nav, Modal, Dropdown, Button, Table } from "react-bootstrap";
import Time from "./Time";
import { lazy } from "react";
import { Suspense } from "react";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import DatePicker from "react-datepicker";

const TrackObjective = lazy(()=>import("./TrackObjective"))
const TrackOraganization = lazy(()=>import("./TrackOrganization"))
const TrackTeam = lazy(()=>import("./TrackTeam"))


const Track = () => {
    const [modalShow, setShowModel] = useState(false);
    const [activeTab, setActiveTab] = useState("Objective"); 
    const [timeScope, setTimeScope] = useState(localStorage.getItem("timescope"))
    const [selectedTimeScope, setSelectedTimeScope] = useState("1 month");
    const [customDateModal, getCustomDateModal] = useState(false);
    let date = new Date();
    let month = date.getMonth();
    const [startDate, setStartDate] = useState(date.setMonth(month - 1));
    const [endDate, setEndDate] = useState(new Date());

    const navigate = useNavigate()
    const location = useLocation()

    const tabData = [
        {
            name: "Objective",
            icon: "bi bi-bullseye h6",
        },
        // {
        //     name: "Team",
        //     icon: "user h6",
        // },
        {
            name: "Organization",
            icon: "fa fa-building-o h6",
        },
    ];

    const timeScopes = [
        {
          name: "1 day",
          value: "1 day",
        },
        {
          name: "7 days",
          value: "7 days",
        },
        {
          name: "14 days",
          value: "14 days",
        },
        {
          name: "1 month",
          value: "1 month",
        },
        {
          name: "Custom",
          value: "custom",
        },
      ];

    useEffect(()=>{
        const TScope = localStorage.getItem("timescope")
        setSelectedTimeScope(TScope)
    },[])  


    useEffect(()=>{
        setTimeScope(localStorage.getItem("timescope"))
    },[localStorage.getItem("timescope")])

    // useEffect(() => {
    //     setShowModel(true);
    // }, []);


    useEffect(() => {
        const customDatesInUse = localStorage.getItem("customDatesInUse");
        if (customDatesInUse === "true") {
          const storedStartDate = localStorage.getItem("startDate");
          const storedEndDate = localStorage.getItem("endDate");
          setStartDate(new Date(storedStartDate));
          setEndDate(new Date(storedEndDate));
        }
      }, []);

      function formatDate(date, key) {
        var d = new Date(date),
          month = "" + (d.getMonth() + 1),
          day = "" + d.getDate(),
          year = d.getFullYear();
    
        if (month.length < 2) month = "0" + month;
        if (day.length < 2) day = "0" + day;
    
        const formatedDate = [year, month, day].join("-");
        if (key === "start") {
          localStorage.setItem("startDate", formatedDate);
        } else {
          localStorage.setItem("endDate", formatedDate);
        }
      }  

      useEffect(() => {
        formatDate(startDate, "start");
        formatDate(endDate, "end");
      }, [startDate, endDate]);




    function submitStartEndDate() {
        localStorage.setItem("timescope", "custom");
        localStorage.setItem("noOfDays", "5 days");
    
        getCustomDateModal(false);
        navigate(location?.pathname, { state: { refresh: false } });
    }

    function handleTabSelect(tabName) {
        setActiveTab(tabName);
    }

    function cancelCustom() {
        getCustomDateModal(false);
      }


    const storeTimeScope = (timescope) => {
        setSelectedTimeScope(timescope.name);
        if (timescope.value === "custom") {
          getCustomDateModal(true);
          let date = new Date();
          let month = date.getMonth();
          setStartDate(date.setMonth(month - 1));
          setEndDate(new Date());
          localStorage.setItem("customDatesInUse", "true");
          // formatDate(startDate, "start");
          // formatDate(endDate, "end");
        } else {
          getCustomDateModal(false);
          localStorage.setItem("timescope", timescope.value);
          localStorage.removeItem("startDate");
          localStorage.removeItem("endDate");
          navigate(location?.pathname, { state: { refresh: true } });
        }
      };

    // function handleTabSelect(tabName) {
    //     console.log(tabName,"tabName")
    //     setActiveTab(tabName);
    //     setShowModel(false);
    // }

    return (
        <>
            {/* <Modal
                className="fade bd-example-modal-lg"
                show={modalShow}
                size="lg"
                data-testid="modal4"
            >
                <Modal.Body>
                    <button
                        variant=""
                        className="btn-close btn-danger float-end align-center m-1 p-2"
                        onClick={() => setShowModel(false)}
                    ></button>
                    <h2 className="text-center mb-4">Select what to track ? </h2>
                    <div className="mb-4">
                        <Tab.Container defaultActiveKey={activeTab.toLowerCase()}>
                            <Nav as="ul" className="nav-tabs">
                                {tabData.map((data, i) => (
                                    <Nav.Item as="li" key={i}>
                                        <Nav.Link
                                            eventKey={data?.name.toLowerCase()}
                                            onClick={() => handleTabSelect(data?.name)}
                                        >
                                           
                                            <i className={`la la-${data?.icon} me-2`} />
                                            <span className="fs-16">{data?.name}</span>
                                            
                                          
                                        </Nav.Link>
                                    </Nav.Item>
                                ))}
                            </Nav>
                        </Tab.Container>
                    </div>
                </Modal.Body>
            </Modal> */}

{/*            
                    <div className="default-tab">
                        <Tab.Container activeKey={activeTab.toLowerCase()}>
                        <Card style={{borderRadius:"0px 0px 0px 0px", marginBottom:"0px"}}>
                            
                <Card.Body>
                <h3 className="mb-4">Select what to track!</h3>
                            <Nav as="ul" className="nav-tabs">
                                {tabData.map((data, i) => (
                                    <Nav.Item as="li" key={i}>
                                      
                                        <Nav.Link
                                            eventKey={data?.name.toLowerCase()}
                                            onClick={() => handleTabSelect(data.name)}
                                        >
                                           
                                            <span className="fs-16">{data?.name}</span>
                                        </Nav.Link>
                                        
                                       
                                    </Nav.Item>
                                ))}
                            </Nav>
                            </Card.Body>
                                        </Card>
                            <Tab.Content className="">
                                {tabData.map((data, i) => (
                                    <Tab.Pane eventKey={data?.name.toLowerCase()} key={i}>
                                        <>
                                      
                                            {activeTab === "Objective" && <div><TrackObjective/></div>}
                                            {activeTab === "Team" && <div><TrackTeam/></div>}
                                            {activeTab === "Organization" && <div><TrackOraganization/></div>}
                                           
                                        </>
                                    </Tab.Pane>
                                ))}
                            </Tab.Content>
                        </Tab.Container>
                    </div> */}

<Modal
        className="fade bd-example-modal-lg"
        show={customDateModal}
        size="lg"
      >
        <Modal.Body>
          <div className="text-center m-0">
            <button
              variant=""
              className="btn-close btn-danger float-end align-center m-1 p-2 "
              onClick={cancelCustom}
            ></button>
            <h2 className="text-center">Select start and end date</h2>
          </div>

          <div className="row">
            <div className="col-6 form-group mb-3">
              <h5>Start date</h5>
              <DatePicker
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                className="form-control"
              />
            </div>
            <div className="col-6 form-group mb-3">
              <h5>End date</h5>
              <DatePicker
                selected={endDate}
                onChange={(date) => setEndDate(date)}
                className="form-control"
              />
            </div>
          </div>

          <div className="text-center mt-4">
            <button
              type="submit"
              className="figma-btn text-white float-right"
              onClick={() => {
                submitStartEndDate();
              }}
            >
              Submit
            </button>
          </div>
        </Modal.Body>
      </Modal>


             <div className="default-tab">
                <Tab.Container activeKey={activeTab.toLowerCase()}>
                    <Card style={{ borderRadius: "0px 0px 0px 0px", marginBottom: "0px" }}>
                        <Card.Body>
                           
                        <div className="d-flex justify-content-between align-items-center">
                          <h3 className="mb-4">Select what to track!</h3>
                             <div style={{display:"flex", alignItems:"center"}}>
                             <span className="filterText mx-4 my-1">Filter: </span>
                                 <OverlayTrigger
                                 delay={{ hide: 100, show: 10 }}
                                 overlay={(props) => (
                                 <Tooltip {...props}>
                                 <h5 className="p text-white">Please select time scope</h5>
                                </Tooltip>
                                 )}
                                  placement="top"
                                   >
                                    
                              <Dropdown className="mx-2">
                           <Dropdown.Toggle
                           variant="outline-primary"
                           style={{ backgroundColor: "#ffffff !important" }}
                           >
                           {selectedTimeScope ? selectedTimeScope : "Select Time Scope"}
                           </Dropdown.Toggle>
                            <Dropdown.Menu>
                                {timeScopes.map((timescope, i) => (
                               <Dropdown.Item
                              onClick={() => {
                          storeTimeScope(timescope);
                                       }}
                                     key={i}
                                  >
                               {timescope.name}
                           </Dropdown.Item>
                            ))}
                            </Dropdown.Menu>
                           </Dropdown>
                                </OverlayTrigger>
                                </div>

                        </div>




                            <Nav as="ul" className="nav-tabs">
                                {tabData.map((data, i) => (
                                    <Nav.Item as="li" key={i}>
                                        <Nav.Link
                                            eventKey={data.name.toLowerCase()}
                                            onClick={() => handleTabSelect(data.name)}
                                        >
                                            <span className="fs-16">{data.name}</span>
                                        </Nav.Link>
                                    </Nav.Item>
                                ))}
                            </Nav>
                        </Card.Body>
                    </Card>
                    <Tab.Content className="">
                        <Suspense fallback={<div>Loading...</div>}>
                            {activeTab === "Objective" && <TrackObjective scopes={timeScope} />}
                            {/* {activeTab === "Team" && <TrackTeam scopes={timeScope} />} */}
                            {activeTab === "Organization" && <TrackOraganization scopes={timeScope} />}
                        </Suspense>
                    </Tab.Content>
                </Tab.Container>
            </div>
               
        </>
    );
};

export default Track;
