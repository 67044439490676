import config from '../config';


class WebSocketService {
  static instance = null;
  callbacks = {};

  static getInstance() {
    if (!WebSocketService.instance) {
      WebSocketService.instance = new WebSocketService();
    }
    return WebSocketService.instance;
  }

  constructor() {
    this.socketRef = null;
    this.newData = null;
    this.UserData = JSON.parse(localStorage?.getItem('userDetails'))
  }
  connect(room_id, userDetails) {
    localStorage.setItem("websocketIds", room_id);
    
    // console.log('in connect ----->',room_id,userDetails)
    const path = config.API_PATH;
    // if(this.UserData){
    this.socketRef = new WebSocket(path + room_id +'/sender/'+userDetails?.id);
    // }
		// this.socketRef = new WebSocket("wss://f3fe-49-248-29-250.in.ngrok.io/ws/chat/"+room_id +'/sender/'+2)

    // this.socketRef = new WebSocket(path)
    this.socketRef.onopen = () => {
      console.log('WebSocket connected successfully:', this.socketRef?.url);
      this.newData = null;
      console.log('this.SocketOpen 1',this.socketRef?.readyState);
      
    };
    this.socketRef.onmessage = e => {
      this.newData = JSON.parse(e?.data)
      // console.log('this.newData', this.newData);
      // console.log('this.socketRef',this.socketRef)
      // this.socketNewMessage(e?.data);
      
      let url = this?.socketRef?.url.split("/")
      // console.log('this.newData',url[url?.length - 3], this.newData);
      // let email = url[2];
      // console.log("this.newData in on message",url,url[url.length - 3],url[url.length -3] === this.newData?.thread)
      if(url[url?.length - 3] === this.newData?.thread){
        this.socketNewMessage(e?.data);
      this.callbacks['newSocketData'] = this.newData;
    }
    };
    this.socketRef.onerror = e => {
      console.log("Websocket error",e.message);
      this.newData = null;
    };
    // this.socketRef.onclose = () => { 
    //   this.newData = null;
    //   // this.connect(room_id, this.UserData.data);
    // };
  }

  logout() {
    // Logic to close WebSocket connection
    if (this.socketRef) {
      this.socketRef.close();
      console.log('WebSocket connection closed');
    } else {
      console.log('WebSocket is not connected');
    }
  }

  close(){
    // console.log("WebSocket closed let's reopen%%%%%%%%%%%%%",this.socketRef);
    if(this.socketRef) {
      console.log('WebSocket disconnected:');
      console.log('fdfdbdfbfdbdfb', this.socketRef)
      this.socketRef.close();
    }
    //  this.socketRef.onclose = () => { 
    //   this.newData = null;
      // console.log("WebSocket on close let's reopen*******************");
      // this.connect(JSON.parse(localStorage.getItem("ActiveChat"))?.thread_id, this.UserData?.data);
    // };
  }

  checkAndCloseWebSocket() {
    const currentUrl = window.location.href;  // Get the full URL
    if (!currentUrl.includes("message")) {  // Check if URL contains 'message'
      console.log("URL does not contain 'message', closing WebSocket...");
      this.close();  // Close WebSocket
    }
  }



  socketNewMessage(data) {
    const parsedData = JSON.parse(data);
    const command = parsedData.command;
    this.messagesAll = parsedData.text;
// this.fetchMessagesChat(parsedData)
    if (Object.keys(this.callbacks).length === 0) {
      return;
    }
    // console.log("in socketNewMessage --- add_attachment",command)
    if (command === 'message') {
      // console.log("in socketNewMessage --- message")
      this.callbacks[command](parsedData.messages);
    }
    else if (command === 'new_message') {
      // console.log("in socketNewMessage --- new_message")
      this.callbacks[command](parsedData.message);
    }
    else if (command === 'add_attachment') {
      this.callbacks[command](parsedData.message);
    }
    else if (command === 'refresh') {
      // console.log("in socketNewMessage --- refresh")
      this.callbacks[command](parsedData.command);
    }
  }

  // initChatUser(room_id) {
  //   WebSocketInstance.connect(room_id);
  //   // this.sendMessage({ command: 'init_chat', username: username });
  // }

  fetchMessages(username) {
    this.sendMessage({ command: 'fetch_messages', sender_id :this.UserData?.data?.id});
  }

  newChatMessage(message) {
    
    if(message.command === "add_attachment"){
      // console.log("in send message add_attachment")
      this.sendMessage(message)
    }
    else if(message.command === "refresh"){
      // console.log("in send message refresh")
      this.sendMessage({
        "sender_id":JSON.parse(localStorage?.getItem('userDetails'))?.data?.id,
        "command": "refresh"
      })
    }
    else{
      // console.log("in send message")
      this.sendMessage({
        "message": message?.text,
        "is_sender": "True",
        "sender_id":JSON.parse(localStorage?.getItem('userDetails'))?.data?.id,
        "command":"new_message",
        "thread": message?.threadId,
      }); 
    }
    // this.sendMessage({ "attachment": message.attachment,"is_sender": "True","sender_id":this.UserData?.data?.id,"command":"new_message"}); 
 
  }

  addCallbacks(messagesCallback, newMessageCallback) {
    // console.log("messagesCallback, newMessageCallback --->",messagesCallback, newMessageCallback)
    this.callbacks['messages'] = messagesCallback;
    this.callbacks['new_message'] = newMessageCallback;
    this.callbacks['add_attachment'] = newMessageCallback;
    this.callbacks['refresh'] = newMessageCallback;
  }
  
  sendMessage(data) {
    try {
      this.socketRef.send(JSON.stringify({ ...data }));
    }
    catch(err) {
      console.log(err.message);
    }  
  }

  state() {
    return this.socketRef.readyState;
  }

   waitForSocketConnection(callback){
    const socket = this.socketRef;
    const recursion = this.waitForSocketConnection;
    setTimeout(
      function () {
        if (socket.readyState === 1) {
          if(callback != null){
            callback();
          }
          return;

        } else {
          console.log("wait for connection...")
          recursion(callback);
        }
      }, 1); // wait 5 milisecond for the connection...
  }

}

const WebSocketInstance = WebSocketService.getInstance();
export default WebSocketInstance;


