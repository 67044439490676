import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAllGoalsByOrgID } from '../../../services/GoalService';
import ProgressBar from 'react-bootstrap/ProgressBar';
import 'bootstrap/dist/css/bootstrap.min.css'
import { Card, Col } from 'react-bootstrap';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { IoArrowForwardCircleSharp } from "react-icons/io5";
import { plannedGoalMyDay } from '../../../store/actions/TaskActions';
import { useDispatch } from 'react-redux';


const PlannedObjectives = () => {
  // const [objectiveLists, setObjectiveList] = useState(objectiveList || []);
  const [goals, setGoals] = useState([])
  const userDetail = localStorage.getItem("userDetails");
  const userInfoDetails = JSON.parse(userDetail);

  // useEffect(() => {
  //   console.log("objectiveListobjectiveList", objectiveLists);
  // }, [objectiveLists]);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const showGoals = (event, goal) => {
    event.preventDefault();
    dispatch(plannedGoalMyDay(true))
    navigate("/goals", { state: { goal: goal } });
    console.log("selected goal", goal);
  };

  const progressBarFilledStyle = {
    backgroundColor: '#d4ebe2', // Custom fill color for the progress
  };

  const progressBarWarningFilledStyle = {
    backgroundColor: '#f5d9b0', // Custom fill color for the progress
  };

  const progressBarFailingFilledStyle = {
    backgroundColor: '#eeaeba', // Custom fill color for the progress
  };


  const handleClick = () => {
    navigate('/goals');
  };

  const progressBarStyle = {
    backgroundColor: "white",
    position: 'relative',
    height: '40px',
    width: '100%',
    maxWidth: '100%',
    border: '1px solid #a8d6c5'
  };
 
  const progressWarningBarStyle = {
    backgroundColor: "white",
    position: 'relative',
    height: '40px',
    width: '100%',
    maxWidth: '100%',
    border: '1px solid #f5d9b0'
  };

  const progressFailingBarStyle = {
    backgroundColor: "white",
    position: 'relative',
    height: '40px',
    width: '100%',
    maxWidth: '100%',
    border: '1px solid #eeaeba'
  };




 const labelStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '100%',
    textAlign: 'center',
    color: '#26996d',
    // fontWeight: 'bold',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis', // Displays '...' if the text is too long
    zIndex: 1,
    padding: '10px 20px', // Padding for some space around the text

  };
 
  const labelWarningStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '100%',
    textAlign: 'center',
    color: '#e5a139',
    // fontWeight: 'bold',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis', // Displays '...' if the text is too long
    zIndex: 1,
    padding: '10px 20px', // Padding for some space around the text

  };

  const labelFailingStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '100%',
    textAlign: 'center',
    color: '#d43551',
    // fontWeight: 'bold',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis', // Displays '...' if the text is too long
    zIndex: 1,
    padding: '10px 20px', // Padding for some space around the text

  };



  const getAllTheGoal = () => {
    const data = getAllGoalsByOrgID(
      userInfoDetails?.data?.accessToken,
      null,
      "",
      "",
      "",
      "",
      true,
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      3,
      "",
      "",
      ""
    );
    data?.then(async (res) => {

      setGoals(res?.data?.data);
      console.log(res?.data?.data, "llllllllllllllllllll2222221seccond")

    });
    data?.catch(async (err) => {

      console.log("error getAllGoalsByOrgID", err);
    });
  }
  useEffect(() => {
    getAllTheGoal()
  }, [])

  useEffect(()=>{
    getAllTheGoal()
  },[localStorage.getItem("scope")])

  // const renderTooltip = (props, objective) => (
  //   <Tooltip id="progress-tooltip" {...props} className='fs-18'>
  //     {`${objective?.goal_completed_percent}% Complete. Status : ${objective?.goal_status}`} despite good progress due to poor task outcomes and/or delays. Check details here. <IoArrowForwardCircleSharp />
  //   </Tooltip>
  // );

  const renderTooltip = (props, objective) => {
    const getTooltipText = () => {
      const { goal_completed_percent, goal_status } = objective;
      if (goal_completed_percent >= 80 && goal_status === 'Failed') {
        return `${goal_completed_percent}%  complete. Status: ${goal_status} despite good progress due to poor task outcomes and delays. Improve outcome quality to recover.`;
      } else if (goal_completed_percent >= 50 && goal_completed_percent < 80 && goal_status === 'Failed') {
        return `${goal_completed_percent}% complete. Status: ${goal_status} due to low task outcomes and significant delays.`;
      } else if (goal_completed_percent > 30 && goal_completed_percent < 50 && goal_status === 'Failed') {
        return `${goal_completed_percent}% complete. Status: ${goal_status} due to poor outcomes and challenges.`;
      } else if (goal_completed_percent <= 30 && goal_status === 'Failed') {
        return `${goal_completed_percent}% complete. Status: ${goal_status} due to low task outcomes and significant delays.`;
      }else if (goal_completed_percent >= 75 && goal_status === 'Near Risk') {
        return `${goal_completed_percent}% complete. Status: ${goal_status} despite good progress due to inconsistent task outcomes and borderline delays.`;
      } else if (goal_completed_percent >= 60 && goal_completed_percent < 75 && goal_status === 'Near Risk') {
        return `${goal_completed_percent}% complete. Status: ${goal_status} due to mixed task outcomes and moderate delays.`;
      } else if (goal_completed_percent >= 35 && goal_completed_percent < 60 && goal_status === 'Near Risk') {
        return `${goal_completed_percent}% complete. Status: ${goal_status} with limited progress and inconsistent outcomes.`;
      } else if (goal_completed_percent < 35 && goal_status === 'Near Risk') {
        return `${goal_completed_percent}% complete. Status: ${goal_status} with significant challenges and poor outcomes.`;
      }else if (goal_completed_percent >= 85 && goal_status === 'On track') {
        return `${goal_completed_percent}% complete. Status: ${goal_status} with strong progress and positive task outcomes.`;
      } else if (goal_completed_percent >= 60 && goal_completed_percent < 85 && goal_status === 'On track') {
        return `${goal_completed_percent}% complete. Status: ${goal_status} with steady progress and positive task outcomes.`;
      } else if (goal_completed_percent > 40 && goal_completed_percent < 60 && goal_status === 'On track') {
        return `${goal_completed_percent}% complete. Status: ${goal_status} with moderate progress.`;
      } else if (goal_completed_percent <= 40 && goal_status === 'On track') {
        return `${goal_completed_percent}% complete. Status: ${goal_status} despite limited progress.`;
      } else if (goal_completed_percent >= 25 && goal_status == 'Failing') {
          return `${goal_completed_percent}% complete. Status: ${goal_status} due to suboptimal task outcomes and some delays.`;
      }
      return `${goal_completed_percent}% Complete. Status: ${goal_status}. Check details here.`;
    };
  
    return (
      <Tooltip id="progress-tooltip" {...props} className='fs-18'>
        {getTooltipText()} 
        {/* <IoArrowForwardCircleSharp /> */}
      </Tooltip>
    );
  };

  return (
    <Col xl={3}>
      <Card>
        <Card.Header>
          <h3>Goals</h3>
        </Card.Header>
        <Card.Body>
          <div>

            <table
              className="display w-100 dataTable"
              id="example5"
              role="grid"
              aria-describedby="example5_info"
            >
              <thead>
                {/* <tr>
            <th>Objective</th>
          </tr> */}
              </thead>
              <tbody>
                {
                  goals?.length === 0 ? (
                    <tr>
                      <td colSpan="3" style={{ textAlign: 'center' }}>No data available</td>
                    </tr>
                  ) : (
                    goals?.map((objective, index) => (
                      <tr key={index} style={{ cursor: "pointer" }}>

                        <td onClick={(event) => showGoals(event, objective)}>






                          {/* <div style={{ position: 'relative', width: '100%' }}>
      <ProgressBar
        now={40} 
        label={null} 
        style={progressBarStyle}
        variant="success"
      />
      <span style={labelStyle}>{`${objective?.name || 'Objective Name'}`}</span>
    </div> */}
                          <OverlayTrigger
                            placement="bottom" // Tooltip position
                            overlay={(props) => renderTooltip(props, objective)} // Pass `objective` to `renderTooltip`
                          >

                            <div style={{ position: 'relative', width: '100%' }}>
                              <ProgressBar
                                now={40} // Adjust the progress value as per your need
                                label={null} // Label is handled manually
                                style={

                                  objective.goal_status === "On track"
                                    ? progressBarStyle
                                    : objective.goal_status === "At risk"
                                      ? progressWarningBarStyle
                                      : progressFailingBarStyle
                                }

                              
                              >
                                <div
  style={{
    ...(objective.goal_status === "On track"
      ? progressBarFilledStyle
      : objective.goal_status === "At risk"
        ? progressBarWarningFilledStyle
        : progressBarFailingFilledStyle),
    width: `${objective?.goal_completed_percent}%`, // The percentage width of the filled part
    height: '100%',
  }}
/>
                              </ProgressBar>
                              <span style={
                                objective.goal_status === "On track"
                                  ? labelStyle
                                  : objective.goal_status === "At risk"
                                    ? labelWarningStyle
                                    : labelFailingStyle

                              }>{`${objective?.name || 'Objective Name'}`}</span>
                            </div>
                          </OverlayTrigger>



                        </td>




                        {/* <td
                    onClick={(event) => showGoals(event, objective)}
                    className={
                      objective?.goal_status === "On track"
                        ? "figbtn-success"
                        : objective?.goal_status === "At risk"
                          ? "figbtn-warning"
                          : "figbtn-failed"
                    }
                  >
                     <span style={{wordBreak: 'break-word'}}
                      className={
                        objective?.goal_status === "On track"
                          ? "figstatus"
                          : objective?.goal_status === "At risk"
                            ? "figwarning"
                            : "figfailed"
                      }
                    >
                      {objective?.name}
                    </span>
                  </td> */}
                        {/* <td>{objective?.goal_status}</td> */}
                      </tr>
                    ))
                  )
                }

              </tbody>
            </table>
          </div>
        </Card.Body>
        <Card.Footer style={{ border: "none" }}>
          {goals?.length !== 0 ? (<p style={{ float: "right", paddingRight: "10px" }}><span onClick={handleClick} class="badge" style={{ backgroundColor: '#356FD4', cursor: "pointer" }}>View all</span></p>) : (<></>)}
        </Card.Footer>
      </Card>
    </Col>
  );
};

export default PlannedObjectives;
